import { fork } from 'redux-saga/effects';
import { saga as simAssignmentSaga } from 'containers/App/modules/sim-assignment/sagas';
import { saga as productStockSaga } from '@tn/modules/dist/product-stock/sagas';

/**
 * Root saga
 */
export default function* root() {
    yield fork(simAssignmentSaga);
    yield fork(productStockSaga);
}
