import * as React from 'react';
import { List } from 'immutable';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { getFormValues } from 'redux-form/immutable';
import { reset, change } from 'redux-form';

import * as _window from '@tn/window';
import { Flex } from '@tn/retune';
import Analytics from '@tn/analytics';

import { toggleModal } from '@tn/modules/dist/modals/actions';
import {
    loadSimAssignment,
    clearSimAssignmentState,
} from 'containers/App/modules/sim-assignment/actions';
import { MODALS } from '@tn/modules/dist/modals/types';
import * as simConstants from 'containers/App/modules/sim-assignment/constants';
import * as simAssignmentSelectors from 'containers/App/modules/sim-assignment/selectors';
import * as deepLinkSelectors from '@tn/modules/dist/deep-link/selectors';
import PhoneCompatibility from 'containers/DetailPage/SimPage/PhoneCompatibility';
import SimAssignFailed from 'containers/DetailPage/SimPage/SimAssignFailed';
import SimAssignSucceeded from 'containers/DetailPage/SimPage/SimAssignSucceeded';
import { LockedStatus } from 'containers/DetailPage/SimPage/IsPhoneLocked';
import { PlanRecord } from '@tn/modules/dist/plans/types';
import { SimAssignmentStoreRecord } from 'containers/App/modules/sim-assignment/types';
import { Product } from '@tn/types/dist/domain/Product';
import checkoutUrlGenerator from 'containers/DetailPage/components/ProductSetContent/checkoutUrlGenerator';
import { ApiError } from '@tn/types/dist/generic';

export class SimPage extends React.PureComponent<Props, State> {
    esnDeepLink?: string = '';

    baseSimProduct: Product = {
        color: '',
        dropdown_label: '',
        giftable: false,
        id: 0,
        img_url: '',
        is_lte: false,
        is_wimax: false,
        product_descriptions: [],
        product_features: [],
        product_images: [],
        product_specifications: [],
        refurbished: false,
        sale_price: 0,
        shipping_delay: 0,
        short_description: '',
        sold_out: false,
        upgrade_price: 0,
        web_store_enabled: false,
        webstore_quality: '',
        model: 'M2MSIMTRIOCON',
        name: 'SIM (K-TUSA)',
        price: 0,
    };

    constructor(props: Props) {
        super(props);

        const purchaseType = _window.getQueryVariable('purchaseType')
            ? _window.getQueryVariable('purchaseType')
            : '';

        this.state = {
            planPicked: this.props.planPicked,
            purchaseType: purchaseType,
        };
    }

    componentDidMount() {
        const esn = _window.getQueryVariable('ESN');

        this.esnDeepLink = undefined;

        if (
            esn &&
            this.props.loadSimAssignment &&
            this.props.setPhoneCompatibilityForm
        ) {
            this.props.setPhoneCompatibilityForm(esn);
            this.props.loadSimAssignment(esn);
            this.esnDeepLink = esn;
        }
    }

    getCurrentPlan = () => {
        const currentPlan = this.props.plans.find(
            (plan) => plan.stripe_id === 'fc'
        );

        // In case the current plan is not yet set in the store.
        return currentPlan ? currentPlan : this.props.plans.get(0);
    };

    onPhoneCompatibilityContinue = () => {
        if (
            this.props.loadSimAssignment &&
            this.props.phoneCompatibilityValues
        ) {
            const esn = this.props.phoneCompatibilityValues.get(
                'esn'
            ) as string;

            this.props.loadSimAssignment(esn);
        }
    };

    onPhoneLocked = (status: LockedStatus) => {
        const locked = status === LockedStatus.Locked;

        if (
            this.props.loadSimAssignment &&
            this.props.phoneCompatibilityValues
        ) {
            const esn = this.props.phoneCompatibilityValues.get(
                'esn'
            ) as string;
            this.props.loadSimAssignment(esn, locked.toString());
        }
    };

    onBringAnotherPhone = () => {
        this.esnDeepLink = undefined;

        if (this.props.clearSimAssignmentState) {
            this.props.clearSimAssignmentState();
        }

        if (this.props.resetPhoneCompatibilityForm) {
            this.props.resetPhoneCompatibilityForm();
        }
    };

    onSimSelectPlan = () => {
        const currentPlan = this.getCurrentPlan();
        let productId;

        if (this.props.currentProduct) {
            productId = this.props.currentProduct;
        } else if (
            this.props.simAssignmentResult &&
            this.props.simAssignmentResult.result.sim_product_id
        ) {
            productId = this.props.simAssignmentResult.result.sim_product_id;
        }

        if (productId && currentPlan) {
            Analytics.setECProduct(this.baseSimProduct);

            Analytics.track('store', 'clicked_buy_now');
            let checkoutUrl = checkoutUrlGenerator({
                planId: currentPlan.id,
                purchaseType: this.state.purchaseType,
                utmCampaign: this.props.utmCampaign,
            });

            // We have to add the product id after the fact because the generator requires a product set with the id
            // Sim does not have the requirement
            checkoutUrl = `${checkoutUrl}&product_id=${productId}`;

            document.location.assign(checkoutUrl);
        }
    };

    onGetSimCard = () => {
        const currentPlan = this.getCurrentPlan();

        if (this.props.simAssignmentResult && currentPlan) {
            Analytics.setECProduct(this.baseSimProduct);

            const urlData: {
                planId: number;
                purchaseType: string;
                utmCampaign?: string;
            } = {
                planId: currentPlan.id,
                purchaseType: this.state.purchaseType,
                utmCampaign: this.props.utmCampaign,
            };
            let checkoutUrl = checkoutUrlGenerator(urlData);

            // We have to add the product id after the fact because the generator requires a product set with the id
            // Sim does not have the requirement
            if (this.props.simAssignmentResult.result.sim_product_id) {
                checkoutUrl = `${checkoutUrl}&product_id=${this.props.simAssignmentResult.result.sim_product_id}`;
            }

            Analytics.track('store', 'clicked_buy_now');
            document.location.assign(checkoutUrl);
        }
    };

    openPhoneCompatibilityModal = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();

        if (this.props.togglePhoneCompatibilityModal) {
            this.props.togglePhoneCompatibilityModal();
        }
    };

    renderSuccess = (result: string = '') => {
        switch (result) {
            case simConstants.VALIDATION_SIM_REQUIRED:
                return (
                    <SimAssignSucceeded
                        onClick={this.onGetSimCard}
                        deviceCheckerPage={this.props.deviceCheckerPage}
                    />
                );
            case simConstants.VALIDATION_DEVICE_NOT_YET_SUPPORTED:
                // feature toggle here
                // Redirect.
                _window.location.assign('/m/fasternetwork/');

                // Return !undefined to satisfy Flex<ReactNode> in render().
                return false;

            // case simConstants.VALIDATION_DEVICE_VALID_FOR_ACTIVATION:
            // Only occurs if ICCID provided

            // If we get a 200 that we can't handle, fall back to DEVICE_NOT_SUPPORTED.
            default:
                return this.renderError(simConstants.DEVICE_NOT_SUPPORTED);
        }
    };

    renderError = (error_code: string = '') => {
        const simAssignFailedProps: SimAssignFailedProps = {
            onLinkClick: this.onBringAnotherPhone,
            title: '',
            subTitle: '',
        };
        let deviceNotSupportedError = false;

        switch (error_code) {
            case simConstants.DEVICE_NOT_SUPPORTED:
                simAssignFailedProps.title =
                    "Sorry, your phone isn't currently supported by TextNow";
                simAssignFailedProps.subTitle =
                    'Still interested in joining TextNow? Enjoy low-priced phones and plans starting from $0 /month.';
                deviceNotSupportedError = true;
                break;
            case simConstants.DEVICE_ID_INVALID:
                simAssignFailedProps.title = 'Invalid IMEI/MEID/ESN';
                simAssignFailedProps.subTitle =
                    'Please enter a valid IMEI/MEID/ESN';
                break;
            case simConstants.DEVICE_ID_REQUIRED:
                simAssignFailedProps.title = 'You must enter an IMEI/MEID/ESN';
                break;
            case simConstants.ICCID_INVALID:
                simAssignFailedProps.title = 'Invalid SIM Number / ICCID';
                simAssignFailedProps.subTitle =
                    'Please enter a valid SIM Number / ICCID';
                break;
            default:
                simAssignFailedProps.title =
                    'Oops, something appears to be wrong. Please try again later.';
                break;
        }

        // There is always an exception: Here we send DEVICE_NOT_SUPPORTED
        // errors through to SimAssignFailed only for the device checker flow.
        // DEVICE_NOT_SUPPORTED is generated from renderSuccess, not from the server.
        if (this.props.deviceCheckerPage && !deviceNotSupportedError) {
            return (
                <PhoneCompatibility
                    onClick={this.onPhoneCompatibilityContinue}
                    openModal={this.openPhoneCompatibilityModal}
                    loading={this.props.simAssignmentLoading}
                    deviceCheckerPage={this.props.deviceCheckerPage}
                    errorString={simAssignFailedProps.title}
                />
            );
        }

        // Render SimPage error component.
        return (
            <SimAssignFailed
                {...simAssignFailedProps}
                deviceCheckerPage={this.props.deviceCheckerPage}
            />
        );
    };

    renderComponent = () => {
        if (
            this.props.simAssignmentResult &&
            this.props.simAssignmentResult.result &&
            this.props.simAssignmentResult.result.validation_result
        ) {
            return this.renderSuccess(
                this.props.simAssignmentResult.result.validation_result
            );
        } else if (
            this.props.simAssignmentResult &&
            this.props.simAssignmentResult.error &&
            this.props.simAssignmentResult.error.error_code
        ) {
            return this.renderError(
                this.props.simAssignmentResult.error.error_code
            );
        }

        // Default state.
        return (
            <PhoneCompatibility
                onClick={this.onPhoneCompatibilityContinue}
                openModal={this.openPhoneCompatibilityModal}
                loading={this.props.simAssignmentLoading}
                deviceCheckerPage={this.props.deviceCheckerPage}
            />
        );
    };

    render() {
        return (
            <Flex alignItems="center" xs>
                {this.renderComponent()}
            </Flex>
        );
    }
}

interface SimAssignFailedProps {
    onLinkClick: () => void;
    title: string;
    subTitle: string;
}

interface State {
    planPicked: boolean;
    purchaseType: string;
}

interface Props {
    planPicked: boolean;
    plans: List<PlanRecord>;
    currentProduct?: string;
    simAssignmentLoading?: boolean;
    simAssignmentResult?: SimAssignmentStoreRecord;
    simAssignmentError?: ApiError;
    phoneCompatibilityValues?: FormData;
    utmCampaign?: string;
    togglePhoneCompatibilityModal?: () => void;
    loadSimAssignment?: (esn: string, locked?: string) => void;
    clearSimAssignmentState?: () => void;
    resetPhoneCompatibilityForm?: () => void;
    setPhoneCompatibilityForm?: (esn: string) => void;
    deviceCheckerPage?: boolean;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        togglePhoneCompatibilityModal: () =>
            dispatch(toggleModal(MODALS.PHONE_COMPATIBILITY)),
        loadSimAssignment: (esn: string, locked?: string) =>
            dispatch(loadSimAssignment(esn, locked)),
        clearSimAssignmentState: () => dispatch(clearSimAssignmentState()),
        resetPhoneCompatibilityForm: () =>
            dispatch(reset('phoneCompatibility')),
        setPhoneCompatibilityForm: (esn: string) =>
            dispatch(change('phoneCompatibility', 'esn', esn)),
    };
}

const makeMapStateToProps = () => {
    const getSimAssignmentLoading = simAssignmentSelectors.isLoading;
    const getSimAssignmentResult = simAssignmentSelectors.simAssignmentResult;
    const getSimAssignmentError = simAssignmentSelectors.simAssignmentError;
    const getUtmCampaign = deepLinkSelectors.getUtmCampaign;

    const mapStateToProps = (state) => {
        return {
            simAssignmentLoading: getSimAssignmentLoading(state),
            simAssignmentResult: getSimAssignmentResult(state),
            simAssignmentError: getSimAssignmentError(state),
            phoneCompatibilityValues: getFormValues('phoneCompatibility')(
                state
            ),
            utmCampaign: getUtmCampaign(state),
        };
    };
    return mapStateToProps;
};

const withConnect = connect<{}, {}, Props>(
    makeMapStateToProps,
    mapDispatchToProps
);

export default withConnect(SimPage);
