import * as React from 'react';
import { compose, Action } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { List } from 'immutable';

import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';
import * as _window from '@tn/window';
import Analytics from '@tn/analytics';

import { injectSaga, injectReducer } from '@tn/redux';
import { plans } from '@tn/modules/dist/plans/selectors';
import { PlanRecord } from '@tn/modules/dist/plans/types';
import * as subscriptionSelectors from '@tn/modules/dist/subscription/selectors';
import { SubscriptionRecord } from '@tn/modules/dist/subscription/types';
import * as productSetActions from '@tn/modules/dist/product-set/actions';
import * as productSetSelectors from '@tn/modules/dist/product-set/selectors';
import * as deepLinkSelectors from '@tn/modules/dist/deep-link/selectors';
import ProductSetContent from 'containers/DetailPage/components/ProductSetContent';
import RelatedProducts from 'containers/DetailPage/components/RelatedProducts';
import * as deepLinkActions from '@tn/modules/dist/deep-link/actions';
import * as plansActions from '@tn/modules/dist/plans/actions';

import './index.scss';
import SimCardContent from 'containers/DetailPage/components/SimCardContent';
import DeviceContent from 'containers/DetailPage/components/DeviceContent';
import saga from 'containers/DetailPage/saga';
import reducer from 'containers/DetailPage/reducers';

export class DetailPage extends React.PureComponent<Props, State> {
    static defaultProps: Partial<Props> = {
        location: {},
    };

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    static getDerivedStateFromProps(props: Props, state: State) {
        if (props.match.params.productSetId !== state.prevProductSetId) {
            _window.scrollTo(0, 0);
            props.loadProductSet(props.match.params.productSetId);
            return {
                prevProductSetId: props.match.params.productSetId,
            };
        } else if (
            !props.match.params.productSetId &&
            props.location.pathname !== state.prevPathName
        ) {
            _window.scrollTo(0, 0);
            props.loadProductSet('sim-card');

            return {
                prevPathName: props.location.pathname,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps: Props) {
        this.checkForDisabledProductSet(prevProps);
    }

    componentDidMount() {
        // Doing this here in case productSet is already loaded
        this.checkForDisabledProductSet();

        // Setting the deep linking for currentPlan
        const plansString = _window.getQueryVariable(
            'plan',
            this.props.location.search
        );
        if (plansString) {
            this.props.setPlanString(plansString);
        }

        // Setting the deep linking for source of query
        const utmSource = _window.getQueryVariable(
            'utm_source',
            this.props.location.search
        );
        if (utmSource) {
            this.props.setUtmSource(utmSource);
        }

        // Setting the deep linking for isMobile
        const isMobile = _window.getQueryVariable(
            'isMobile',
            this.props.location.search
        );

        if (isMobile !== undefined) {
            this.props.setIsMobile(isMobile);
        }

        const utmCampaign = _window.getQueryVariable(
            'utm_campaign',
            this.props.location.search
        );
        if (this.props.setUTMCampaign) {
            this.props.setUTMCampaign(utmCampaign);
        }
    }

    onUpdateCurrentProduct = (id) => {
        this.props.setProductString(id);
        this.props.loadPlans(id);
    };

    onUpdateCurrentPlan = (currentPlanStripeId) => {
        const findPlan = this.props.plans.find(
            (plan) => plan.stripe_id === currentPlanStripeId
        );
        const planName = `${currentPlanStripeId}||${findPlan && findPlan.name}`;

        Analytics.track('store', 'change_plan', planName);

        this.props.setPlanString(currentPlanStripeId);
    };

    checkForDisabledProductSet(prevProps?: Props) {
        const { productSet } = this.props;
        const prevProductSet = prevProps && prevProps.productSet;

        if (
            productSet &&
            productSet.id !== -1 &&
            // Make sure this only happens once
            (!prevProductSet || prevProductSet.id !== productSet.id) &&
            !productSet.web_store_enabled &&
            // not a sim card
            productSet.name.indexOf('SIM') === -1
        ) {
            this.props.disabledProductSetLoaded(productSet.id.toString());
        }
    }

    /**
     * this loads the plans for the first product
     *
     * @param productSet
     */
    loadPlansProduct(productSet?: ProductSetRecord) {
        let currentProductId = _window.getQueryVariable(
            'product',
            window.location.search
        );

        // look for first product that is not sold_out
        if (productSet) {
            productSet.products.forEach((product) => {
                if (!product.sold_out && !currentProductId) {
                    currentProductId = product.id.toString();
                    return;
                }
            });

            // all of them are sold_out, select first one
            if (!currentProductId && productSet.products.length > 0) {
                currentProductId = productSet.products[0].id.toString();
            }

            if (currentProductId) {
                this.props.loadPlans(currentProductId.toString());
            }
        }
    }

    createRichSearchObj = () => {
        // Creating a rich search object that will enable us to utilize rich search on google
        if (this.props.productSet) {
            const id = this.props.currentProduct
                ? this.props.currentProduct
                : this.props.productSet.default_product_id;
            const currentProduct = this.props.productSet.products.filter(
                (product) => product.id.toString() === id.toString()
            )[0];
            if (currentProduct) {
                // Getting just the preview images
                // We determine that an image is a preview image by checking it's description
                // All preview images have a description of a single char, so we check that the length is equal to 1, if it is then it is a preview image
                const images: string[] = [];
                currentProduct.product_images.forEach((image) => {
                    if (image.description.length === 1) {
                        images.push(image.img_url);
                    }
                });

                return JSON.stringify({
                    '@context': 'https://schema.org/',
                    '@type': 'Product',
                    name: currentProduct.name,
                    image: images,
                    description:
                        currentProduct.product_descriptions.length > 0
                            ? currentProduct.product_descriptions[0].description
                            : '',
                    brand: {
                        '@type': 'Thing',
                        name: this.props.productSet.make,
                    },
                    offers: {
                        '@type': 'Offer',
                        url: window.location.href,
                        priceCurrency: 'USD',
                        price:
                            currentProduct.price > currentProduct.sale_price
                                ? currentProduct.sale_price
                                : currentProduct.price,
                        itemCondition: currentProduct.refurbished
                            ? 'https://schema.org/UsedCondition'
                            : 'https://schema.org/NewCondition',
                        availability: currentProduct.sold_out
                            ? 'https://schema.org/OutOfStock'
                            : 'https://schema.org/InStock',
                        seller: {
                            '@type': 'Organization',
                            name: 'TextNow',
                        },
                    },
                });
            }
            return '';
        }
        return '';
    };

    render() {
        const isSimCard =
            this.props.productSet &&
            this.props.productSet.name.indexOf('SIM') >= 0;
        let title =
            this.props.productSet && this.props.productSet.name
                ? this.props.productSet.name
                : 'Affordable no contract smart phones and cell phone plans';
        const meta = [
            <meta
                key="0"
                property="og:title"
                content={`${title} - TextNow Wireless`}
            />,
        ];

        if (isSimCard) {
            title = 'SIM Card | SIM Only Plans | Pay As You Go SIM - TextNow';
            meta.push(
                <meta
                    key="1"
                    name="description"
                    content="Get a SIM card kit along free cellular service when you bring your phone to TextNow. Order a SIM card to start saving with our free nationwide talk & text plan."
                />,
                <meta
                    key="2"
                    name="keywords"
                    content="SIM Card, CDMA, Sprint, SIM Only, BYOP, Activation Kit"
                />
            );
        } else {
            title = `${title} - TextNow Wireless`;
        }

        if (
            _window.getQueryVariable('product_id', this.props.location.search)
        ) {
            this.props.setProductString(
                _window.getQueryVariable(
                    'product_id',
                    this.props.location.search
                )
            );
        }

        return (
            <div id="ProductSet">
                <Helmet key="helmet">
                    <title>{`${title}`}</title>
                    {meta}
                    <script type="application/ld+json">
                        {this.createRichSearchObj()}
                    </script>
                    <link
                        rel="stylesheet"
                        href="https://widget.reviews.io/rating-snippet/dist.css"
                    />
                </Helmet>

                {this.props.productSet !== undefined && (
                    <ProductSetContent
                        onUpdateCurrentPlan={this.onUpdateCurrentPlan}
                        onUpdateCurrentProduct={this.onUpdateCurrentProduct}
                        currentPlan={this.props.currentPlan}
                        currentProduct={this.props.currentProduct}
                        plans={this.props.plans}
                        productSet={this.props.productSet}
                        isSim={isSimCard}
                        utmCampaign={this.props.utmCampaign}
                    />
                )}

                <div id="device-content">
                    {this.props.productSet && !isSimCard && (
                        <DeviceContent productSet={this.props.productSet} />
                    )}
                    {this.props.productSet && isSimCard && <SimCardContent />}
                </div>

                {this.props.productSet && (
                    <RelatedProducts
                        title={
                            isSimCard
                                ? 'Buy a TextNow powered phone'
                                : 'Other powerful smartphones'
                        }
                        currentProduct={this.props.productSet.id.toString()}
                    />
                )}
            </div>
        );
    }
}

interface State {
    prevProductSetId?: string;
    prevPathName?: string;
}

export interface Props {
    loadProductSet: (productSetId: string) => void;
    productSet: ProductSetRecord | undefined;
    plans: List<PlanRecord>;
    loadPlans: (productId: string) => void;
    currentPlan: string;
    currentProduct: string;
    hasSubscription: boolean;
    subscription: SubscriptionRecord;
    match: {
        params: {
            productSetId: string;
        };
    };
    setPlanString: (queryString: string) => void;
    setUtmSource: (utmSource: string) => void;
    setProductString: (queryString: string) => void;
    setIsMobile: (queryString: string) => void;
    setUTMCampaign: (utmCampaign: string) => void;
    location: {
        pathname?: string;
        search?: string;
    };
    disabledProductSetLoaded: (productId: string) => void;
    utmSource: string;
    utmCampaign: string;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        loadPlans: (productId: string) =>
            dispatch(plansActions.loadPlans(productId)),
        loadProductSet: (productSetId: string) =>
            dispatch(productSetActions.loadProductSet(productSetId)),
        setPlanString: (planString: string) =>
            dispatch(deepLinkActions.setDeepLink({ planString })),
        setProductString: (productString: string) =>
            dispatch(deepLinkActions.setDeepLink({ productString })),
        setIsMobile: (queryString: string) =>
            dispatch(deepLinkActions.setDeepLink({ mobileApp: queryString })),
        setUtmSource: (utmSource: string) =>
            dispatch(deepLinkActions.setDeepLink({ utmSource })),
        disabledProductSetLoaded: (productSetId: string) =>
            dispatch(productSetActions.disabledProductSetLoaded(productSetId)),
        setUTMCampaign: (utmCampaign: string) =>
            dispatch(deepLinkActions.setDeepLink({ utmCampaign: utmCampaign })),
    };
}

const makeMapStateToProps = () => {
    const getProductSet = productSetSelectors.productSet;
    const getHasSubscription = subscriptionSelectors.hasSubscription();
    const getSubscription = subscriptionSelectors.getSubscription();
    const getCurrentPlan = deepLinkSelectors.getCurrentPlan;
    const getCurrentProduct = deepLinkSelectors.getCurrentProduct;
    const getUtmSource = deepLinkSelectors.getUtmSource;
    const getUtmCampaign = deepLinkSelectors.getUtmCampaign;
    const getPlans = plans;

    const mapStateToProps = (state, props: Props) => {
        let productSetId = props.match.params.productSetId;

        if (
            props.location.pathname &&
            props.location.pathname.indexOf('sim-card') >= 0
        ) {
            productSetId = 'sim-card';
        }

        return {
            productSet: getProductSet(state, productSetId),
            plans: getPlans(state, getUtmCampaign(state)),
            currentPlan: getCurrentPlan(state),
            currentProduct: getCurrentProduct(state),
            hasSubscription: getHasSubscription(state),
            subscription: getSubscription(state),
            utmSource: getUtmSource(state),
            utmCampaign: getUtmCampaign(state),
        };
    };
    return mapStateToProps;
};

const withConnect = connect(makeMapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'detailPage', reducer });
const withSaga = injectSaga({ key: 'detailPage', saga });

export default compose(withReducer, withSaga, withConnect)(DetailPage);
