import * as React from 'react';

import { Flex, ImageGallery, ImageGalleryItem } from '@tn/retune';
import { ProductImage } from '@tn/types/dist/domain/Product';

export class DevicePreview extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.createImageObject = this.createImageObject.bind(this);
    }

    createImageObject(size: string) {
        const thumbnails: DevicePreviewItem[] = [];
        const previewImages: DevicePreviewItem[] = [];
        const imageObjects: ImageGalleryItem[] = [];
        const blackListed = [
            'feature',
            'tech_review_logo',
            'tech_review_text',
            'lifestyle',
        ];

        const filteredImages = this.props.productImages.filter((image) => {
            return (
                blackListed.filter((b) => new RegExp(b).test(image.description))
                    .length === 0
            );
        });

        filteredImages.forEach((image: DevicePreviewItem) => {
            if (image.description.includes('feature')) {
                return;
            } else if (image.description.includes('thumbnail')) {
                thumbnails.push(image);
            } else {
                previewImages.push(image);
            }
        });

        previewImages.forEach((image: DevicePreviewItem, index: number) => {
            const object = {
                thumbnail:
                    thumbnails[index] &&
                    `${thumbnails[index].img_url}?size=product-detail-thumbnail`,
                image: `${image.img_url}?size=${size}`,
                image2x: `${image.img_url}?size=${size}&density=2`,
                title: image.web_description
                    ? image.web_description
                    : image.description,
            };
            imageObjects.push(object);
        });

        return imageObjects;
    }

    render() {
        return (
            <>
                <Flex xs={false} md>
                    <ImageGallery
                        images={this.createImageObject('product-detail-d')}
                        automationId="selectedPreviewImage"
                    />
                </Flex>
                <Flex fluid marginTop={3} marginBottom={3} xs md={false}>
                    <ImageGallery
                        images={this.createImageObject('product-detail-m')}
                        automationId="selectedPreviewImage-mobile"
                        width="212px"
                        height="367px"
                    />
                </Flex>
            </>
        );
    }
}

interface Props {
    productImages: DevicePreviewItem[];
    productSetId: number;
}

interface DevicePreviewItem extends ProductImage {}

export default DevicePreview;
