import * as React from 'react';
import { Flex, Label, Container, Row, Column, Image } from '@tn/retune';
import './index.scss';

export interface Props {
    message: string;
    logo: string;
}

export default class TechReviewBanner extends React.PureComponent<Props> {
    render() {
        return (
            <Flex
                xs
                direction="column"
                className="tech-review-banner"
                alignContent="center"
                padding={[5]}
            >
                <Container>
                    <Row>
                        <Column>
                            <Flex
                                className="tech-review-banner__message"
                                xs
                                justify="center"
                                direction="column"
                            >
                                <Flex md xs={false}>
                                    <Label
                                        centered
                                        size="heading1"
                                        className="tech-review-banner__message__big"
                                    >
                                        {this.props.message}
                                    </Label>
                                </Flex>

                                <Flex xs md={false}>
                                    <Label
                                        centered
                                        size="subheading"
                                        className="tech-review-banner__message__big"
                                    >
                                        {this.props.message}
                                    </Label>
                                </Flex>

                                <Flex
                                    xs
                                    direction="column"
                                    alignItems="center"
                                    marginTop={4}
                                >
                                    <Image src={this.props.logo} alt="" />
                                </Flex>
                            </Flex>
                        </Column>
                    </Row>
                </Container>
            </Flex>
        );
    }
}
