import * as React from 'react';

import { Label, H2, Flex, Button, H1 } from '@tn/retune';

import './index.scss';

export class SimAssignSucceeded extends React.PureComponent<Props> {
    renderSimSuccessPage = () => {
        // TODO: Move to component.
        return (
            <Flex
                direction="column"
                className="sim-assign-succeeded"
                alignSelf="center"
                fluid
                xs
            >
                <H2>Congratulations, your phone is compatible!</H2>
                <Flex marginBottom={3} xs>
                    <Label>
                        To connect to our Free Nationwide Talk & Text service,
                        purchase a SIM Activation Kit.
                    </Label>
                </Flex>
                <Flex alignItems="start" direction="column" md xs={false}>
                    <Button onClick={this.props.onClick} label="Buy Now" />
                </Flex>
                <Flex alignItems="start" direction="column" md={false} xs>
                    <Button onClick={this.props.onClick} label="Buy Now" wide />
                </Flex>
            </Flex>
        );
    };

    renderDeviceCheckerSuccessPage = () => {
        // TODO: Move to component.
        return (
            <Flex
                direction="column"
                className="device-check"
                alignSelf="center"
                fluid
                xs
                justify="center"
            >
                <Flex className="device-check-sim-assign-succeeded">
                    <H2>Congratulations!</H2>
                    <H1 marginBottom={3}>Your phone can be activated.</H1>
                    <Flex
                        xs
                        className="subText device-check-sim-assign-succeeded__subtext"
                        justify="center"
                    >
                        Now all you need is a SIM Card to access our Free
                        Nationwide Talk & Text service.
                    </Flex>
                    <Flex
                        xs
                        alignSelf="center"
                        justify="center"
                        className="device-checker-button--opacity"
                    >
                        <Button
                            onClick={this.props.onClick}
                            label="Buy a SIM Card"
                            inverted
                            className="device-checker-button"
                        />
                    </Flex>
                </Flex>
            </Flex>
        );
    };

    render() {
        if (this.props.deviceCheckerPage) {
            return this.renderDeviceCheckerSuccessPage();
        }

        return this.renderSimSuccessPage();
    }
}

interface Props {
    onClick?: () => void;
    deviceCheckerPage?: boolean;
}

export default SimAssignSucceeded;
