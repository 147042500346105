import * as React from 'react';
import { compose, Action } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container, Row, Flex, Column, H2 } from '@tn/retune';
import * as _window from '@tn/window';

import { injectSaga, injectReducer } from '@tn/redux';
import ProductsList from 'containers/WirelessPage/components/ProductSetTileList';
import * as deepLinkActions from '@tn/modules/dist/deep-link/actions';
import saga from 'containers/WirelessPage/saga';
import reducer from 'containers/WirelessPage/reducers';
import './index.scss';

export class DevicesPage extends React.PureComponent<Props> {
    static defaultProps: Partial<Props> = {
        location: {},
    };

    componentDidMount() {
        // Setting the deep linking for currentPlan
        const plansString = _window.getQueryVariable(
            'plan',
            this.props.location.search
        );
        if (plansString) {
            this.props.setPlanString(plansString);
        }

        // Setting the deep linking for isMobile
        const isMobile = _window.getQueryVariable(
            'isMobile',
            this.props.location.search
        );
        if (isMobile !== undefined) {
            this.props.setIsMobile(isMobile);
        }

        const utmCampaign = _window.getQueryVariable(
            'utm_campaign',
            this.props.location.search
        );
        if (this.props.setUTMCampaign) {
            this.props.setUTMCampaign(utmCampaign);
        }
    }

    render() {
        return (
            <div id="wirelessPage">
                <Helmet key="helmet">
                    <title>
                        Wireless Phone Plans | Cell Phone Plans | TextNow
                        Wireless
                    </title>
                    <meta
                        name="description"
                        content="No contract cell phone plans from TextNow Wireless. Pay as you go mobile phone plans start at $9.99. Free Shipping & hassle free returns on all smartphones!"
                    />
                </Helmet>

                <Container>
                    <Row>
                        <Column>
                            <Flex
                                xs
                                justify="center"
                                marginTop={3}
                                className="wireless-page-device-header"
                                marginBottom={3}
                            >
                                <H2 centered>Select device & plan</H2>
                            </Flex>
                        </Column>
                    </Row>
                </Container>

                <Container key="products">
                    <Row marginBottom={5}>
                        <ProductsList location={this.props.location} />
                    </Row>
                </Container>
            </div>
        );
    }
}

interface Props {
    location: {
        search?: string;
    };
    setPlanString: (queryString: string) => void;
    setIsMobile: (queryString: string) => void;
    setUTMCampaign: (utmCampaign: string) => void;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        setPlanString: (queryString: string) =>
            dispatch(deepLinkActions.setDeepLink({ planString: queryString })),
        setIsMobile: (queryString: string) =>
            dispatch(deepLinkActions.setDeepLink({ mobileApp: queryString })),
        setUTMCampaign: (utmCampaign: string) =>
            dispatch(deepLinkActions.setDeepLink({ utmCampaign: utmCampaign })),
    };
}

const withConnect = connect(null, mapDispatchToProps);

const withReducer = injectReducer({ key: 'wirelessPage', reducer });
const withSaga = injectSaga({ key: 'wirelessPage', saga });

export default compose(withReducer, withSaga, withConnect)(DevicesPage);
