import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { Action, compose } from 'redux';

import * as errorHandlingSelectors from '@tn/modules/dist/error-handling/selectors';
import { setErrorAcknowledged } from '@tn/modules/dist/error-handling/actions';
import { ErrorHandlingModal } from '@tn/textnow-web-components';

export class ConnectedErrorHandlingModal extends React.PureComponent<Props> {
    render() {
        return (
            <ErrorHandlingModal
                ackError={this.props.ackError}
                showRateLimiting={this.props.showRateLimiting}
            />
        );
    }
}

interface Props {
    ackError: () => void;
    showRateLimiting: boolean;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        ackError: () => dispatch(setErrorAcknowledged()),
    };
}

const mapStateToProps = createStructuredSelector({
    showRateLimiting: errorHandlingSelectors.getErrorHandlingError,
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ConnectedErrorHandlingModal);
