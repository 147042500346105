import { createSelector } from 'reselect';

import { SimAssignmentStoreRecord } from './types';

const selectSimAssignment = (state) => {
    return state.getIn(['detailPage', 'simAssignment']);
};

export const isLoading = createSelector(
    selectSimAssignment,
    (state: SimAssignmentStoreRecord) => {
        return state && state.loading;
    }
);

export const simAssignmentResult = createSelector(
    selectSimAssignment,
    (state: SimAssignmentStoreRecord) => {
        return state;
    }
);

export const simAssignmentError = createSelector(
    selectSimAssignment,
    (state: SimAssignmentStoreRecord) => {
        return state.error;
    }
);
