import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from 'containers/App';

import { CookiesProvider } from 'react-cookie';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';

import registerServiceWorker from './registerServiceWorker';

import { store, history } from './configureStore';

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root') as HTMLElement
);

registerServiceWorker();
