import * as React from 'react';
import { List } from 'immutable';

import { Plan } from '@tn/types/dist/domain';
import { Label, Flex } from '@tn/retune';

import { PlanRecord } from '@tn/modules/dist/plans/types';
import PlanModalTileLandscape from 'containers/WirelessPage/components/PlanModalTile/Landscape';
import PlanModalTilePortrait from 'containers/WirelessPage/components/PlanModalTile/Portrait';
import './index.scss';

export class PlansModalContent extends React.PureComponent<Props> {
    static defaultProps: Partial<Props> = {
        allowPromoPrice: true,
    };

    render() {
        return (
            <>
                <Flex xs justify="center" fluid>
                    <Label size="heading2" centered>
                        Monthly Plans & Pricing
                    </Label>
                </Flex>

                <Flex
                    xs
                    lg={false}
                    justify="center"
                    fluid
                    marginTop={3}
                    direction="column"
                >
                    <Label size="micro" centered appearance="muted">
                        All plans include unlimited talk & text to U.S and
                        Canada. High speed plans include unlimited 2G data and
                        Ad Free+. No contract.
                    </Label>
                </Flex>

                <Flex
                    xs={false}
                    lg
                    justify="center"
                    fluid
                    marginTop={3}
                    direction="column"
                >
                    <Label size="regular" centered appearance="muted">
                        All plans include unlimited talk & text to USA and
                        Canada.
                    </Label>
                    <Label size="regular" centered appearance="muted">
                        Data plans include unlimited 2G data. No contract.
                    </Label>
                </Flex>

                <Flex
                    direction="row"
                    justify="between"
                    marginTop={4}
                    marginBottom={4}
                    lg
                    xs={false}
                >
                    {!this.props.isLoadingPlans &&
                        this.props.plans.map((plan: Plan, index: number) => (
                            <PlanModalTilePortrait
                                name={plan.name}
                                category={plan.category}
                                price={plan.price}
                                promo_price={
                                    this.props.allowPromoPrice
                                        ? plan.promo_price
                                        : plan.price
                                }
                                key={index}
                            />
                        ))}
                </Flex>

                <Flex
                    direction="column"
                    justify="between"
                    marginTop={4}
                    marginBottom={4}
                    lg={false}
                    xs
                >
                    {!this.props.isLoadingPlans &&
                        this.props.plans.map((plan: Plan, index: number) => (
                            <PlanModalTileLandscape
                                name={plan.name}
                                category={plan.category}
                                price={plan.price}
                                promo_price={
                                    this.props.allowPromoPrice
                                        ? plan.promo_price
                                        : plan.price
                                }
                                key={index}
                            />
                        ))}
                </Flex>

                <Flex
                    className="plans-modal__footer"
                    justify="center"
                    alignContent="center"
                    lg
                    xs={false}
                    direction="column"
                >
                    <Flex xs alignContent="center" justify="center">
                        <Label appearance="muted" centered>
                            Users who exceed monthly data limit will not be
                            charged overage fees, speeds will reduce to 2G until
                            the next cycle.
                        </Label>
                    </Flex>

                    <Flex
                        xs
                        alignContent="center"
                        justify="center"
                        marginTop={2}
                    >
                        <Label
                            appearance="muted"
                            size="micro"
                            centered
                            noTranslate
                        >
                            †
                        </Label>
                        <Label appearance="muted" size="micro" centered>
                            Users who exceed their included data allowance will
                            experience reduced speeds until the next cycle.
                        </Label>
                    </Flex>
                </Flex>

                <Flex
                    className="plans-modal__footer"
                    justify="center"
                    alignContent="center"
                    lg={false}
                    xs
                    direction="column"
                >
                    <Flex xs alignContent="center" justify="center">
                        <Label appearance="muted" size="micro" centered>
                            Users who exceed monthly data limit will not be
                            charged overage fees, speeds will reduce to 2G until
                            the next cycle.
                        </Label>
                    </Flex>

                    <Flex
                        xs
                        alignContent="center"
                        justify="center"
                        marginTop={2}
                    >
                        <Label
                            appearance="muted"
                            size="micro"
                            centered
                            noTranslate
                        >
                            †
                        </Label>
                        <Label appearance="muted" size="micro" centered>
                            Users who exceed their included data allowance will
                            experience reduced speeds until the next cycle.
                        </Label>
                    </Flex>
                </Flex>
            </>
        );
    }
}

interface Props {
    plans: List<PlanRecord>;
    isLoadingPlans: boolean;
    allowPromoPrice?: boolean;
    isSim?: boolean;
}

export default PlansModalContent;
