import { ApiError } from '@tn/types/dist/generic';

import { Action, ActionWithError, SimpleAction } from '@tn/types/dist/generic';

// ACTIONS
import * as types from 'containers/App/modules/sim-assignment/constants';
import {
    SimAssignmentApiResponse,
    SimAssignment,
    SimAssignmentActionWithResponse,
} from 'containers/App/modules/sim-assignment/types';

export function loadSimAssignment(
    esn?: string,
    locked?: string
): SimAssignmentActionWithResponse {
    return {
        type: types.LOAD_SIM_ASSIGN,
        esn,
        locked,
    };
}

export function loadSimAssignmentRequested(): SimpleAction {
    return {
        type: types.LOAD_SIM_ASSIGN_REQUESTED,
    };
}

export function loadSimAssignmentFailed(error: ApiError): ActionWithError {
    return {
        type: types.LOAD_SIM_ASSIGN_FAILED,
        error,
    };
}

export function loadSimAssignmentSucceeded(
    payload: SimAssignmentApiResponse
): Action<SimAssignment> {
    return {
        type: types.LOAD_SIM_ASSIGN_SUCCEEDED,
        payload,
    };
}

export function clearSimAssignmentState(): SimpleAction {
    return {
        type: types.CLEAR_SIM_ASSIGN_STATE,
    };
}
