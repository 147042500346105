import * as React from 'react';
import { Product } from '@tn/types/dist/domain/Product';
import { Flex, Image } from '@tn/retune';

import './index.scss';

export interface Props {
    isOpen: boolean;
    currentProduct: Product;
}

export default class LifeStyle extends React.PureComponent<Props> {
    render() {
        const lifeStyleImage = this.props.currentProduct.product_images.find(
            (image) => image.description === 'lifestyle_1'
        );

        return lifeStyleImage ? (
            <Flex
                fluid
                className="device-content__lifestyle"
                marginTop={this.props.isOpen ? 5 : 0}
                paddingTop={this.props.isOpen ? 5 : 0}
            >
                <Image src={lifeStyleImage.img_url} alt="" />
            </Flex>
        ) : null;
    }
}
