/* eslint-disable max-len */

const FAQContent = [
    {
        title: 'What can I expect if I bring my own phone?',
        body:
            'Bringing your own phone to TextNow is a cheap and easy option. We love that you can join us with a phone you want, and still benefit from our amazing technology, deals, and offers. When you submit your order we will be sure to check your eligibility before charging you a cent.',
    },
    {
        title: 'How does it work?',
        body:
            'We’ll ship your SIM card to you with free shipping. Once you get it, put it in your device and follow our guide to help you get set up. You will be up and running in a matter of minutes.',
    },
    {
        title:
            'Can I use my phone number from a different carrier with TextNow Wireless?',
        body:
            'Yes! Most numbers can be ported in, as long as they are active with the current carrier.',
    },
];

/* eslint-enable max-len */

export default FAQContent;
