import * as React from 'react';
import {
    AccordionElement,
    Flex,
    Label,
    ThemeProvider,
    AccordionExploder,
} from '@tn/retune';
import FAQContent from 'containers/App/components/FAQ/FAQContent';
import FAQContentSIMCard from 'containers/App/components/FAQ/FAQContentSIMCard';

import './index.scss';

export class FAQ extends React.PureComponent<Props> {
    static defaultProps: Partial<Props> = {
        title: 'Frequently Asked Questions',
        branded: false,
    };

    render() {
        const content: { title: string; body: string | JSX.Element }[] =
            this.props.type === 'sim-card' ? FAQContentSIMCard : FAQContent;
        const elements = content.slice(0, content.length).map((faq, index) => {
            return (
                <AccordionElement key={index} title={faq.title}>
                    {faq.body}
                </AccordionElement>
            );
        });

        return (
            <ThemeProvider branded={this.props.branded}>
                <Flex marginTop={5} marginBottom={5} automationId="faq">
                    <div className="FAQ-title">
                        <Label size="heading2">{this.props.title}</Label>
                    </div>
                    <Flex marginTop={5}>
                        <AccordionExploder
                            displayLimit={5}
                            viewFewer="VIEW FEWER QUESTIONS"
                            viewMore="VIEW MORE QUESTIONS"
                        >
                            {elements}
                        </AccordionExploder>
                    </Flex>
                </Flex>
            </ThemeProvider>
        );
    }
}

export interface Props {
    title?: string;
    type?: string;
    branded?: boolean;
    limit?: number;
}

export default FAQ;
