export const SHIPPING = {
    icon: 'shipping',
    label: 'Free shipping & returns',
};
export const MONEY_BACK = {
    icon: 'money-back',
    label: '30-day money back guarantee',
};
export const WARRANTY = {
    icon: 'warranty',
    label: '1 year warranty on all phones',
};
export const CONTRACT = {
    icon: 'no-contract',
    label: 'No contract & no credit checks',
};
export const NATIONWIDE = {
    icon: 'nationwide',
    label: 'Free Nationwide Talk & Text',
};
