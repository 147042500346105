import request, { CallResponse } from '@tn/request';
import { RequestParams } from '@tn/request/dist/types';
import {
    SimAssignment,
    SimAssignmentApiResponse,
} from 'containers/App/modules/sim-assignment/types';

const API = process.env.REACT_APP_API;

export function loadSimAssignment(
    esn?: string,
    locked?: string
): Promise<SimAssignmentApiResponse> {
    let cleanEsn = esn;
    if (esn) {
        cleanEsn = esn.replace(/[\s-]+/g, '');
    }

    const url = `${API}/wireless/byod/v2/validate`;
    const headers = { 'tn-v3': true };
    const params: RequestParams<{}> = {
        url,
        headers,
        data: {
            device_id: cleanEsn,
            carrier_locked: locked ? locked : undefined,
        },
    };

    return request
        .post(params)
        .then(
            (
                response: CallResponse<SimAssignment>
            ): SimAssignmentApiResponse => {
                return {
                    result: response.data,
                    error_code: '',
                };
            }
        )
        .catch((err: { response: CallResponse<SimAssignmentApiResponse> }) => {
            return {
                result: err.response.data.result,
                error_code: err.response.data.error_code,
            };
        });
}
