import * as React from 'react';

import {
    Flex,
    Label,
    SlidingItemSelector,
    SlidingItemSelectorElement,
} from '@tn/retune';
import PriceLabel from 'components/PriceLabel';
import { Product } from '@tn/types/dist/domain/Product';
import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';
import Analytics from '@tn/analytics';

export interface Props {
    currentProductId: string;
    onUpdateCurrentProduct?: (id: string) => void;
    productSet: ProductSetRecord;
}

export default class ProductSelector extends React.PureComponent<Props> {
    inferSummary = (product: Product, hideQuality: boolean = false) => {
        const storage = product.product_specifications.find(
            (spec) => spec.title === 'Storage'
        );
        const summary: string[] = [];

        if (storage) {
            summary.push(storage.description);
        }

        summary.push(product.color);

        if (!hideQuality) {
            summary.push(product.webstore_quality);
        }

        return summary.join(' / ');
    };

    trackVariantChange = (value: string) => {
        Analytics.track('store', 'changed_variant', value);
    };

    renderProducts() {
        return this.props.productSet.products.map(
            (product: Product, index: number) => {
                return (
                    <SlidingItemSelectorElement
                        automationId={`modelSelection_${index}`}
                        key={index}
                        thumbnail={
                            product.img_url + '?size=product-detail-thumbnail'
                        }
                        title={this.inferSummary(product, true)}
                        value={product.id.toString()}
                        selectedPlaceholder={
                            <Label appearance="muted" size="small" noTranslate>
                                {this.inferSummary(product)}&nbsp;
                                {product.sold_out && (
                                    <Label size="small">(Sold Out)</Label>
                                )}
                            </Label>
                        }
                        rightTitle={
                            <PriceLabel
                                className="product-set__plan__price"
                                size="regular"
                                amount={product.sale_price}
                            />
                        }
                        subTitle={
                            <Flex direction="row" xs>
                                <Label size="small" appearance="muted">
                                    {product.webstore_quality}
                                </Label>
                                {product.sold_out && (
                                    <Flex marginLeft={2}>
                                        <Label size="small">(Sold Out)</Label>
                                    </Flex>
                                )}
                            </Flex>
                        }
                        onClick={this.trackVariantChange}
                    />
                );
            }
        );
    }

    render() {
        return (
            <Flex marginTop={3}>
                <SlidingItemSelector
                    title={this.props.productSet.name}
                    description=""
                    automationId="modelSelection"
                    selectedPlaceholder="Selected Phone"
                    selectedItem={this.props.currentProductId}
                    onSelectedItem={this.props.onUpdateCurrentProduct}
                    width="390px"
                >
                    {this.renderProducts()}
                </SlidingItemSelector>
            </Flex>
        );
    }
}
