import * as React from 'react';

import { Product } from '@tn/types/dist/domain/Product';
import { ConfirmModal } from '@tn/retune';

export interface Props {
    currentProduct: Product;
    onCancelConfirmUsed: () => void;
    onContinueConfirmUsed: () => void;
    isOpen: boolean;
}

export default class UsedModal extends React.PureComponent<Props> {
    render() {
        const title =
            this.props.currentProduct.webstore_quality === 'Used'
                ? 'Used Device Information'
                : 'Gently Used Device Information';
        const message =
            this.props.currentProduct.webstore_quality === 'Used'
                ? 'Used phones are meticulously hand inspected and tested to ensure they are fully functional with a 60-day warranty.' +
                  ' Used devices will have visible signs of cosmetic wear which could include blemishes such as scuffs, nicks, dents and scratches visible on the frame and display of the device.'
                : 'Gently used phones may have some slight cosmetic blemishes such as scuffs, nicks, or scratches ' +
                  'visible on the frame and display of the device. Each phone is meticulously hand inspected and tested to ensure they are fully functional. 60-day warranty.';

        return (
            <ConfirmModal
                onCancel={this.props.onCancelConfirmUsed}
                onContinue={this.props.onContinueConfirmUsed}
                isOpen={this.props.isOpen}
                title={title}
                message={message}
            />
        );
    }
}
