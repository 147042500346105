import * as React from 'react';
import { reduxForm } from 'redux-form/immutable';
import { Form, InjectedFormProps } from 'redux-form';

import { Flex, H2, Link, Button, Field, H1 } from '@tn/retune';

import PhoneCompatibilityModal from 'containers/DetailPage/SimPage/PhoneCompatibilityModal';

import './index.scss';
import PhoneCompatibilityModalDeviceChecker from '../PhoneCompatibilityModalDeviceChecker';

export const validation = (values) => {
    const regexESNHex = /^[0-9a-fA-F]{8}$/; // Hex ESN (eight digit mixed case hex)
    const regexESNDec = /^[0-9]{11}$/; // Decimal ESN candidate (eleven decimal digits further validation is possible)
    const regexIMEINoCheckDec = /^[0-9]{14}$/; // Decimal IMEI with no check digit (fourteen digit decimal)
    const regexMEIDNoCheckHex = /^[0-9a-fA-F]{14}$/; // Hex MEID with no check digit (fourteen digit mixed case hex)
    const regexIMEIWithCheckDec = /^[0-9]{15}$/; // Decimal IMEI with check digit (fifteen digit decimal)
    const regexMEIDWithCheckHex = /^[0-9a-fA-F]{15}$/; // Hex MEID with with check digit (fifteen digit mixed case hex)
    const regexMEIDDec = /^[0-9]{18}$/; // Decimal MEID (eighteen digit decimal)

    if (values) {
        const cleanValues = values.replace(/[\s-]+/g, '');
        if (
            !(
                regexESNHex.test(cleanValues) ||
                regexESNDec.test(cleanValues) ||
                regexIMEINoCheckDec.test(cleanValues) ||
                regexMEIDNoCheckHex.test(cleanValues) ||
                regexIMEIWithCheckDec.test(cleanValues) ||
                regexMEIDWithCheckHex.test(cleanValues) ||
                regexMEIDDec.test(cleanValues)
            )
        ) {
            return 'Invalid IMEI/MEID/ESN';
        }
    } else {
        return 'You must enter an IMEI/MEID/ESN';
    }
    return '';
};

export class PhoneCompatibility extends React.PureComponent<
    Props & InjectedFormProps,
    State
> {
    constructor(props: Props & InjectedFormProps) {
        super(props);

        this.state = {};

        this.onHandleSubmit = this.onHandleSubmit.bind(this);
    }

    // eslint-disable-next-line
    onHandleSubmit(values?) {
        this.setState({
            errorString: '',
        });
        let errorString = '';
        if (values) {
            // This is grabbing the ESN field, since it is the only field.
            errorString = validation(values.first());
        }
        if (errorString) {
            return this.setState({
                errorString: errorString,
            });
        }
        return this.props.onClick();
    }

    renderError() {
        const errorMessage = this.state.errorString || this.props.errorString;

        if (errorMessage) {
            return (
                <Flex
                    xs
                    direction="row"
                    marginTop={2}
                    className="uikit-text-field--error"
                >
                    <span className="uikit-text-field__message">
                        {errorMessage}
                    </span>
                </Flex>
            );
        }

        return null;
    }

    render() {
        if (this.props.deviceCheckerPage) {
            return (
                <Flex
                    direction="column"
                    className="device-check"
                    alignSelf="center"
                    fluid
                    xs
                    justify="center"
                >
                    <div id="bringYourOwnPhone">
                        <H2>Bring your own phone.</H2>
                        <H1>Free Nationwide</H1>
                        <H1 marginBottom={3}>Talk & Text.</H1>
                    </div>
                    <Flex className="subText" justify="center">
                        Check if your phone is compatible.
                    </Flex>
                    <Form
                        onSubmit={this.props.handleSubmit((values) => {
                            this.onHandleSubmit(values);
                            return false;
                        })}
                    >
                        <Flex xs justify="center" className="esn-submit-form">
                            <Flex className="esnInput">
                                <Field
                                    props={{
                                        id: 'sim-card-esn',
                                    }}
                                    name="esn"
                                    type="text"
                                    label="Enter your phone's IMEI"
                                />
                                {this.renderError()}
                            </Flex>
                            <Flex
                                md
                                xs={false}
                                className="device-checker-button--opacity"
                            >
                                <Button
                                    className="device-checker-button"
                                    inverted
                                    type="submit"
                                    label="Check My Phone"
                                    loading={this.props.loading}
                                />
                            </Flex>
                            <Flex
                                md={false}
                                xs
                                className="device-checker-button--opacity"
                            >
                                <Button
                                    className="device-checker-button"
                                    inverted
                                    type="submit"
                                    label="Check My Phone"
                                    loading={this.props.loading}
                                    wide
                                />
                            </Flex>
                        </Flex>
                    </Form>
                    <Flex
                        alignSelf="center"
                        justify="center"
                        className="need-help__link"
                    >
                        <span
                            onClick={this.props.openModal}
                            title="Need help finding your IMEI?"
                            className="subText subText-link"
                        >
                            Need help finding your IMEI?
                        </span>
                        <PhoneCompatibilityModalDeviceChecker />
                    </Flex>
                </Flex>
            );
        } else {
            return (
                <Flex
                    direction="column"
                    className="phone-compatibility"
                    alignSelf="center"
                    fluid
                    xs
                    justify="center"
                >
                    <H2>Check if your phone is compatible.</H2>

                    <Flex marginTop={3} marginBottom={2}>
                        <Link
                            onClick={this.props.openModal}
                            title="Where can I find my IMEI / MEID / ESN?"
                            size="regular"
                        >
                            Where can I find my IMEI / MEID / ESN?
                        </Link>
                        <PhoneCompatibilityModal />
                    </Flex>
                    <Form
                        onSubmit={this.props.handleSubmit(() => {
                            this.onHandleSubmit();
                            return false;
                        })}
                    >
                        <Flex marginTop={4} marginBottom={2}>
                            <Field
                                props={{
                                    id: 'sim-card-esn',
                                }}
                                name="esn"
                                type="text"
                                label="Enter your IMEI/MEID/ESN"
                                validate={validation}
                            />
                        </Flex>
                        <Flex marginTop={2} md xs={false}>
                            <Button
                                type="submit"
                                label="Continue"
                                loading={this.props.loading}
                            />
                        </Flex>
                        <Flex marginTop={2} md={false} xs>
                            <Button
                                type="submit"
                                label="Continue"
                                loading={this.props.loading}
                                wide
                            />
                        </Flex>
                    </Form>
                </Flex>
            );
        }
    }
}

interface Props {
    onClick: () => void;
    openModal: (e: React.MouseEvent<HTMLElement>) => void;
    loading: boolean;
    handleSubmit: (values: FormData) => void;
    deviceCheckerPage?: boolean;
    errorString: string;
}

interface State {
    errorString?: string;
}

export default reduxForm<{}, Partial<Props>>({
    form: 'phoneCompatibility',
    destroyOnUnmount: false,
})(PhoneCompatibility);
