import * as React from 'react';

import SuperBanner from 'components/SuperBanner';

import './index.scss';

export class BannersList extends React.PureComponent {
    getBannerSettings(isMobile: boolean) {
        return {
            link: `/wireless/sim-card`,
            image: isMobile
                ? 'https://static.textnow.com/web_sales/non-sale/store_mobile_new_v1.1_2x.jpg'
                : 'https://static.textnow.com/web_sales/non-sale/store_gateway_slot_v1.1_2x.jpg',
            background: {
                color: '#f8f8f8',
            },
        };
    }

    render() {
        return (
            <div id="banners-store">
                <SuperBanner
                    settings={this.getBannerSettings(false)}
                    mobileSettings={this.getBannerSettings(true)}
                />
            </div>
        );
    }
}

export default BannersList;
