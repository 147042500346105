import * as React from 'react';
import {
    Container,
    Flex,
    Label,
    Icon,
    ThemeProvider,
    Column,
    Row,
} from '@tn/retune';
import { IconNames } from '@tn/retune/build/lib/components/Icon';

import './index.scss';

interface IconItem {
    icon: string;
    label: string;
}

export class IconList extends React.PureComponent<Props> {
    static defaultProps: Partial<Props> = {
        centered: false,
        size: 'regular',
    };

    renderIcon(iconItem: IconItem, centered?: boolean) {
        return (
            <>
                <Icon
                    name={iconItem.icon as IconNames}
                    className={`banner-icons__tile__icon banner-icons__tile__icon--${this.props.size}`}
                />
                <Label
                    size={this.props.size === 'big' ? 'regular' : 'small'}
                    centered={centered}
                    className="banner-icons__tile__label"
                >
                    {iconItem.label}
                </Label>
            </>
        );
    }

    render() {
        let className = 'banner-icons';

        if (this.props.appearance === 'inverted') {
            className += ' banner-icons--inverted';
        }

        if (this.props.appearance === 'alternate') {
            className += ' banner-icons--alternate';
        }

        return (
            <ThemeProvider branded={false}>
                <Flex xs className={className} fluid>
                    <Container>
                        <Row>
                            {this.props.iconItemList.map(
                                (iconItem, idx: number) => (
                                    <Column
                                        xs={6}
                                        md={3}
                                        className="banner-icons__tile"
                                        key={idx}
                                        justify="center"
                                    >
                                        {this.props.size === 'big' && (
                                            <Flex
                                                marginTop={5}
                                                marginBottom={5}
                                                xs
                                                direction="column"
                                                justify="center"
                                                alignItems="center"
                                            >
                                                {this.renderIcon(
                                                    iconItem,
                                                    this.props.centered
                                                )}
                                            </Flex>
                                        )}

                                        {this.props.size === 'regular' && (
                                            <Flex
                                                marginTop={4}
                                                marginBottom={4}
                                                xs
                                                direction="row"
                                            >
                                                {this.renderIcon(
                                                    iconItem,
                                                    this.props.centered
                                                )}
                                            </Flex>
                                        )}
                                    </Column>
                                )
                            )}
                        </Row>
                    </Container>
                </Flex>
            </ThemeProvider>
        );
    }
}

export interface Props {
    iconItemList: IconItem[];
    appearance?: 'inverted' | 'alternate';
    size?: 'regular' | 'big';
    centered?: boolean;
}

export default IconList;
