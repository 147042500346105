import React from 'react';
import navbarHtml from '../../../../templates/webflow/navbar.html';
import stylesHtml from '../../../../templates/webflow/styles.html';

class Navbar extends React.PureComponent {
    render() {
        return (
            <div
                dangerouslySetInnerHTML={{ __html: stylesHtml + navbarHtml }}
            />
        );
    }
}

export default Navbar;
