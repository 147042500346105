import * as React from 'react';

import { Button, Column, Label, Row } from '@tn/retune';
import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';

import TileContent from 'components/TileContent';
import PriceLabel from 'components/PriceLabel';

export default class PhoneCard extends React.PureComponent<Props> {
    renderTileContentDetail() {
        return (
            <>
                <PriceLabel
                    size="big"
                    amount={this.props.productSet.min_sale_price}
                />
            </>
        );
    }

    render() {
        const isSoldOut = this.props.productSet.sold_out;

        const phoneNameLabel = [
            [
                <Row key={1}>
                    <Column md={false} sm={false} xs={false} lg>
                        <Label size="heading2" noTranslate>
                            {this.props.productSet.make || ''}
                        </Label>
                    </Column>
                </Row>,
                <Row key={2}>
                    <Column md={false} sm={false} xs={false} lg>
                        <Label size="heading2" noTranslate>
                            {this.props.productSet.model || ''}
                        </Label>
                    </Column>
                </Row>,
            ],
            <Row key="responsive">
                <Column md lg={false}>
                    <Label size="regular" noTranslate>
                        {this.props.productSet.name}
                    </Label>
                </Column>
            </Row>,
        ];

        return (
            <TileContent
                title={phoneNameLabel}
                productSetId={this.props.productSet.id}
                subTitle={
                    <PriceLabel
                        size="small"
                        crossOut
                        amount={this.props.productSet.min_price}
                    />
                }
                detail={this.renderTileContentDetail()}
                disabled={isSoldOut}
                footer={
                    <Button
                        label={isSoldOut ? 'SOLD OUT' : 'DETAILS'}
                        disabled={isSoldOut}
                    />
                }
            />
        );
    }
}

interface Props {
    productSet: ProductSetRecord;
}
