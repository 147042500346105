import * as React from 'react';
import { ImageLink, Flex } from '@tn/retune';
import LazyLoad, { forceCheck } from 'react-lazyload';

import './index.scss';

class SuperBanner extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);
        this.updateLazyLoad = this.updateLazyLoad.bind(this);
    }

    updateLazyLoad() {
        forceCheck();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateLazyLoad);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLazyLoad);
    }

    render() {
        const className = `super-banner`;

        return (
            <div
                className={`super-banner__wrapper ${className}`}
                style={{
                    backgroundColor: this.props.settings.background.color,
                }}
            >
                <Flex
                    className="super-banner__content"
                    xs={false}
                    md
                    justify="center"
                >
                    {
                        <LazyLoad offset={200} once>
                            <ImageLink
                                href={this.props.settings.link}
                                src={`${this.props.settings.image}?size=super-banner-d`}
                                src2x
                                alt="Promo Banner"
                                appearance="none"
                                className="super-banner__link"
                            />
                        </LazyLoad>
                    }
                </Flex>

                <Flex
                    className="super-banner__content"
                    xs
                    md={false}
                    justify="center"
                >
                    {
                        <LazyLoad offset={200} once>
                            <ImageLink
                                href={this.props.mobileSettings.link}
                                src={`${this.props.mobileSettings.image}?size=super-banner-m`}
                                src2x
                                alt="Promo Banner"
                                appearance="none"
                                className="super-banner__link"
                            />
                        </LazyLoad>
                    }
                </Flex>
            </div>
        );
    }
}

interface BannerSettings {
    link: string;
    image: string;
    background: { color: string };
}

export interface Props {
    settings: BannerSettings;
    mobileSettings: BannerSettings;
}

export default SuperBanner;
