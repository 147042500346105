/* eslint-disable max-len */
/* eslint-disable */

export default {
    en: {
        English: 'Español',
    },
    es: {
        'Your credit card information was successfully changed.':
            'La información de tu tarjeta de crédito se ha cambiado correctamente.',
        'There was an error getting your account information. Please try again later.':
            'Hubo un error al obtener la información de tu cuenta. Inténtalo de nuevo más tarde.',
        'Your billing address information was successfully changed.':
            'La información de tu domicilio de facturación se ha cambiado correctamente.',
        'You do not have any plans on record.':
            'No tienes ningún plan en registro.',
        'You can’t upgrade your plan because your TextNow Wireless account is inactive. Please reactivate your account.':
            'No se puede cambiar el plan porque su cuenta de TextNow no está activa. Por favor reactivar su plan.',
        'Server Error. Subscription status could not be retrieved':
            'Error del Servidor. Estado de la suscripción no se pudo recuperar',
        'Success. Your': 'Éxito. Tu',
        'will start': 'comenzará',
        'Your account balance is insufficient to cover the cost of upgrading your plan. Please add a credit card, or add a new PIN to increase your balance.':
            'El saldo de su cuenta es insuficiente para cubrir el coste de actualizar su plan. Por favor, añada una tarjeta de crédito, o añadir un nuevo PIN para aumentar su saldo.',
        'Sorry, the plan you’ve selected is unavailable at the moment. Please go back and select a different plan and try again.':
            'Lo sentimos, el plan que has seleccionado no está disponible en este momento. Regresa, selecciona un plan diferente y vuelve a intentarlo.',
        "Your credit card has been declined. As a result, we couldn't process your order.":
            'Tu tarjeta de crédito ha sido rechazada. Como resultado, no pudimos procesar tu pedido.',
        'Unknown error - Please try submitting again. If the problem continues contact Customer Care for assistance.':
            'Error desconocido - Por favor, intente enviarlo de nuevo. Si el problema continúa el contacto de atención al cliente para obtener ayuda.',
        'Are you sure you want to unblock this user?':
            '¿Está seguro de que desea desbloquear este usuario?',
        'You will receive calls and messages from this number.':
            'Va a recibir llamadas y mensajes de este número.',
        'Your signature information was successfully changed.':
            'Su firma ha cambiado correctamente.',
        'You are using invalid characters. Please try again.':
            'Estás utilizando caracteres inválidos. Inténtalo de nuevo.',
        '(Queued for Suspension)': '(En cola para la suspensión)',
        '(Queued for Cancellation)': '(En cola para la cancelación)',
        '(Queued for Unsuspension)': '(En cola para la reactivación)',
        'Next Period': 'Siguiente período',
        'Your order of a': 'Su orden de una',
        'is currently under review.': 'actualmente se está examinando.',
        'is ready and will be shipped promptly.':
            'está listo y será enviado puntualmente.',
        'was shipped': 'fue enviado',
        'Click here to view your tracking information.':
            'Haga clic aquí para ver su información de seguimiento.',
        'There is no payment information.': 'No hay información de pago.',
        'A Verification Email Has Been Sent!':
            '¡Un correo de verificación ha sido enviado!',
        'Your email address has already been verified':
            'Su dirección de correo electrónico ya ha sido verificado.',
        "An email address doesn't exist for your account":
            'Una dirección de correo electrónico no existe para su cuenta.',
        'Something went wrong! Please Try again at a later time':
            '¡Algo salió mal! Por favor inténtalo de nuevo más luego.',
        'Your password was successfully changed':
            'Su contraseña se ha cambiado correctamente',
        'Incorrect old password. Please enter your current password.':
            'Contraseña anterior incorrecta Por favor introduce tu contraseña actual.',
        'Your profile information was successfully changed.':
            'La información de tu perfil se ha cambiado correctamente.',
        'There was an error retrieving your pin history':
            'Hubo un error al recuperar su historial de pin',
        'PIN has been added to your account.':
            'PIN se ha añadido con éxito a tu cuenta.',
        'Oops, something appears to be wrong. Please try again later.':
            'Oops, algo parece estar mal. Por favor, inténtelo de nuevo más tarde.',
        'You entered an Invalid PIN. Please try again.':
            'Ha introducido un PIN inválido. Por favor, vuelva a intentarlo.',
        'Sorry, this PIN is no longer available. Please try another PIN.':
            'Lo sentimos, este PIN ya no está disponible. Por favor, pruebe con otro PIN.',
        'You have already redeemed this PIN.': 'Usted ya ha redimido este PIN',
        'You have entered too many PINs in a short amount of time. Please try again later.':
            'Ha introducido demasiados PIN en un corto período de tiempo. Por favor, inténtelo de nuevo más tarde.',
        'Internal server error, please try again later':
            'Se ha producido un error interno del servidor, inténtalo más tarde',
        "You haven't saved a credit card on file. Add one for automatic billing if you run out of an account balance.":
            'Usted no ha guardado una tarjeta de crédito en el archivo .Añadir una para la facturación automática si te quedas sin balance en tu PIN.',
        'There was an error with your request. Please try again':
            'Hubo un error con su solicitud. Por favor, inténtelo de nuevo',
        'There was an error with your credit card.':
            'Hubo un error con tu tarjeta de crédito.',
        'Looks like your payment failed. Update or retry your credit card before adding to your account balance.':
            'Parece que su pago ha fallado. Por favor actualice o vuelva a intentar su tarjeta de crédito antes de agregar al saldo de la cuenta.',
        'You need to have a subscription to add a credit card':
            'Usted necesita tener una suscripción para agregar una tarjeta de crédito',
        'Username/password did not match':
            'Nombre de usuario / contraseña no coincidieron',
        'You forgot to enter a password! Please try again.':
            '¡Olvidaste introducir una contraseña! Inténtalo de nuevo.',
        'You have no password set! Please login via Facebook.':
            '¡No cuentas con una contraseña establecida! Por favor, inicia la sesión a través de Facebook.',
        'Something went wrong, please try again.':
            'Algo salió mal. Por favor, vuelva a intentarlo.',
        'Unknown error': 'Error desconocido',
        'We have suggested a username below for you to use. Take it or leave it!':
            'Hemos sugerido el nombre de usuario a continuación para que lo utilices. ¡Tómalo o déjalo!',
        "There doesn't seem to be an email address associated with your Facebook account. Please add an email to your Facebook account and try again.":
            'No parece ser una dirección de correo electrónico asociada con tu cuenta de Facebook. Por favor, añade un correo electrónico a su cuenta de Facebook y vuelve a intentarlo.',
        'Your Facebook authentication seems to have expired. To fix this, Facebook recommends you visit www.facebook.com and log out and log back in to your Facebook account. Try Facebook login for TextNow again after you have completed these instructions.':
            'Su autenticación de Facebook parece haber vencido. Para solucionar esto, Facebook recomienda que visites www.facebook.com, cierres y vuelvas a iniciar tu sesión en tu cuenta de Facebook. Trata de ingresar a TextNow a través de Facebook de nuevo después de haber completado estas instrucciones.',
        'Internal server error - Please try again later.':
            'Error interno del servidor. Inténtalo de nuevo más tarde.',
        'Credit Card Information': 'Información de la tarjeta de crédito',
        'Credit Card Number': 'Número de tarjeta de crédito',
        'A credit card number is required':
            'Un número de tarjeta de crédito es requerido',
        'Please enter a valid credit card number':
            'Por favor introduzca un numéro valido de tarjeta de crédito',
        'Exp. Date': 'Venc. Fecha',
        Month: 'Mes',
        Year: 'Año',
        'Security code has to be 3 or 4 numbers.':
            'El código de seguridad debe constar de 3 o 4 números.',
        'Security code': 'Código de seguridad',
        '(3 # on back, Amex: 4 on front)':
            '(3 en la parte posterior, Amex: 4 en el frente)',
        'Billing Information': 'Información de Factura',
        'First Name': 'Nombre',
        'First name': 'Nombre',
        'Your first name is required': 'Se requiere tu nombre.',
        'Your first name is too long': 'Tu nombre es demasiado largo',
        'Last Name': 'Apellidos',
        'Last name': 'Apellidos',
        'Your last name is required': 'Se requiere tu apellido',
        'Your last name is too long': 'Tu apellido es demasiado largo',
        Address: 'Dirección',
        'Address 2': 'Dirección 2',
        'An address is required': 'Se requiere una domicilio',
        City: 'Ciudad',
        'A city name is required': 'Se requiere el nombre de la ciudad',
        State: 'Estado',
        'Please choose a state.': 'Por favor selecciona el nombre del estado.',
        Zip: 'Codigo Postal',
        'Your zipcode is invalid': 'Tu código postal no es válido',
        'Please enter a zip code': 'Por favor introduzca un codigo postal',
        Submit: 'Enviar',
        'Add a credit card to continue your service after your balance has run out.':
            'Añadir una tarjeta de crédito para continuar su servicio después de que su saldo se haya agotado',
        'Add Credit Card': 'Añadir la tarjeta de crédito',
        "Your account balance won't cover your next month's payment. The remainder will be charged to your credit card.":
            'El saldo de su PIN no cubrirá su plan el próximo mes. Por favor, añadir un nuevo PIN o pague con tarjeta de crédito.',
        'Please verify your email': 'Por favor verifique su correo electrónico',
        'to complete your sign-up process.':
            'para completar su proceso de registro.',
        'Change Email': 'Cambiar cuenta de correo electrónico',
        'Resend Email': 'Reenviar correo electrónico',
        "Your Account balance won't cover your plan next month. Please add a new PIN or pay with a credit card instead.":
            'El saldo de su PIN no cubrirá su plan el próximo mes. Por favor, añadir un nuevo PIN o pague con tarjeta de crédito.',
        'Add PIN': 'Añadir PIN',
        'Oops! Looks like your credit card payment failed. Update your credit card information. Or, press retry.':
            '¡Uy! Parece que falló el pago con tu tarjeta de crédito. Actualiza la información de tu tarjeta de crédito. O bien, presiona reintentar.',
        Retry: 'Reintentar',
        Update: 'Actualizar',
        'Payment Successful!': '¡Pago exitoso!',
        'Credit Card': 'Tarjeta de crédito',
        Expiration: 'Vencimiento',
        'Current Billing Date': 'Fecha de facturación actual',
        Amount: 'Importe',
        'mo.': 'mes',
        'Edit Credit Card Information':
            'Editar la información de la tarjeta de crédito',
        'There was a problem charging your credit card :(':
            'Hubo un problema al realizar el cargo a tu tarjeta de crédito :(',
        'Please call your credit card issuer or your bank to find out why they declined the charge and try again. Their number can be found on the backside of your card.':
            'Por favor llama al emisor de tu tarjeta de crédito o a tu banco para averiguar por qué se rechazó el cargo y vuelve a intentarlo. Su número se puede encontrar en la parte trasera de tu tarjeta.',
        'Possible reasons your card could be declined:':
            'Posibles razones por las que tu tarjeta fue rechazada:',
        'Your bank or credit card blocked the charge to protect you':
            'Tu banco o tarjeta de crédito bloqueó el cargo para tu protección',
        'You have an insufficient balance or credit limit on your card':
            'No tienes saldo o límite de crédito suficiente en tu tarjeta',
        'If the problem persists and you have contacted your bank or credit card issuer, please connect with us over chat at textnow.com/support':
            "Si el problema persiste y se comunicó con el banco o el emisor de su tarjeta de crédito, conéctese con nosotros a través del chat en <a href='/support' target='_blank'>textnow.com/support</a>",
        'Enter a valid credit card number.':
            'Ingresa un numero de tarjeta de crédito válido',
        'Invalid security code': 'Código de seguridad invalido',
        Save: 'Guardar',
        'Billing Address': 'Dirección de Facturación',
        Name: 'Nombre',
        'Edit Billing Address Information':
            'Editar información de la dirección de facturación',
        'Messaging & Calling': 'Mensajería & Calling',
        "Your settings have not yet been initalized. Please select 'Messaging' in the header and allow the page to fully load":
            "Tus ajustes no se han inicializado aún. Por favor, selecciona 'Mensajería' en el encabezado y deja que la página se cargue por completo",
        'Messaging Settings': 'Ajustes de mensajería',
        Notifications: 'Notificaciones',
        'Play a notification sound when I receive an incoming text':
            'Reproduce un sonido de notificación cuando reciba un mensaje de texto entrante',
        'Show a desktop notification when I receive an incoming text':
            'Muestra una notificación en la computadora de escritorio cuando reciba un mensaje de texto entrante',
        'Send me an email when I receive a text':
            'Envíame un correo electrónico cuando reciba un mensaje de texto',
        "Your email address hasn't been verified yet.":
            'tu correo electrónico no ha sido verificado todavía.',
        Signature: 'Firma',
        'No signature set': 'Firma no establecida',
        'Calling Settings': 'llamando Ajustes',
        Voicemail: 'Correo de voz',
        'Send missed calls to voicemail':
            'Enviar llamadas perdidas al correo de voz',
        'Blocked Numbers': 'números bloqueados',
        'Numbers will be listed here that you have blocked within the conversation screen.':
            'Los números se enumeran aquí que ha bloqueado dentro de la pantalla de conversación.',
        'Change Password': 'Cambiar la contraseña',
        'Old Password': 'Contraseña anterior',
        'New Password': 'Contraseña nueva',
        'Your password is too short': 'Tu contraseña es demasiado corta',
        'Your password is too long': 'Tu contraseña es demasiado largo',
        'Confirm New Password': 'Confirma la contraseña nueva',
        'Your password does not match': 'Tu contraseña no coincide',
        'Change Plan': 'Cambiar Plan',
        starts: 'empieza',
        'Cancel Change.': 'Cancelar Cambiar.',
        'All plans include': 'Todos los planes incluyen',
        'unlimited 2G Data': 'ilimitada de datos 2G',
        and: 'y',
        'unlimited calls & text': 'llamadas ilimitadas y texto',
        'in US & Canada.': 'en los Estados Unidos y Canadá.',
        '3G/4G LTE Data': '3G / 4G LTE de datos',
        'Current plan': 'Plan actual',
        'Next plan': 'Siguiente plan',
        'Choose plan': 'Elija el plan',
        'Your Profile': 'Tu perfil',
        Email: 'Correo electrónico',
        'Your email is required': 'Se requiere tu correo electrónico',
        'This field is required': 'Este campo es requerido',
        'Must be longer than {length} characters':
            'Debe tener más de {length} caracteres',
        'Enter a valid email':
            'Ingresa una dirección de correo electrónico válida',
        'Email is already in use':
            'La dirección de correo electrónico ya está en uso',
        'Just the basics': 'Sólo lo básico',
        'Tell us about yourself so we can serve you better.':
            'Cuéntanos acerca de tí para que podamos servirte mejor.',
        'Try us out commitment-free':
            'Pruebe nuestro servicio sin firmar contrato.',
        'Try TextNow for Free': 'Pruebe TextNow gratis',
        'Unlimited calling and texting':
            'Llamadas ilimitadas y mensajes de texto',
        'Our phones come with a 30-day money-back guarantee and 1 year warranty.':
            'Nuestros telefonos vienen con una garantia de devolución de su dinero si no esta satisfecho en 30 dias y ademas un año de garantia.',
        'Our phones come with a 30-day money-back guarantee, all taxes included and 1 year warranty.':
            'Nuestros teléfonos vienen con una garantía de devolución de dinero de 30 días, todos los impuestos incluidos y 1 año de garantía.',
        'Our plans include unlimited texting, and calling, starting as low as $9.99 per month with no contracts and all taxes included.':
            'Nuestros planes incluyen mensajes de texto ilimitados y llamadas, desde $ 9,99 por mes sin contratos y con todos los impuestos incluidos.',
        "If you aren't satisfied just send your phone back for a refund (we'll pay the postage).":
            ' Si ud. no esta satisfecho envienos su telefono para el reembolso (nosotros pagamos el costos del envio).',
        'Shop Phones & Plans': 'Comprar teléfonos y planes',
        'Sign up for your free phone number':
            'Suscribase para su numero gratis',
        'Sign up for the free Wi-Fi only version of TextNow':
            'Suscribase para la version wifi de TextNow unicamente',
        'available for download on your own device.':
            'disponible para bajar desde su propio aparato.',
        'If you like us, switch to a paid TextNow plan with your new or existing phone number.':
            'Si le gusta nuestro servicio, cambiese ahora a un plan de TextNow con un nuevo telefono o con el existente.',
        'Sign Up Free': 'Intentalo gratis',
        'Sign up free': 'Intentalo gratis',
        'SIGN UP FREE': 'INTENTALO GRATIS',
        'TextNow is available FREE on Android':
            'TextNow está disponible GRATIS en el Android',
        'TextNow is available FREE on the iPhone':
            'TextNow está disponible GRATIS en el iPhone',
        'TextNow is available FREE on Windows Phone':
            'TextNow está disponible GRATIS en Teléfonos Window',
        'International Credit': 'Créditos Internacionales',
        'Buy or earn credit to make cheap international calls':
            'Compra o gana créditos para hacer llamadas internacionales baratas',
        'Phone Number Porting': 'Transferencia del número de teléfono',
        'Want to bring your existing phone number to TextNow?':
            '¿Quieres traer a su número de teléfono existente a TextNow?',
        'Good news — TextNow lets you port your old phone number to save yourself the hassle of changing your phone number!':
            '¡Buenas noticias - TextNow permite transferir tu antiguo número de teléfono para ahorrarte la molestia de cambiar tu número de teléfono!',
        'Port Number': 'Número para transferir',
        'Phone number port in progress! Expect up to 5 days delay.':
            '¡Transferencia del número de teléfono en progreso! Espera un retraso de 5 días.',
        'Account Balance': 'Balance de Codigo Pin',
        'Your balance can be used to pay for monthly plans and international calls':
            'El saldo puede ser utilizado para pagar los planes mensuales y llamadas internacionales',
        'Add Money with credit card':
            'Agregar Dinero con una tarjeta de crédito',
        'You do not have a credit card associated to your account.':
            'Usted no tiene una tarjeta de crédito asociada a su cuenta.',
        'Add credit card': 'Agregar una tarjeta de crédito',
        Your: 'Tu',
        'ending in': 'terminando en',
        'will be charged': 'se cobrará',
        'Apply Pin Code': 'Aplicar Código Pin',
        'Enter a PIN to apply to your account':
            'Introduzca un PIN para aplicar a su cuenta',
        Apply: 'Aplicar',
        'View PIN usage history': 'Ver el historial de uso del PIN',
        'What is a PIN?': '¿Qué es un PIN?',
        'TextNow PINs provide cash payments for our plans. Your account balance gets charged before a credit card.':
            'Códigos de Pin proveen pagos en efectivo para nuestros planes. Su balance de cuenta sera cobrado antes de la tarjeta de crédito.',
        'Are you sure you want to cancel this new plan?':
            '¿Está seguro de que desea cancelar este nuevo plan?',
        Yes: 'Sí',
        'Review Changes': 'Cambios opinión',
        'Due Today': 'Para hoy',
        'You will be charged': 'Se te cobrará',
        beginning: 'comenzando',
        Confirm: 'Confirmar',
        'When do you want to start the new plan?':
            '¿Cuándo desea iniciar este nuevo plan?',
        'Upgrade Now': 'Actualizar ahora',
        'Increase your high speed data limit to':
            'Aumentar su límite de datos de alta velocidad para',
        'immediately. Your new plan will renew in':
            'inmediatamente. Su nuevo plan se renovará en',
        days: 'días',
        'Upgrade Next Period': 'Actualizar próximo período',
        'Your new plan will take effect': 'Su nuevo plan se llevará a efecto',
        Continue: 'Continuar',
        Previous: 'Anterior',
        'Account Credit': 'cuenta de crédito',
        'Remaining Balance': 'Balance restante',
        'Your credit card ending in': 'Su tarjeta de crédito que termina en',
        'today.': 'hoy.',
        starting: 'comenzando',
        "Your Account Balance won't cover your upgrade. Please add a new PIN or pay with a credit card instead.":
            'Balance de su Cuenta no cubrirá la actualización. Por favor, añadir un nuevo PIN o pagar con una tarjeta de crédito en su lugar.',
        'TextNow Account Protection': 'Protección de la cuenta de TextNow',
        'Verification failed - please try again!':
            'La verificación falló - ¡por favor intenta de nuevo!',
        'Please verify your account by completing the captcha below.':
            'Por favor completa el acertijo para verificar tú cuenta.',
        'My Account': 'Cuenta',
        'MY ACCOUNT': 'Cuenta',
        Settings: 'Ajustes',
        'Your Number': 'Tu número',
        Username: 'Nombre de usuario',
        'Buy Credit': 'Comprar Créditos',
        'Add Money': 'Agregar Dinero',
        'in international calling credit':
            'en créditos de llamadas internacionales',
        'Data Usage': 'Uso de datos',
        'Days left': 'Días restantes',
        '>Return to Login Page': 'Volver a la página de inicio',
        'Personal Info': 'Info personal',
        Password: 'Contraseña',
        'Number Porting': 'Teléfono de transferencia',
        'Windows Desktop': 'Windows para computadora de escritorio',
        'Download TextNow for free!': '¡Descarga TextNow gratis!',
        'Free texting & calling': 'Mensajes de texto y llamadas gratis',
        'from your': 'de ti',
        'Download Free for': 'Descargue gratis para',
        'Also available on': 'También disponible en',
        'No catch here! Download our free app to experience free calling and texting on any device you want!':
            '¡Aquí no hay trampas! ¡Descarga nuestra aplicación gratuita para experimentar llamadas gratuitas y mensajes de texto en cualquier dispositivo que quieras!',
        'No Phone? Check out TextNow Wireless':
            '¿No tienes teléfono? Echa un vistazo a TextNow inalámbrico',
        'TextNow Wireless is the next step in the TextNow ecosystem. We can offer you wireless service starting at $19.99/month, which means you can use TextNow anywhere at anytime - even without wifi! No contract and no commitment.':
            'TextNow inalámbrico es el siguiente paso en el ecosistema de TextNow. Podemos ofrecerte servicios inalámbricos a partir de $19.99/mes, lo que significa que puedes utilizar TextNow en cualquier lugar y en cualquier momento - ¡incluso sin wifi! Sin contrato y sin compromisos.',
        'View TextNow Powered Phones': 'Ver los teléfonos operados por TextNow',
        'Download TextNow FREE': 'Descargar TextNow GRATIS',
        'Get our free app for unlimited texting and calling with your own phone number.':
            'Consigue nuestra aplicación gratis para mensajes de texto ilimitados y llamadas con tu propio número de teléfono.',
        'Download Now': 'Descargar ahora',
        hide: 'ocultar',
        'Sign Up': 'Registrarse',
        'Username is required': 'Se requiere el nombre de usuario',
        'Username is too short': 'El nombre de usuario es demasiado corto',
        'Username is too long': 'El nombre de usuario es demasiado largo',
        'Username is already taken': 'El nombre de usuario ya existe',
        'Invalid username': 'El nombre de usuario es inválido',
        'Create Account': 'Crear una cuenta',
        'Please wait...': 'Por favor espera…',
        'Created by TextNow, Inc. ©': 'Creado por TextNow, Inc. ©',
        'All rights reserved.': 'Todos los derechos reservados.',
        'Terms of Use': 'Condiciones de uso',
        'Privacy Policy': 'Política de privacidad',
        'Unlimited 2G Fair Use Policy': 'Ilimitado Política de Uso Justo 2G',
        '2G Fair Use Policy': 'Política de uso justo de 2G',
        'California Privacy Policy': 'Política de privacidad de California',
        'Notice Of Collection': 'Aviso de cobro',
        "Don't Sell My Info": 'No venda mi información',
        'Store Locator': 'Localizador de Tiendas',
        Español: 'English',
        English: 'English',
        'Forgot username or password?':
            '¿Olvidó su nombre de usuario o contraseña?',
        'Enter the email address you used to sign up for TextNow to identify your account.':
            'Introduce la dirección de correo electrónico que usaste cuando te registraste en TexNow para identificar tu cuenta.',
        'Enter a valid email.':
            'Ingresa una dirección de correo electrónico válida.',
        'Email not found': 'Correo electrónico no encontrado',
        SUBMIT: 'ENVIAR',
        'Back to Login': 'Regresar al Inicio de Sesión',
        'Check your email': 'Revisa tu correo electrónico',
        "We've emailed you instructions to reset your password.":
            'Te hemos enviado instrucciones para cambiar tu contraseña.',
        'International Calling & Rates': 'Precios de Llamadas Internacionales',
        "Stay close to friends and family members, even though you are miles apart. We're proud to provide cheap international calling, which means you stay connected longer.":
            'Mantente cerca de tus amigos y familia, a pesar de estar a millas de distancia. Estamos orgullosos de ofrecer llamadas internacionales a muy buenos precios, para mantenerte conectado más tiempo.',
        'Search for international rates': 'Buscar tarifas internacionales',
        'Search by country': 'Búsqueda de país',
        'Popular Countries': 'Países Populares',
        from: 'Desde',
        'Free!': 'Gratis!',
        '/min': '/minuto',
        'No matching country could be found':
            'Ningún país correspondiente se pudo encontrar',
        'Free Unlimited Domestic Calling':
            'Llamadas Nacionales Ilimitadas Gratis',
        'No need to purchase or earn credit to make local calls.':
            'Sin necesidad de comprar o ganar crédito para hacer llamadas locales.',
        'Calls to USA & Canada are always unlimited.':
            'Las llamadas a EE.UU. y Canadá están siempre ilimitado.',
        'No contract, no hidden fees.': 'Sin contrato ni cuotas escondidas.',
        'Phones & Plans': 'Smartphones Y Planes',
        'Call and text online for free using TextNow.':
            'Llame y envie textos gratis usando TextNow.',
        "We've developed technology to save you money":
            'Desarrollamos tecnologia para ahorrarle dinero',
        'Our technology uses a hybrid of Wi-Fi + cellular for texting and calling. This means we can offer you low-cost plans with affordable, name-brand smartphones.':
            'Nuestra tecnologia usa un hibrido de wifi y celular para textos y llamadas. Esto significa que podemos ofrecerle planes de bajo costo con telefonos inteligentes de marca a su alcance..',
        'TextNow uses a hybrid of Wi-Fi + cellular for all of your texting and calling. Not being dependent on the big cellular networks means we can offer you low-cost plans with affordable name-brand smartphones.':
            'TextNow usa un hibrido de wifi y celular para textos y llamadas. No ser dependiente de las grandes empresas de celulares significa que podemos ofrecerle planes de bajo costo con telefonos inteligentes de marca a su alcance.',
        'Smart Phones, Smarter Plans':
            'Telefonos Inteligentes, Planes mas Inteligentes.',
        'Our plans include unlimited texting, and calling, starting as low as $9.99 per month with no contracts.':
            'Nuestros planes incluyen mensajes de texto ilimitados y llamadas, desde $ 9,99 por mes sin contratos.',
        'We even have a free, Wi-Fi only version of TextNow, available for download on your existing device.':
            'Incluso tenemos una version gratuita de wifi solamente disponible para bajar en su aparato existente.',
        'One Number, Multiple Devices': 'Un numero, muchos dispositivos.',
        'Send messages and make calls on your computer or tablet, then access them from your phone while on the go.':
            'Envie mensajes y haga llamadas desde su computadora o tablet, luego tenga acceso a ellas desde su telefono desde fuera.',
        'The free TextNow App is available on iOS, Android, Windows Phone, Windows Desktop, Mac OS X, and online at TextNow.com.':
            'La aplicación textnow esta disponible para iOS, Android, Windows Phone, Windows Desktop, Mac OSX y tambien en textnow.com.',
        'View Downloads': 'Vea Descargas',
        'Wi-Fi + Cellular = Connected Everywhere':
            'Wi-Fi + celular = Conectado todas partes',
        "Wi-Fi can provide you with added coverage in places where cell networks don't always work - like basements and apartments. No roaming fees for Wi-Fi connections also means you stay connected while travelling the world.":
            'Wifi puede darle cobertura adicional en lugares donde las redes de celular no siempre funcionan-como sotanos y apartamentos. Sin costos por roaming y conecciones de wifi, significa que puede seguir conectado cuando viaje a cualquier lugar del mundo.',
        'How does TextNow work?': '¿Cómo funciona TextNow?',
        'Super Affordable International Calling':
            'Llamadas Internacionales súper asequible',
        'TextNow offers low-cost, international calling to over 230 countries. Stay connected longer with rates starting at less than $0.01 per minute.':
            'TextNow ofrece bajo costo, llamadas internacionales a más de 230 países. Manténgase conectado ya con tarifas a partir de menos de $0.01 por minuto.',
        'View International Rates': 'Ver tarifas internacionales',
        'Want to Save Even More?': '¿Quiere ahorrar aún más?',
        'Earn money to pay for your plan or make international calls by opting-in to complete offers or watch advertisements.':
            'Gana dinero para pagar por su plan o hacer llamadas internacionales al optar-in para completar las ofertas o ver anuncios.',
        'Free texting and calling | Affordable no contract cell phone plans – TextNow':
            'Mensajes de texto y llamadas gratis | Planes de teléfonos celulares asequibles sin contrato - TextNow',
        'LOGIN WITH FACEBOOK': 'INGRESAR CON FACEBOOK',
        'or login with your username': 'inicia sesión con tu nombre de usuario',
        'Username or email': 'Nombre de usuario o correo electrónico',
        'Your password is required': 'Se requiere la contraseña',
        'Remember me': 'Recuérdame',
        LOGIN: 'INICIAR SESIÓN',
        'Page not found': 'No se encontró la página',
        'The page you entered does not exist':
            'La página que has introducido no existe',
        '375 Hagey Blvd., Suite 310': '375 Hagey Blvd, Suite 310',
        'Create New Password': 'Crea una contraseña nueva',
        'Create a new password for':
            'Crea una contraseña nueva para la cuenta de',
        "'s account": '',
        'Sorry, this token to reset your password is no longer valid.':
            'Lo sentimos, este token para restablecer la contraseña ya no es válido.',
        'Sorry, there was an interval server error. Please try again later.':
            'Lo sentimos, hubo un error en el servidor de intervalo. Inténtalo de nuevo más tarde.',
        'Confirm Password': 'Confirma la contraseña',
        'UPDATE PASSWORD': 'ACTUALIZAR CONTRASEÑA',
        'SIGN UP WITH FACEBOOK': 'REGISTRARSE CON FACEBOOK',
        'Sign up with Facebook': 'Registrarse con Facebook',
        'Sign up with Google': 'Registrarse con Google',
        'Sign in with Facebook': 'Iniciar sesión usando Facebook',
        'Sign in with Google': 'Inicia sesión con Google',
        'or sign up with email': 'o registrarse con el correo electrónico',
        'Pick a username': 'Elige un nombre de usuario',
        'Sign in': 'Iniciar la sesión',
        or: 'o',
        'reset password': 'restablecer contraseña',
        'Please verify that you are not a robot':
            'Demuestra que no eres un robot.',
        'SIGN UP': 'REGISTRARSE',
        'Signing up is free!': '¡Registrarse es gratis!',
        'Get your own phone number': 'Obtenga su propio número de teléfono',
        'Text and call from the web': 'Llama y envía mensajes desde la web',
        'Use your account to login to our free apps':
            'Usa tu cuenta para acceder a nuestras aplicaciones gratuitas',
        'Welcome to TextNow Support': 'Bienvenido a TextNow Soporte',
        'Please select the product that we can help you with.':
            'Por favor selecciona el producto con el que requieres ayuda.',
        'TextNow Wireless': 'Teléfonos de TextNow',
        'Devices + Plans': 'inalámbrico + MiFi',
        'For TextNow Wireless plan subscribers':
            'Para clientes nuevos o existentes que han comprado un plan de TextNow inalámbrico.',
        'Free and Premium': 'Gratuita y Premium',
        'TextNow App': 'Aplicación TextNow',
        'For users of our free and Premium app':
            'Para usuarios de nuestra aplicación gratuita y Premium',
        "We've developed technology that uses a combination of Wi-Fi, data, and cellular voice networks for texting and calling. What does this mean for you? Better coverage, less dropped calls, and significant savings!":
            'Hemos desarrollado la tecnología que utiliza una combinación de la tecnología Wi-Fi, datos y redes de voz celulares para enviar mensajes de texto y llamar. ¿Qué significa esto para ti? Una mejor cobertura, menos llamadas caídas, y un importante ahorro!',
        "Wi-Fi provides robust coverage in places where cell networks don't always work - like basements and apartments. No roaming fees for Wi-Fi connections also means you stay connected while travelling the world.":
            'Wi-Fi ofrece una cobertura robusta en lugares donde las redes celulares no siempre funcionan - como sótanos y apartamentos. No hay tarifas de itinerancia para las conexiones Wi-Fi también significa permanecer conectado mientras viaja el mundo.',
        'Introducing TextNow Elastic Calling Technology':
            'La introducción de la tecnología de llamadas TextNow elástico',
        "Traditional carriers don't and won't change your network. We're looking out for any issues you may have, and adjusting your connection accordingly.":
            'Los operadores tradicionales no lo hacen y no va a cambiar su red. Estamos mirando hacia fuera para cualquier problema que pueda tener, y el ajuste de la conexión en consecuencia.',
        'Enter your area code': 'Ingrese su código de área',
        'Area code': 'Codigo de Area',
        'Area Code': 'Codigo de Area',
        'Choose a phone number': 'Elige un número de teléfono',
        'Invalid area code. Example: 628':
            'Código de área inválido. Ejemplo: 628',
        'Example: 628': 'Ejemplo: 628',
        'Email address': 'Dirección de correo electrónico',
        'Email in use': 'Correo electrónico en uso',
        'Email does not exist': 'El correo electrónico no existe',
        'Did you mean to log in?': '¿Querías iniciar sesión?',
        Show: 'Espectáculo',
        Hide: 'Esconder',
        'Create account': 'Crear una cuenta',
        'Already have an account': 'Ya tienes una cuenta',
        'Log in': 'Iniciar sesión',
        'Get TextNow for free on your mobile device':
            'Obtenga TextNow gratis en su dispositivo móvil',
        'Email is required': 'Correo electronico es requerido',
        'Password is required': 'Se requiere contraseña',
        'Invalid email format': 'Formato de correo inválido',
        'Invalid email address format':
            'Formato de dirección de correo electrónico no válido',
        'Welcome back': 'Dar una buena acogida',
        'Email or Username': 'Correo electrónico o nombre de usuario',
        'Username or Email is required':
            'Nombre de usuario o correo electrónico es requerido',
        'Forgot?': '¿Olvidó?',
        "Don't have an account?": 'No tienes una cuenta?',
        'Log in with Facebook': 'Iniciar sesión con Facebook',
        'Forgot password': 'Se te olvidó tu contraseña',
        'Submit the email you signed up with to reset your password':
            'Envía el correo electrónico con el que te registraste para restablecer tu contraseña',
        'Numbers will refresh in:': 'Los números se actualizarán en:',
        seconds: 'segundos',
        "The area code you entered is unavailable, so we've shown other area codes near it":
            'El código de área que ingresaste no está disponible, por lo que hemos mostrado otros códigos de área cerca de él',
        'Maximum 4 characters': 'Máximo 4 caracteres',
        'No results for Area Code:':
            'No se encontraron resultados para el Código de área:',
        'Update your search or select a different area code':
            'Actualice su búsqueda o seleccione un código de área diferente',
        'An error has occurred. Please try again':
            'Se ha producido un error. Inténtalo de nuevo',
        'No numbers found': 'No se encontraron números',
        'Please update your search and try again':
            'Actualice su búsqueda y vuelva a intentarlo',
        Change: 'Cambio',
        'Search a 4 character word or number':
            'Buscar una palabra o número de 4 caracteres',
        'We were unable to reserve phone number':
            'No pudimos reservar el número de teléfono',
        'Please choose a different number':
            'Por favor elija un número diferente',
        'Invalid characters': 'Caracteres inválidos',
        'Your session has expired. You will now be redirected back to the login page':
            'Su sesión ha caducado. Ahora será redirigido a la página de inicio de sesión',
        'Login with facebook': 'Iniciar sesión con Facebook',
        TextNow: 'TextNow',
        Phones: 'Smartphones',
        PHONES: 'SMARTPHONES',
        Plans: 'Planes',
        PLANS: 'PLANES',
        'Free App Downloads': 'Bajar Gratis',
        Coverage: 'Cobertura',
        Reload: 'Recarga',
        'Activate Device': 'Active su Dispositivo',
        'ACTIVATE DEVICE': 'Active su Dispositivo',
        'Free Downloads': 'Bajar Gratis',
        Web: 'Web',
        Android: 'Android',
        'iPhone / iPad': 'iPhone / iPad',
        Windows: 'Windows',
        'Mac OS X': 'Mac OS X',
        'Company Info': 'Información de la Compañia',
        'About Us': 'Acerca de Nosotro',
        Careers: 'Empleos',
        Blog: 'Blog',
        Advertising: 'Publicidad',
        'Engineering Blog': 'Blog de Ingeniería',
        Contact: 'Contacto',
        Support: 'Soporte',
        SUPPORT: 'SOPORTE',
        'All Rights Reserved.': 'Todos los Derechos Reservados.',
        'Visit Online Support': 'Visite Nuestro Soporte en Linea',
        'Have a question about TextNow Wireless?':
            '¿Tiene preguntas acerca de TextNow Wireless??',
        'Question about our phones or plans?':
            '¿Preguntas acerca de nuestros telefonos y planes?',
        'Speak with a knowledgeable specialist today.':
            'Hable con un especialista hoy',
        'Need Help?': '¿necesita ayuda?',
        Press: 'La Prensa',
        'My Wireless Account': 'Mi cuenta inalámbrica',
        'Log out': 'Salir',
        'LOG OUT': 'Salir',
        'Activate Your Device': 'Activar',
        'International Rates': 'Tarifas Internacionales',
        'Download the Free App': 'Bajar Gratis',
        'DOWNLOAD THE FREE APP': 'BAJAR GRATIS',
        'App Downloads': 'Descargas del App',
        'APP DOWNLOADS': 'Descargas del App',
        Login: 'Incia sesion',
        menu: 'menú',
        MENU: 'MENÚ',
        close: 'cerrar',
        Messages: 'Mensajes',
        'Text & Call Online': 'Texto & Llamar Online',
        'TEXT & CALL ONLINE': 'Texto & Llamar Online',
        'Text & Call Online Free!': '¡Texto y llamadas en línea gratis!',
        'Log Out': 'Salir',
        'Username required': 'Nombre de usuario requerido',
        'Password required': 'Se requiere contraseña',
        'Your username and password combination is incorrect!':
            '¡Su combinación de nombre de usuario y contraseña es incorrecta!',
        'Internal Server Error.': 'Error interno del servidor.',
        'Something went wrong. Please try again.':
            'Algo salió mal. Por favor intentelo nuevamente.',
        'Have an account?': '¿Tener una cuenta?',
        'Click here to login': 'Haga clic aquí para iniciar sesión',
        'of highspeed data': 'de datos de alta velocidad',
        'Change name, password, etc.': 'Cambiar nombre, contraseña, etc.',
        'There is no TextNow account associated with your Facebook account!':
            '¡No hay una cuenta de TextNow asociada con tu cuenta de Facebook!',
        'Unable to log into your TextNow account!':
            '¡No puede acceder a tu cuenta de TextNow!',
        '{value}%': '{value}%',
        '${value}': '${value}',
        '${value} off': '${value} de descuento',
        '{value}% off': '{value}% de descuento',
        '${value} off phones': '${value} fuera de los teléfonos',
        '{value}% off phones': '{value}% fuera de los teléfonos',
        '${value} account credit': '${value} crédito de la cuenta',
        'promo applied: ${value} off': 'promo aplicado: ${value} de descuento',
        'promo applied: {value}% off': 'promo aplicado: {value}% de descuento',
        'promo applied: ${value} off phones':
            'promo aplicado: ${value} fuera de los teléfonos',
        'promo applied: {value}% off phones':
            'promo aplicado: {value}% fuera de los teléfonos',
        'promo applied: ${value} account credit':
            'promo aplicado: ${value} crédito de la cuenta',
        'promo applied: {value}% account credit':
            'promo aplicado: {value}% crédito de la cuenta',
        Expires: 'Expira',
        'Expires in': 'Expira en',
        'Please enter a valid zip code': 'Introduce un código postal válido',
        'Invalid credit card number':
            'Número de tarjeta de crédito no es válido',
        'Please enter a valid security code':
            'Introduce un código de seguridad válido',
        'Please enter a valid full name for your shipping address':
            'Por favor ingrese un nombre completo válido para su dirección de envío',
        'Please enter a valid phone number':
            'Introduce un número telefónico válido',
        'Please enter a valid full name for your billing address':
            'Ingrese un nombre completo válido para su dirección de facturación',
        'Please enter a valid MEID or ESN.': 'Introduce un MEID o ESN válido.',
        'Please enter an email for us to contact you with.':
            'Introduce una dirección de correo electrónico para ponernos en contacto contigo.',
        'Invalid password': 'Contraseña invalida',
        'Your username contains invalid characters!':
            '¡Tu nombre de usuario contiene caracteres inválidos!',
        'Your device is eligible for a TextNow plan!':
            '¡Su teléfono es elegible para un plan TextNow!',
        'You have entered an invalid ICCID. Please check your phone settings to verify.':
            'Has introducido un ICCID inválido. Comprueba la configuración del teléfono para verificar.',
        'Your ICCID is missing. Please check your phone settings to verify.':
            'Falta tu ICCID. Comprueba la configuración del teléfono para verificar.',
        'An unknown error occurred - sorry for the inconvenience. Please contact Customer Care for assistance.':
            'Se produjo un error desconocido - lamentamos las molestias. Comunícate con atención al servicio al cliente para obtener ayuda.',
        'Service in this area is unreliable.<br />In this zip code, you&#146;ll only receive high quality coverage on WiFi.':
            'El servicio en esta área no es confiable.<br />En este código postal,&#146;sólo recibirás la cobertura de alta calidad en WiFi.',
        'Your device cannot be activated. Contact your carrier to learn more.':
            'No se puede activar el dispositivo. Póngase en contacto con su operador para obtener más información.',
        'Your device cannot be activated. Contact your carrier to cancel your account.':
            'No se puede activar el dispositivo. Póngase en contacto con su operador para cancelar su cuenta.',
        'MEID or ESN is a required field.':
            'MEID o ESN es un campo obligatorio.',
        'The SIM Card in your phone cannot be activated.':
            'La tarjeta SIM del teléfono no se puede activar.',
        'Order a TextNow SIM Card': 'Solicitar una tarjeta SIM TextNow',
        'Invalid ICCID.': 'ICCID inválido.',
        'If you leave, all changes you have made to this form will be lost.':
            'Si te vas todos los cambios que has hecho a este formulario se perderán',
        'Unfortunately TextNow Wireless is not available in your area.':
            'Desafortunadamente TextNow inalámbrico no está disponible en tu área.',
        'Your login session has expired! Logout and login again to continue.':
            '¡Tu inicio de sesión ha caducado! Cierra la sesión e inicia la sesión de nuevo para continuar.',
        'Your card was not found. Please use a different credit card or re-enter your card details.':
            'No se encontró tu tarjeta. Utiliza una tarjeta de crédito diferente o vuelve a ingresar los detalles de tu tarjeta.',
        'Sorry, we experienced a system error. Please try again later.':
            'Lo sentimos, sufrimos un error del sistema. Inténtalo de nuevo más tarde.',
        'This product is not available at the moment. Please try again later.':
            'Este producto no está disponible en este momento. Inténtalo de nuevo más tarde.',
        'Your device has already been activated on our network and should be ready for use.':
            'Su dispositivo ya ha sido activado en nuestra red y debe estar listo para su uso.',
        'The CVC given does not match the credit card. Please try again.':
            'El CVC proporcionado no coincide con la tarjeta de crédito. Inténtalo de nuevo.',
        'ZIP given does not match card.':
            'El código postal no coincide con la tarjeta.',
        'Sorry, the expiry month you provided does not match the credit card. Please try again.':
            'Lo sentimos, el mes de expiración que proporcionaste no coincide con la tarjeta de crédito. Inténtalo de nuevo.',
        'Sorry, the expiry year you provided does not match the credit card. Please try again.':
            'Lo sentimos, el año de expiración que proporcionaste no coincide con la tarjeta de crédito. Inténtalo de nuevo.',
        'Your credit card has expired. Please use another credit card.':
            'Tu tarjeta de crédito ha expirado. Utiliza otra tarjeta de crédito.',
        'Sorry, the billing address you provided does not match the credit card. Please try again.':
            'Lo sentimos, la dirección de facturación que nos proporcionaste, no coincide con la tarjeta de crédito. Inténtalo de nuevo.',
        'There appears to have been an error activating your device. Please contact customer care for assistance.':
            'Parece que se ha producido un error al activar tu equipo. Comunícate con atención al servicio al cliente para obtener ayuda.',
        'You cannot purchase a new device while in bad financial standing':
            'You cannot purchase a new device while in bad financial standing',
        'Shipping Address cannot be a P.O. box':
            'El domicilio de envío no puede ser un apartado postal',
        'Insufficient balance. Add another PIN or enter a credit card to complete your purchase.':
            'Saldo insuficiente. Agregue otro PIN o ingrese una tarjeta de crédito para completar su compra.',
        'Charge could not be completed.': 'El cargo no se pudo completar.',
        'Your device has already been activated by another user.':
            'Su dispositivo ya ha sido activado por otro usuario.',
        'Checkout for': 'Checkout for',
        'I would like them to get a new phone number':
            'Me gustaría que reciban un nuevo número de teléfono',
        'When they login they will select a number':
            'Cuando inicien sesión, seleccionarán un número',
        'I would like them to use their TextNow number':
            'Me gustaría que usen su número de TextNow',
        'I would like to use my TextNow number':
            'Me gustaría utilizar mi número de TextNow',
        'I would like to get a new phone number':
            'Me gustaría tener un nuevo número de teléfono',
        'Next time you login you will select a number':
            'Elegirás un número la próxima vez que inicies la sesión en la aplicación',
        'Joni L.': 'Joni L.',
        'Kissimmee, FL': 'Kissimmee, FL',
        "'TextNow has allowed us to stay in touch with family and friends out of state much easier and WAY cheaper. I couldn't be more happy with our decision to become TextNow customers!'":
            "'TextNow has allowed us to stay in touch with family and friends out of state much easier and WAY cheaper. I couldn't be more happy with our decision to become TextNow customers!'",
        '---': '---',
        'Check Eligibility': 'Verificar los requisitos',
        'Payment Information': 'Información de Pago',
        'Order Complete': 'Orden completada',
        'Sign Up and Order': 'Regístrate y ordena',
        'Please wait while we complete your order':
            'Por favor, espere mientras completamos su pedido',
        today: 'hoy',
        'Please enter a valid location to find a retailer near you.':
            'Por favor, introduzca una ubicación válida para encontrar la tienda más cercana.',
        'We were unable to find results for the location specified. Please try again.':
            'No hemos podido encontrar resultados para la ubicación especificada. Por favor, inténtelo de nuevo.',
        'We were unable to process your request at this time, please try again.':
            'No hemos podido procesar su solicitud en este momento, por favor, inténtelo de nuevo.',
        'Free shipping': 'Envío gratis',
        'Free 2-day shipping': 'Envío gratuito de 2 días',
        'Gently Used Device Information': 'Gently Used Device Information',
        'Gently used phones may have some slight cosmetic blemishes such as scuffs, nicks, or scratches visible on the frame and display of the device. Each phone is meticulously hand inspected and tested to ensure they are fully functional. 60-day warranty.':
            'Gently used phones may have some slight cosmetic blemishes such as scuffs, nicks, or scratches visible on the frame and display of the device. Each phone is meticulously hand inspected and tested to ensure they are fully functional. 60-day warranty.',
        'Used phones are meticulously hand inspected and tested to ensure they are fully functional with a 60-day warranty. Used devices will have visible signs of cosmetic wear which could include blemishes such as scuffs, nicks, dents and scratches visible on the frame and display of the device.':
            'Used phones are meticulously hand inspected and tested to ensure they are fully functional with a 60-day warranty. Used devices will have visible signs of cosmetic wear which could include blemishes such as scuffs, nicks, dents and scratches visible on the frame and display of the device.',
        'Buy a TextNow powered phone':
            'Compre un teléfono con tecnología de texto',
        'Other powerful smartphones': 'Otros smartphones potentes',
        'Missing fields below': 'Hay campos vacíos abajo',
        'Your Email': 'Tu correo electrónico',
        'Something went wrong with your signup. Please try again':
            'Algo salió mal con tu registro. Inténtalo de nuevo',
        'The email address provided is not valid':
            'La dirección de correo electrónico no es válida',
        'This user cannot be invited to your family plan.':
            'This user cannot be invited to your family plan.',
        '(FREE UPGRADE to 2-day)': '(ACTUALIZACIÓN GRATUITA a 2 días)',
        'Thank you for your order.': '¡Gracias por tu pedido!',
        'Your TextNow account has been setup and your device shipped shortly. Once it does, you will receive an email with a tracking code.':
            'TextNow su cuenta ha sido configurada y su dispositivo enviado en breve. Una vez que lo reciba, recibirá un correo electrónico con un número de seguimiento.',
        A: 'Un',
        'Only one code may be used per order':
            'Solo se puede usar un código por orden',
        'Invalid Promo Code.': 'Código de promoción inválido.',
        'Invalid Promo Code. Your previous valid promo code is still applied':
            'Código de promoción inválido Su código de promoción válido anterior todavía se aplica',
        'Promo Applied': 'Promo Aplicado',
        'When your order is complete': 'Cuando su pedido esté completo',
        "will be added to your account. Once it's activated, you can use this on your next monthly bill.":
            "will be added to your account once it's activated. You can use this on your next monthly bill.",
        '{0} has been taken off your subtotal.':
            '{0} ha sido eliminado de su subtotal.',
        'Your order is not complete! By leaving this page your order will be cancelled. Your credit card will not be charged.':
            'Su pedido no está completo! Al dejar esta página su pedido será cancelado. no se le cobrará de su tarjeta de crédito.',
        'The username': 'El nombre de usuario',
        'is already in use by another account':
            'ya está en uso por otra cuenta',
        'The email': 'El correo electrónico',
        'Confirm your SIM card number': 'Confirma tu número de tarjeta SIM',
        'Enter SIM Number': 'Introduzca el número SIM',
        'Select a Plan': 'Seleccione un plan',
        'Add Payment Method': 'Agregar método de pago',
        'Connect to Network': 'Conectarse a la red',
        'Select a brand': 'Seleccione una marca',
        Other: 'Otro',
        'Your device is not eligible for a TextNow plan.':
            'Tu equipo no es elegible para un plan deTextNow.',
        'To view available phones,': 'Para ver los teléfonos disponibles,',
        'visit the store.': 'visita la tienda.',
        'Select your current phone carrier':
            'Seleccione su compañía telefónica actual',
        Clare: 'Clare',
        'St. Louis, MO': 'St. Louis, MO',
        '"Love textnow! My data plan is just what I wanted in phone service."':
            '"¡Ame el texto ahora! Mi plan de datos es justo lo que quería en el servicio telefónico."',
        Jon: 'Jon',
        'Boca Raton, FL': 'Boca Raton, FL',
        '"The best value on the PLANET!"': '"El mejor valor en el PLANETA!!"',
        Kayla: 'Kayla',
        'Holly Hill, SC': 'Holly Hill, SC',
        '"I love being a customer with textnow wireless. The service is great."':
            '"Amo ser un cliente con el texto ahora wireless. El servicio es genial."',
        'Please enter a valid email': 'Por favor introduce un email válido',
        "Thanks! We'll email you information on upcoming sales.":
            '¡Gracias! Te enviaremos emails con información sobre nuestras próximas ofertas.',
        'Your credit card has been saved':
            'Su tarjeta de crédito se ha guardado',
        "You're Almost There!": '¡Ya casi has terminado!',
        'will be added to your account, which you can use on your next monthly bill.':
            'se agregará a su cuenta, que puede utilizar en su próxima factura mensual.',
        'Please follow these steps on your phone to complete your activation:':
            'Sigue los siguientes pasos en tu teléfono para completar la activación:',
        'Please connect to a Wi-Fi network.': 'Por favor conectarse a Wi-Fi.',
        'Open your phone app (not TextNow).':
            'Abra su aplicación de teléfono (No TextNow) .',
        Dial: 'Marque',
        'into the phone app and tap Call.':
            'En la aplicación de teléfono y presiona llamar.',
        "A 'Starting Service Update' message will appear - tap OK.":
            'Aparecerá un mensaje de Comenzando el Servicio de actualización - presione OK..',
        'If update is successful Service Update Complete message will appear after about a minute - tap OK.':
            'Si la actualización se realiza correctamente Servicio de actualización completa aparecerá después de un minuto - presione OK.',
        'Open your App Store and': 'Abra su App Store y',
        'Download TextNow.': 'Baja TextNow.',
        'If you already have it downloaded, update to the latest version. Follow the registration instructions in the app to start using your account.':
            'Si ya lo has bajado, actualiza la aplicación a la última versión. Sigue las instrucciones de registro para empezar a utilizar tu cuenta.',
        'Please ensure your mobile data is turned on.':
            'Asegúrese de que sus datos móviles estén activados.',
        'Your MSL Code is': 'Su Código MSL es',
        "You'll need this number in the next step, so please note it down.":
            'Usted necesitará este número en el paso siguiente, así que por favor téngalo en cuenta.',
        'For Nexus devices, type': 'Para los teléfonos Nexus, escriba',
        'into your native Android dialpad (not TextNow).':
            'En su teclado nativo de Android (no TextNow).',
        'For all other devices, type':
            'Para el resto de los teléfonos, escriba',
        'After your phone restarts, open the Play Store and':
            'Después de reiniciar el teléfono , abra el Play Store y',
        'Turn off your WiFi, open your web browser, and navigate to a website of your choice.':
            'Apague el WiFi , abra su navegador web y vaya a un sitio web de su elección.',
        "If you are unable to browse the internet, your activation hasn't yet been completed. Please contact support for assistance":
            'Si usted no puede navegar por Internet, la activación aún no se ha completado. Por favor llame a servicio al cliente al',
        'Finished Activation?': '¿Completo la activación?',
        'Go to Web App': 'Ir a la aplicación web',
        'View Your Account': 'Ver Su Cuenta',
        Loading: 'Cargando',
        'Device Identifier': 'Identificador del equipo',
        'Information about your Device': 'Información sobre tu equipo',
        'Device Type': 'Tipo de equipo',
        'Please Select Device Type': 'Selecciona el tipo de equipo',
        iOS: 'iOS',
        'MEID or IMEI': 'MEID o IMEI',
        'Where can I find this?': '¿Dónde puedo encontrar esto?',
        MEID: 'MEID',
        'of data at 4G LTE speed': 'de los datos a velocidad de 4G LTE',
        Unlimited: 'Ilimitado',
        'SIM Card Number': 'Número de la tarjeta SIM',
        'Your ICCID lets us check if your phone can be activated':
            'Su ICCID nos permite comprobar si su teléfono puede ser activado',
        ICCID: 'ICCID',
        'Not required for your device': 'No se requiere para su teléfono',
        'We have bad news, and': 'Tenemos malas noticias y',
        good: 'bueno',
        'news.': 'noticias.',
        'Your phone cannot be activated today. The good news is your phone can be activated using a Textnow SIM card.':
            'El teléfono no se puede activar hoy. La buena noticia es que su teléfono puede ser activado con una tarjeta SIM de Textnow.',
        'VIEW DETAILS': 'VER DETALLES',
        'Select Plan': 'Seleccione el Plan',
        'Check Coverage': 'Revisa la cobertura',
        Optional: 'Opcional',
        'Enter your zip code to check the network coverage in your area.':
            'Introduzca su código postal para comprobar la cobertura de la red en su área.',
        'Zip Code': 'Código postal',
        'Activate your phone': 'Active su teléfono TextNow o Sprint',
        'Activate your phone on TextNow’s wireless network in a few easy steps.':
            'Activate your phone on TextNow’s wireless network in a few easy steps.',
        'We’ll even give you your': 'We’ll even give you your',
        'first month of service for free.': 'primer mes de servicio gratis.',
        '1-month free service promotion can only be used one time and applies to new customers only.':
            '1 mes de promoción de servicio gratuito sólo se puede utilizar una sola vez y se aplica a nuevos clientes solamente.',
        'Activate your device': 'Activar el dispositivo',
        'I have a TextNow SIM card': 'Tengo una tarjeta SIM TextNow',
        'Activate Sim': 'Activar Sim',
        'I have an eligible Sprint or TextNow Phone':
            'Tengo un teléfono Sprint o TextNow elegible',
        'Activate Phone': 'Activar Teléfono',
        'Complete these steps': 'Completa estos pasos',
        'Punch out the SIM Card and insert it into your phone.':
            'Extraiga la tarjeta SIM e insértela en su teléfono.',
        'Connect to Wi-Fi.': 'Connect to WiFi',
        'Download the latest version of the free TextNow app from the App Store.':
            'Descargue la última versión de la aplicación gratuita TextNow desde la App Store.',
        'Download the latest version of the free TextNow app from the Google Play Store.':
            'Download the latest version of the free TextNow app from the Google Play Store',
        'Download the latest version of the free TextNow app on your phone.':
            'Descargue la última versión de la aplicación gratuita TextNow en su teléfono.',
        'Open the TextNow app and login or create an account.':
            'Open the TextNow app and login or create an account',
        'Open the menu': 'Abrir el menú',
        'Tap Activate Data Plan': 'Toca Activar plan de datos',
        'and follow the on-screen instructions.':
            'Y siga las instrucciones en pantalla.',
        'Follow the on-screen instructions.':
            'Follow the on-screen instructions',
        'Payment Successful': '¡Pago exitoso!',
        'Complete Activation': 'La activación completa',
        'Optional step': 'paso opcional',
        'Add a credit card to continue your service even after your account balance runs out':
            'Añade una tarjeta de crédito para continuar su servicio incluso después de que el saldo de la cuenta se acabe.',
        'Skip this step': 'Omitir este paso',
        'We can keep your credit card on file for automatic billing':
            'Podemos mantener su tarjeta de crédito en el archivo para la facturación automática',
        "This credit card won't be charged until your account balance runs out.":
            'Esta tarjeta de crédito no será cargada hasta que el saldo de su PIN se agote.',
        'Credit card number': 'Número de tarjeta de crédito',
        '(Pre-paid cards require a 2 month plan payment upfront)':
            '(Las tarjetas prepago requieren un pago por adelantado de 2 meses)',
        'Expiration Date': 'Fecha de expiración',
        CVV: 'CVV',
        '(3 on back, Amex: 4 on front)':
            '(3 en la parte posterior, Amex: 4 en el frente)',
        'Street Address': 'Domicilio',
        'Street Address 1': 'Domicilio 1',
        'Street Address 2': 'Domicilio 2',
        'Please Select State': 'Selecciona el nombre del estado',
        'Have a promo code?': '¿Tienes un código de promoción?',
        'promo applied': 'promo aplicado',
        'Promo code applied': 'Código de promoción aplicado',
        'Promo code applied!': 'Código de promoción aplicado!',
        'View details': 'Ver detalles',
        Close: 'Cerca',
        'Enter Promo/Referral code': 'Ingrese codigo promocional',
        Cancel: 'Cancelar',
        'Complete Order': 'Completar Orden',
        '30-day money back guarantee':
            '30 días de garantía de devolución de dinero',
        Billing: 'Facturación',
        "If the problem persists and you have contacted your bank or credit card issuer, please connect with us over chat at <a href='/support' target='_blank'>textnow.com/support</a>":
            "Si el problema persiste y se comunicó con el banco o el emisor de su tarjeta de crédito, conéctese con nosotros a través del chat en  <a href='/support' target='_blank'>textnow.com/support</a>",
        'Same as shipping': 'Igual al del envío',
        'Full Name': 'Nombre Completo',
        'Credit card': 'Credit card',
        'Bundle pricing applied:': 'Precios de paquetes aplicados:',
        "You've entered a pre-paid card": 'Has ingresado una tarjeta prepagada',
        'We require that pre-paid card users pay for 2 months in advance.':
            'Requerimos que los usuarios de tarjetas prepago paguen por 2 meses por adelantado.',
        "Don't want to pay 2 months up front?":
            '¿No quieres pagar 2 meses por adelantado?',
        'Enter a credit or debit card.':
            'Ingrese una tarjeta de crédito o débito.',
        'Credit / Debit Card pricing applied':
            'Se aplica el precio de la tarjeta de crédito / débito',
        'Secure checkout': 'Pago seguro',
        Checkout: 'Revisa',
        'E-Gift Card': 'Tarjeta E-Gift',
        'Your code will be immediately available on the next screen after payment is submitted. You will also receive an email with the code. To send the e-gift card directly to the recipient check the box below.':
            'Su código estará inmediatamente disponible en la siguiente pantalla después de que se envíe el pago. También recibirás un correo electrónico con el código. Para enviar la tarjeta de regalo electrónico directamente al destinatario marque la casilla de abajo.',
        'Send E-Gift Card directly to the recipient':
            'Envíe la tarjeta de regalo electrónico directamente al destinatario',
        'Recipient Email': 'Receptor de E-mail',
        'Recipient Name (optional)': 'Nombre del destinatario (opcional)',
        'Gift Message (optional)': 'Mensaje de regalo (opcional)',
        'Choose Password': 'Elegir una contraseña',
        'By continuing, I agree to the': 'Al continuar, acepto el',
        'Privacy Policy.': 'Política de privacidad.',
        'Delivery Address': 'Dirección de entrega',
        'Shipping Address': 'Domicilio de envío',
        '(No P.O. Boxes)': '(No. de apartado postal)',
        'Phone Number': 'Numero de teléfono',
        'ICCID* (Only applies to LTE devices. Look in Settings > About Phone > Status > ICCID)':
            'ICCID* (Sólo aplica a los equipos de LTE. Busca en Ajustes> Acerca del teléfono> Estado> ICCID)',
        'ICCID* (Look in Settings > General > About on your iOS Device)':
            'ICCID* (Busca en Ajustes> General> Acerca de tu equipo iOS)',
        '* If applicable': '* Si es aplicable',
        'Submit Order': 'Enviar el pedido',
        'EXP:': 'EXP:',
        'Same as Last Purchase': 'Igual que la última compra',
        'Our wireless phones and plans are currently unavailable in Canada':
            'Nuestros teléfonos móviles y planes no están actualmente disponibles en Canadá',
        "Enter your e-mail and we'll let you know when we launch in Canada.":
            'Ingresa tu e-mail y te avisaremos cuando lancemos en Canadá.',
        "Thank you. We'll let you know when we launch in Canada.":
            '¡Gracias ! Nosotros te avisaremos cuando lancemos en Canadá.',
        'Please enter a valid e-mail.':
            'Por favor introduzca una dirección de correo electrónico válida.',
        'E-mail Address': 'Correo electrónico',
        'Store Locations Coming Soon': 'Ubicación de las tiendas Próximamente',
        'TextNow Wireless phone plans will soon be available for cash purchase at select locations near you. Enter your email to be notified when cash payments launch.':
            'Planes de teléfonos inalámbricos TextNow pronto estarán disponibles para su compra en efectivo en ciertas localidades cerca de usted. Ingrese su correo electrónico para recibir una notificación cuando los pagos en efectivo lanzamiento.',
        'Email Address': 'Dirección de correo electrónico',
        'Interested in selling TextNow products and services?':
            '¿Interesado en la venta de productos y servicios TextNow?',
        'Protected information.': 'Protected information',
        'You will be refunded your payment if your order doesn’t pass fraud screening. If you don’t clear the screening in 7 days, your order will be automatically cancelled.':
            'For the sake of your security, we sometimes put orders through secondary screening. You will not be charged for this order until fraud screening is complete.',
        'For your security, we want to confirm your identity.':
            'For your security, we put some orders through secondary screening.',
        'To process your order, we’ll need to verify that you are the card holder.':
            'To process your order, we’ll need to verify that you are the card holder.',
        'Please submit the following documents':
            'Please submit the following documents',
        'Photo of credit card': 'Photo of credit card',
        'Upload a picture of your credit card. We only need to see your name, last 4 digits of your credit card, and expiration date. <b>No Photocopies.</b>':
            'Upload a picture of your credit card. We only need to see your name, last 4 digits of your credit card, and expiration date. <b>No Photocopies.</b>',
        Select: 'Seleccionar',
        'Photo ID': 'Photo ID',
        'Upload any government ID that shows your photo and address. Bus passes, student cards, etc. will not qualify. <b>No Photocopies.</b>':
            'Upload any government ID that shows your photo and address. Bus passes, student cards, etc. will not qualify. <b>No Photocopies.</b>',
        Processed: 'Procesada',
        'Security screening': 'Examen de seguridad',
        'One more thing before we ship your order':
            'Una cosa más antes de que enviemos su pedido',
        'In an effort to reduce fraudulent orders, we need to verify you are the card holder':
            'En un esfuerzo por reducir pedidos fraudulentos, necesitamos verificar que usted es el titular de la tarjeta',
        'Something went wrong.': 'Algo salió mal.',
        'For you security we need to verify that you are the card holder':
            'Para su seguridad, necesitamos verificar que usted es el titular de la tarjeta',
        'Enter verification code': 'Ingrese el código de verificación',
        'The charge is labeled TEXTNOW followed by your 5 digit verification code':
            'El cargo se etiqueta TEXTNOW seguido de su código de verificación de 5 dígitos',
        'Back to security methods': 'Volver a los métodos de seguridad',
        'Use verification code': 'Usa el código de verificación',
        'A verification code was sent to your financial institution. It is located in the description of this purchase on your online credit card statement.':
            'Se envió un código de verificación a su institución financiera. Se encuentra en la descripción de esta compra en el extracto de su tarjeta de crédito en línea.',
        'Upload documents': 'Subir documentos',
        'Upload a photo of your credit card and identification':
            'Cargue una foto de su tarjeta de crédito e identificación',
        'You have 7 days to complete your screening before your order is canceled and refunded':
            'Tiene 7 días para completar su evaluación antes de que su orden sea cancelada y reembolsada',
        'Where do I find my verification code?':
            '¿Dónde encuentro mi código de verificación?',
        'Verification code': 'Código de verificación',
        'Finding my verification code': 'Encontrar mi código de verificación',
        'Credit card statement example:':
            'Ejemplo de extracto de tarjeta de crédito:',
        'Your order has been cancelled': 'Su orden ha sido cancelada',
        'Your order has been refunded for the full purchase price. It can take 8-10 days for this to be posted on your credit card statement.':
            'Su pedido ha sido reembolsado por el precio total de la compra. Pueden transcurrir entre 8 y 10 días para que se publique en el resumen de su tarjeta de crédito.',
        'Shop Phones': 'Comprar teléfonos',
        'TextNow Coverage': 'Cobertura de TextNow',
        'Depending on your device and carrier, TextNow chooses between two different LTE networks to provide you the best possible experience.':
            'Dependiendo del dispositivo y el operador, TextNow elige entre dos redes LTE diferentes para ofrecerle la mejor experiencia posible.',
        'Select a device / network': 'Seleccione un dispositivo / red',
        Network: 'Red',
        Edit: 'Editar',
        'View coverage for TextNow devices':
            'Ver cobertura para dispositivos TextNow',
        'View coverage for my own device or SIM':
            'Ver la cobertura de mi propio dispositivo o tarjeta SIM',
        "Already know what network you're looking for?":
            '¿Ya sabes qué red buscas?',
        CDMA: 'CDMA',
        GSM: 'GSM',
        'Coverage map': 'Mapa de cobertura',
        '{0} additional payments of ${1} are due before the {2} will be shipped.  You will receive an email shortly to set up your payment schedule.':
            '{0} additional payments of ${1} are due before the {2} will be shipped.  You will receive an email shortly to set up your payment schedule.',
        'Pay with PIN Code': 'Pagar con Código de Pin',
        Directions: 'Direcciones',
        'View Phones': 'View Phones',
        Review: 'Revisión',
        Reviews: 'Comentarios',
        'payments of': 'payments of',
        'BUY NOW': 'COMPRA AHORA',
        Only: 'Sólo quedan',
        'left in stock.': 'disponibles.',
        'people watching.': 'personas mirando.',
        'Selected Phone': 'Teléfono seleccionado',
        'Sold Out': 'Agotado',
        'SOLD OUT': 'AGOTADO',
        'Out of Stock': 'Agotado',
        'This phone is currently unavailable.':
            'Este teléfono está disponible en este momento.',
        'Phone Upgrade': 'Actualiza tu teléfono',
        'Order this phone to be used with your existing TextNow account.':
            'Ordenar este teléfono para utilizar con su cuenta actual de TextNow.',
        'Selected Monthly Plan': 'Plan Mensual Seleccionado',
        '/ month after the first month': '/ mes después del primer mes',
        'Buy as a gift': 'Comprar como regalo',
        Gifting: 'Regalar',
        "Subscribe as a gift by prepaying for 3 or 12 months of service. We'll send a phone that is ready to be activated when it is turned on for the first time.":
            'Suscribirse como un regalo por pago por adelantado de 3 ó 12 meses de servicio. Te enviaremos un teléfono que está listo para ser activado cuando se enciende por primera vez.',
        Gift: 'Regalo',
        'Frequently asked questions': 'Preguntas frecuentes',
        'Frequently Asked Questions': 'Preguntas Frecuentes',
        'View more questions': 'Ver más preguntas',
        BACK: 'ATRÁS',
        'E-Gift Cards': 'Tarjetas E-Gift',
        'The perfect gift to stay in touch, TextNow is excited to offer gift cards to be given to those you love most.':
            'El regalo perfecto para mantenerse en contacto, TextNow está emocionado de ofrecer tarjetas de regalo para dar a aquellos que más te gustan.',
        'Includes 1 free month of service*':
            'Incluye 1 mes gratis de servicio*',
        'Select your gift': 'Seleccione su regalo',
        'Give a gift of:': 'Dar un regalo de:',
        'EXPLORE ALL PHONES': 'EXPLORAR TODOS LOS TELÉFONOS',
        '/mo': 'al mes',
        'data at 2G speed': 'datos a velocidad de 2G',
        'Talk and Text to US & Canada':
            'Conversación y texto a EE.UU. y Canadá',
        'Monthly plans & pricing': 'Planes mensuales y precios',
        'Available for LTE devices on the CDMA network':
            'Disponible para dispositivos LTE en la red CDMA',
        'Users who exceed monthly data limit will not be charged overage fees, speeds will reduce to 2G until the next cycle.':
            'A los usuarios que superen el límite de datos mensual no se les cobrarán tarifas excedentes, las velocidades se reducirán a 2G hasta el siguiente ciclo.',
        'Users who exceed their included data allowance will experience reduced speeds until the next cycle.':
            'Los usuarios que excedan su asignación de datos incluida experimentarán velocidades reducidas hasta el próximo ciclo.',
        'All plans include unlimited talk & text to USA and Canada.':
            'Todos los planes incluyen llamadas y mensajes de texto ilimitados a EE. UU. Y Canadá. ',
        'Data plans include unlimited 2G data. No contract.':
            'Los planes de datos incluyen datos ilimitados de 2G. Sin contrato.',
        'Hot-spotting available for LTE': 'Hot-spotting disponible para LTE',
        'devices on the CDMA network': 'dispositivos en la red CDMA',
        'Hot-spotting available for LTE devices on the CDMA network':
            'Hot-spotting disponible para LTE dispositivos en la red CDMA',
        '*Promo price for the first month then ${value} a month.':
            '*Precio promocional para el primer mes y luego ${value} al mes',
        'Unlimited LTE*': 'LTE ilimitado*',
        'All plans include unlimited talk & text to USA and Canada. Data plans include unlimited 2G data.':
            'Todos los planes incluyen llamadas y mensajes de texto ilimitados a EE. UU. Y Canadá. Los planes de datos incluyen datos ilimitados de 2G.',
        'All plans include unlimited talk & text to USA and Canada. Data plans include unlimited 2G data. No contract.':
            'Todos los planes incluyen llamadas y mensajes de texto ilimitados a EE. UU. Y Canadá. Los planes de datos incluyen datos ilimitados de 2G. Sin contrato.',
        'Unlimited data at 2G speeds is subject to our':
            'Datos ilimitados a velocidades 2G está sujeto a nuestra',
        'Unlimited 2G Data Fair Use Policy':
            'Ilimitado Política de Uso Justo de datos 2G',
        'Select your plan': 'Seleccione su plan',
        'All plans': 'Todos los planes',
        'include unlimited talk & text to USA and Canada. Data plans include unlimited 2G data':
            'incluye llamadas y mensajes de texto ilimitados a EE. UU. y Canadá. Los planes de datos incluyen datos 2G ilimitados',
        'LTE Data': 'Los datos LTE',
        'for the first month': 'para el primer mes',
        then: 'después',
        month: 'mes',
        'Hear what our customers think':
            'Escucha lo que nuestros clientes piensan',
        'Load more reviews': 'Cargar más comentarios',
        'Want a SIM by': 'Quieres un SIM por',
        'Want a phone by {shippingPromiseDay}?':
            '¿Quieres un teléfono por {shippingPromiseDay}?',
        'Order within the next': 'Pide en los próximos',
        'SIM Card': 'Tarjeta SIM',
        'Use your own Phone with TextNow':
            'Utilice su propio teléfono con TextNow',
        FREE: 'GRATIS',
        Free: 'Gratis',
        'First month of service free!*': 'Primer mes de servicio gratis!*',
        'How do TextNow SIM Cards work?':
            '¿Cómo funcionan las tarjetas SIM de TextNow?',
        'TextNow uses a hybrid of Wi-Fi + cellular for texting and calling. This means we can offer you low-cost plans':
            'TextNow utiliza un híbrido de Wi-Fi + celular para mensajes de texto y llamadas. Esto significa que podemos ofrecerle planes de bajo costo',
        'Use your number on multiple devices':
            'Utilice su número en varios dispositivos',
        'Affordable plans': 'Planes asequibles',
        'Unlimited calling & messaging': 'Calling y mensajería ilimitados',
        '“Love TextNow! My data plan is just what I wanted in phone service: affordable and just what I need.”':
            '“¡Love TextNow! Mi plan de datos es justo lo que quería en el servicio telefónico: asequible y justo lo que necesito.”',
        'Clare M.': 'Clare M.',
        'Love TextNow! My data plan is just what I wanted in phone service: affordable and just what I need.':
            '¡Love TextNow! Mi plan de datos es justo lo que quería en el servicio telefónico: asequible y justo lo que necesito.',
        MISSOURI: 'MISSOURI',
        'Details about bringing your own phone':
            'Detalles sobre cómo traer tu propio teléfono',
        'What can I expect if I bring my own phone?':
            '¿Qué puedo esperar si traigo mi propio teléfono?',
        'Bringing your own phone to TextNow is a cheap and easy option. We love that you can join us with a phone you want, and still benefit from our amazing technology, deals, and offers. When you submit your order we will be sure to check your eligibility before charging you a cent.':
            'Traer su propio teléfono a TextNow es una opción barata y fácil. Nos encanta que pueda unirse a nosotros con un teléfono que desee, y aún así beneficiarse de nuestra increíble tecnología, ofertas y ofertas. Cuando envíe su pedido, estaremos seguros de verificar su elegibilidad antes de cobrarle un centavo.',
        'How does it work?': '¿Como funciona?',
        'We’ll ship your SIM card to you with free shipping. Once you get it, put it in your device and follow our guide to help you get set up. You will be up and running in a matter of minutes.':
            'Le enviaremos su tarjeta SIM con envío gratuito. Una vez que lo consigas, ponlo en tu dispositivo y sigue nuestra guía para ayudarte a configurarlo. Usted estará en funcionamiento en cuestión de minutos.',
        'Can I use my phone number from a different carrier with TextNow Wireless?':
            '¿Puedo usar mi número de teléfono de una compañía diferente con TextNow Wireless?',
        'Yes! Most numbers can be ported in, as long as they are active with the current carrier.':
            '¡Sí! La mayoría de los números pueden ser portados, siempre y cuando estén activos con el portador actual.',
        'Is my phone eligible?': '¿Es mi teléfono elegible?',
        'Must be a Sprint, T-Mobile, or unlocked  device with either Android or iOS operating system.':
            'Debe ser un dispositivo Sprint, T-Mobile o desbloqueado con el sistema operativo Android o iOS.',
        'Not blacklisted.': 'No en la lista negra.',
        'Not on a contract.': 'No en un contrato.',
        'Include gift card': 'Incluye tarjeta de regalo',
        'Send as gift': 'Send as gift',
        'Select Gift Card Amount':
            'Seleccione la cantidad de la tarjeta de regalo',
        'Monthly plans starting at $9.99/month':
            'Planes mensuales a partir de $9.99 / mes',
        'View plans': 'Ver los Planes',
        'VIEW PLANS': 'VER LOS PLANES',
        'Nationwide Coverage': 'Cobertura nacional',
        'View coverage map': 'Ver el mapa de cobertura',
        'Free shipping & returns': 'Envíos y devoluciónes gratis',
        '1 year warranty on all phones':
            '1 año de garantía en todos los teléfonos',
        'No contract & no credit checks':
            'Sin contrato y sin verificación de crédito',
        'HIDE ALL SPECS': 'OCULTAR TODAS LAS ESPECIFICACIONES',
        'VIEW ALL SPECS': 'VER ESPECIFICACIONES',
        'Select device & plan': 'Seleccionar dispositivo y plan',
        'To activate your phone with TextNow Wireless, the phone must be a TextNow or Sprint phone. Phones from other phone companies are not compatible with TextNow Wireless.':
            'Para activar tu teléfono con TextNow Wireless, debe ser un teléfono de TextNow o Sprint. Teléfonos de otras compañía no so compatibles con TextNow.',
        'If you have a Sprint phone, it must meet the following criteria:':
            'Si tienes un teléfono de Sprint, debe cumplir con los siguientes criterios:',
        'Must be running Android or iOS': 'Debe operar con Android o iOS',
        'Device must be off-contract and fully paid for':
            'El teléfono debe estar fuera de contrato y totalmente pagado',
        'Not marked lost or stolen': 'No marcado como perdido ni robado',
        'You are already a TextNow Wireless User!':
            '¡Ya eres usuario de TextNow Wireless!',
        'Each TextNow Account can have only one active device.':
            'Cada Cuenta TextNow sólo puede tener un teléfono activo.',
        'If the phone you are trying to activate is:':
            'Si el teléfono que está intentando activar está:',
        ' An upgrade or a replacement for your TextNow device':
            'Reemplazando tu actual teléfono TextNow',
        'Contact Support': 'Llama a servicio al cliente',
        'to assist with your upgrade': 'para ayuda con tu cambio',
        OR: 'o',
        'An additional TextNow device. Both devices will be in use.':
            'Un teléfono de TextNow adicional. Ambos teléfonos estarán en uso.',
        'Sign Out': 'Cierra la sesión',
        'and activate your phone with a New Account':
            'y activa el teléfono con una cuenta nueva',
        'Your order is being processed': 'Your order is being processed',
        'This should only take a couple of minutes':
            'This should only take a couple of minutes',
        'Check Service Coverage': 'Compruebe Cobertura del servicio',
        'Enter the zip code of the person who will be using the phone to make sure they live in a supported coverage area.':
            'Introducir el código postal de la persona que va a utilizar el teléfono para asegurarse de que viven en un área de cobertura apoyado.',
        'Enter a valid zip code': 'Introduzca un código postal válido',
        'Service in': 'servicio de',
        'is excellent.': 'es excelente.',
        'Click here': 'Haga clic aquí',
        'to check service in a different area.':
            'para comprobar el servicio en un área diferente.',
        'is unreliable. You will experience quality issues when not on WiFi':
            'es poco fiable. Usted va a experimentar problemas de calidad cuando no esté en WiFi',
        'Your Contact Information': 'Tu información de contacto',
        "We'll send your receipt here": 'Vamos a enviar su recibo aquí',
        'Your Phone Number': 'Su número de teléfono',
        Shipping: 'Envío',
        Payment: 'Pago',
        'Give the gift of unlimited talk, text & data.':
            'Dar el regalo de hablar ilimitado, texto, & datos.',
        'The perfect gift to stay in touch, TextNow is excited to offer gift cards to be given to those you love most. For use with a monthly plan or international calls.':
            'El regalo perfecto para mantenerse en contacto, TextNow está emocionado de ofrecer tarjetas de regalo para dar a aquellos que más te gustan. Para uso con un plan mensual o llamadas internacionales.',
        'Select your E-Gift value:': 'Seleccione su valor E-Gift:',
        'E-cards are only redeemable by customers with a TextNow Wireless phone plan':
            'Las tarjetas electrónicas solo son canjeables por los clientes con un plan de teléfono de TextNow Wireless',
        'Choose Gift': 'Elija un regalo',
        'BUY GIFT': 'COMPRAR REGALO',
        'Your order is a Virtual Gift Card.':
            'Su pedido es una tarjeta de regalo virtual.',
        'You will also receive an email with the code.':
            'También recibirás un correo electrónico con el código.',
        'About Your Gift': 'Acerca de su regalo',
        'Ths phone will be shipped with everything needed to get started.':
            'Mls teléfono será enviado con todo lo necesario para empezar.',
        "Didn't mean to purchase as a gift?":
            'No fue mi intención de comprar como regalo?',
        'for the regular checkout.': 'para el pago y envío regular.',
        'We’re holding your device. Checkout within':
            'We’re holding your device. Checkout within',
        'to guarantee it': 'to guarantee it',
        'ICCID is required for phones with a removable SIM card':
            'ICCID se requiere para los teléfonos con una tarjeta SIM desmontable',
        "Only phones with a removable SIM card have an ICCID. If your phone doesn't have an ICCID, leave this field blank":
            'Sólo los teléfonos con una tarjeta SIM desmontable tienen un ICCID . Si su teléfono no tiene un ICCID ,deje este campo en blanco',
        "You can locate you phone's": 'Usted puede localizar el',
        'ICCID in 2 different ways': 'ICCID de dos maneras diferentes',
        'Find it on your SIM Card': 'Encuéntralo en tu tarjeta SIM',
        'Your SIM card is either located underneath your battery or can be found in a slot at the side of your phone':
            'La tarjeta SIM se encuentra debajo de la batería, o en la ranura en la parte lateral del teléfono',
        'Find it in your Phone Settings right underneath your IMEI/ESN':
            'Encuéntralo en las configuraciones de tu teléfono debajo del código IMEI/ESN',
        'Look in Settings > About Phone > Status':
            'Buscar en Configuración > Acerca del dispositivo > Estado',
        iPhone: 'iPhone',
        'Look in Settings > General > About':
            'Buscar en Ajustes > General > Información',
        'Don’t have a removable SIM card?':
            '¿No tiene una tarjeta SIM extraíble?',
        'Your phone can be activated using a TextNow SIM card.':
            'El teléfono se puede activar mediante una tarjeta SIM TextNow.',
        'LEARN MORE': 'APRENDE MÁS',
        'Already have a Reload PIN?': 'Ya tiene un PIN de recarga?',
        'Apply PIN to your Account': 'Aplicar PIN para su cuenta',
        'Buy a TextNow phone or reload your plan at a store near you.':
            'Comprar un teléfono TextNow o recargar su plan en una tienda cerca de usted.',
        "We can't confirm if phones and pins will be in stock at all locations below. We recommend you contact the location ahead of time to confirm availability.":
            'No podemos confirmar si los teléfonos estarán disponibles en todos los lugares. Le recomendamos que contacte a la ubicación para confirmar la disponibilidad.',
        'Enter a city, state and/or zip code':
            'Introduzca un código postal de la ciudad, el estado y / o',
        'Reload with PIN': 'Reload/PIN Retailers',
        'Sign In to your account.': 'Ingresa a tu cuenta.',
        'Sign In': 'Iniciar la sesión',
        'Sign in using Facebook': 'Ingresar usando Facebook',
        'Your MEID/ESN helps us identify your phone':
            'Tu MEID/ESN nos ayuda a identificar su teléfono',
        "You can locate your phone's": 'Usted puede localizar el',
        'MEID/ESN': 'MEID/ESN',
        'in a few different ways:': 'de algunas maneras diferentes:',
        '*#06#': '*#06#',
        'into your phone app (not TextNow)':
            'en su aplicación de teléfono (No TextNow)',
        'Find it in your phone settings':
            'Encuéntralo en la configuración del teléfono',
        'Find it on your phone': 'Encuéntralo en el teléfono',
        'Turn off your phone': 'Apaga tu teléfono',
        'Remove your battery if possible': 'Si es posible remueve la batería',
        'Most phones have a white sticker underneath with the Hex MEID/ESN':
            'La mayoría de los teléfonos tienen una etiqueta blanca debajo con el HEX MEID / ESN',
        'Not ready to buy?': '¿No estás listo para comprar?',
        'Subscribe to be the 1st to know the next great deal!':
            '¡Suscríbete para ser el primero en conocer nuestras grandes ofertas!',
        'Please enter your email': 'Por favor escribe tu email',
        "Don't miss out on the next big deal!":
            '¡No te pierdas la próxima mega oferta!',
        'Your new phone & plan': 'Your new phone & plan',
        'Who will be using this phone?': 'Who will be using this phone?',
        'Provide the following information to assign this phone and plan':
            'Provide the following information to assign this phone and plan',
        'This email belongs to an existing TextNow user.':
            'This email belongs to an existing TextNow user.',
        'Would you like to add this user to your family plan?':
            'Would you like to add this user to your family plan?',
        'This email belongs to an existing TextNow Wireless user.':
            '{0} is an existing TextNow Wireless user. Would you like to add them to your family plan?',
        'Sorry, there are no retail locations within 100 miles of you.':
            'Lo sentimos, no hay puntos de venta dentro de un radio de 100 millas de usted.',
        "If you're looking to purchase a phone, check out our":
            'Si usted está mirando para comprar un teléfono, visita nuestra',
        'online store': 'tienda en línea',
        'To add money to your account, consider':
            'Para añadir dinero a su cuenta, considere',
        'adding a credit card on file': 'la adición de una tarjeta de crédito',
        'Unreliable Coverage in Your Area': 'Cobertura poco fiable en su área',
        'In this zipcode': 'En este código postal',
        'TextNow’s coverage may not work for calling. You will only be able to make reliable calls while on WiFi.':
            'Cobertura de TextNow puede no funcionar para llamar. Sólo se podrá hacer llamadas fiable, al tiempo en WiFi.',
        'Please note that we do not refund any shipping fees on your purchase if you are unsatisfied with the coverage.':
            'Tenga en cuenta que no se reembolsará cualquier tarifa de envío en su compra si no está satisfecho con la cobertura.',
        'I acknowledge that my area might not have coverage.':
            'Reconozco que mi área que no tenga cobertura.',
        'You are not authorized to make this purchase':
            'You are not authorized to make this purchase',
        'As a member of a family plan, only your plan owner can make purchases for your account.':
            'As a member of a family plan, only your plan owner can make purchases for your account.',
        'Log in with another account': 'Log in with another account',
        'Not available for family plans':
            'No disponible para planes familiares',
        'Sim cards are not currently available for purchase for a new family plan member.':
            'Las tarjetas SIM no están actualmente disponibles para la compra de un nuevo miembro del plan familiar.',
        'Have you used TextNow before?': '¿Has utilizado TextNow antes?',
        ', I have a TextNow account.': ', tengo una cuenta TextNow.',
        No: 'No',
        ', I have not used TextNow before.': ', no he utilizado TextNow antes.',
        'Month Pre-Paid Bundle': 'Paquete pre-pago de un mes',
        '*Promo price for the first month':
            '* Precio promocional para el primer mes',
        'Monthly Plan': 'Plan Mensual',
        'Promotion applied (1 Month Free)': 'Promoción aplicada (1 mes gratis)',
        'Gift Card (digital delivery)': 'Tarjeta de regalo (entrega digital)',
        'Promo Code': 'Código promocional',
        'PIN Amount': 'Monto PIN',
        'Due today': 'Para hoy',
        'You save': 'Ahorras',
        'a month': 'un mes',
        'Your Plan:': 'Tu plan:',
        Billed: 'billed',
        '*1-Month promo price': '*1-Month promo price',
        'Current Carrier Information': 'Información del proveedor actual',
        'Account Information': 'Información de la cuenta',
        'Phone Number to Port': 'Número de teléfono para transferir',
        'This is your phone number with your current carrier. This is the number that will replace your TextNow number to become your primary number on your phone. This number must stay active during the port-in request in order for your current carrier to release it to us.':
            'Este es tu número de teléfono con tu proveedor actual. Este es el número que reemplazará tu número de TextNow para convertirse en el número principal en tu teléfono. Este número debe permanecer activo durante la solicitud de transferencia para que tu proveedor actual lo libere a nuestro favor.',
        'Account Number': 'Número de cuenta',
        "This is your account number with your current carrier. It's unlikely that your account number is your phone number. If you're unsure what your account number is, please reach out to your current carrier.":
            'Este es tu número de cuenta con tu proveedor actual. Es poco probable que tu número de cuenta sea tu número de teléfono. Si no estás seguro de cuál es tu número de cuenta, contacta a tu proveedor actual.',
        'Account PIN': 'PIN de la cuenta',
        'If Applicable': 'Si aplica',
        'Your carrier may require you to have a PIN code to access your account. If so, we will require it during the number porting.':
            'Tu proveedor puede solicitar que tengas un código de PIN para acceder a tu cuenta. Si es así, lo vamos a necesitar durante la transferencia del número.',
        'Are you sure your account number is your phone number? Your port request will fail if you submit an incorrect account number. Please reach out to your current carrier if you’re unsure what your account number is.':
            '¿Estas seguro que tu número de cuenta es tu número de teléfono? Tu solicitud de transferencia fallará si envías un número de cuenta incorrecto. Contacta a tu proveedor actual si no estás seguro cuál es tu número de cuenta.',
        'Current Billing Information': 'Información de facturación actual',
        'Same as previous': 'Igual que la anterior',
        'Account Holder': 'Titular de la cuenta',
        'Contact Information': 'Información de contacto',
        'Submit Request': 'Procesar la solicitud',
        'Cancel Phone Porting': 'Cancelar transferencia de número',
        'I would like to transfer an existing phone number':
            'Me gustaría transferir un número telefónico existente',
        'We will help you transfer the phone number':
            'Te ayudaremos a transferir tu número de teléfono',
        'About Phone Number Porting':
            'Acerca de la transferencia del número de teléfono',
        'It takes 5-6 business days to complete the port-in request.':
            'Transferencia de número se  tarda 5-6 días hábiles para completar.',
        'It may take an additional 1-2 days to make the number fully usable.':
            'Se puede tomar  de 1-2 días adicionales para que el número sea plenamente utilizable.',
        'The ported in number will replace your current TextNow number.':
            'El número transferido reemplazará a su número TextNow actual',
        'The number must stay active until the porting process is complete.':
            'El número debe permanecer activo hasta que el proceso de transferencia se ha completado.',
        'Pay with Credit Card': 'Pagar con Tarjeta de Credito',
        'Enter a PIN code to apply to your account.':
            'Introduzca un código PIN para aplicar a su cuenta.',
        'You still need to pay': 'You still need to pay',
        'to complete your order.': 'to complete your order.',
        'Please apply another PIN code or enter your credit card information.':
            'Por favor, aplicar otro código PIN o introducir su información de tarjeta de crédito.',
        'Monthly Plans & Pricing': 'Planes mensuales y precios',
        'All plans include unlimited 2G data, and unlimited talk & text to USA and Canada':
            'Todos los planes incluyen datos ilimitados de 2G y conversaciones y textos ilimitados a EE.UU. y Canadá',
        'Port your number & get a $100 rebate*':
            'Porte su número y obtenga un reembolso de $ 100 *',
        'Please have the following information ready to submit your port request':
            'Tenga a mano la siguiente información para enviar su solicitud de puerto',
        "Name of authorized account holder associated to the account you're porting from":
            'Nombre del titular autorizado de la cuenta asociado a la cuenta desde la que está transfiriendo',
        "Account number associated to the account you're porting from":
            'Número de cuenta asociado a la cuenta desde la que está transfiriendo',
        "Pin number associated to the account you're porting from":
            'Número de PIN asociado a la cuenta desde la que está transfiriendo',
        "Current billing address associated to the account you're porting from":
            'Dirección de facturación actual asociada a la cuenta a la que está transfiriendo desde',
        'For more information on how to port-in your number {0}':
            'Para obtener más información sobre cómo port-in su número {0}',
        Rebate: 'Reembolso',
        'Please verify that you are not a robot.':
            'Demuestra que no eres un robot.',
        'Enter promo code': 'Introduce el código de promoción',
        'Hide promo code': 'Ocultar código de promoción',
        'Have a different promo?': '¿Tienes una promoción diferente?',
        'texting & calling to US & Canada':
            'mensajes y llamadas a EE.UU. y Canadá',
        'Price after the first month': 'Precio después del primer mes',
        'To continue with your purchase, please select an option below.':
            'Para continuar con tu compra, selecciona una de las siguientes opciones.',
        'No, I have not used TextNow before.':
            'No, No he utilizado TextNow antes.',
        'Create a new TextNow account.': 'Crear una nueva cuenta de TextNow.',
        'Yes, I have a TextNow account.': 'Sí, Tengo una cuenta de TextNow.',
        'Use existing TextNow number and credentials.':
            'Utilizar un número y credenciales existentes de TextNow.',
        'Create a new account.': 'Crear una nueva cuenta',
        'Sign up for TextNow': 'Inscríbete TextNow',
        "We've found a SIM card associated to your account.":
            'Hemos encontrado una tarjeta SIM asociada a su cuenta.',
        'Enter your SIM Card Number': 'Introduzca su número de tarjeta SIM',
        'SIM Number': 'Número de SIM',
        Help: 'Ayuda',
        'Invalid SIM number.': 'Número de SIM inválido.',
        'Try entering your SIM card number again.':
            'Intente volver a ingresar su número de tarjeta SIM.',
        'Where is my SIM Number?': '¿Dónde está mi número SIM?',
        'Your SIM Card number is the number below the bar code and on the actual SIM Card you put in your phone.':
            'El número de su tarjeta SIM es el número que se encuentra debajo del código de barras y en la tarjeta SIM real que coloca en su teléfono.',
        'You can activate your phone online':
            'Puede activar su teléfono en línea',
        "If your Sprint phone already has a SIM card you don't need to purchase a SIM Card.":
            'Si su teléfono Sprint ya tiene una tarjeta SIM que no es necesario comprar una tarjeta SIM',
        Activate: 'Activar',
        'What phone do you have?': '¿Qué teléfono tiene?',
        'Your phone is currently ineligible':
            'Su teléfono no está actualmente disponible',
        'Unfortunately your phone is currently not supported.':
            'Lamentablemente, su teléfono no es compatible actualmente.',
        'Want to switch to TextNow today?': '¿Quieres cambiar a TextNow hoy?',
        'All our phones include a 30-day guarantee with no contracts.':
            'Todos nuestros teléfonos incluyen una garantía de 30 días sin contratos.',
        'Back to the TextNow Store': 'Volver a la tienda TextNow',
        'Select your model': 'Seleccione el modelo de',
        'How do I find out what model I have?':
            '¿Cómo puedo averiguar qué modelo que tengo?',
        Selected: 'Seleccionado',
        'Check device eligibility': 'Comprobar la elegibilidad del dispositivo',
        'Great,': 'Estupendo,',
        'your device is eligible for a SIM Card':
            'Su dispositivo es elegible para una tarjeta SIM',
        'Locked phones are not eligible for a TextNow SIM card':
            'Los teléfonos bloqueados no son elegibles para una tarjeta SIM de TextNow',
        'Want to switch to TextNow today? All our phones include a 30-day money-back guarantee with no contracts.':
            '¿Quieres cambiar a TextNow hoy? Todos nuestros teléfonos incluyen una garantía de devolución de 30 días sin contactos.',
        'Is your phone unlocked?': 'Se desbloquea el teléfono?',
        'How do I find out?': '¿Cómo puedo saber?',
        'Does your phone already have a Sprint SIM card?':
            '¿Su teléfono ya tiene una tarjeta SIM Sprint?',
        'No SIM Required': 'No se requiere tarjeta SIM',
        'How do I find out if my phone is locked?':
            '¿Cómo puedo saber si mi teléfono está bloqueado?',
        'Contact your mobile wireless service provider. Devices can be unlocked with unlock codes or other software updates offered to you by your provider. Some providers will complete the unlocking process in-store, others will unlock your device remotely.':
            'Póngase en contacto con su proveedor de servicios inalámbricos móviles. Los dispositivos se pueden desbloquear con códigos de desbloqueo u otras actualizaciones de software ofrecidas por su proveedor. Algunos proveedores completarán el proceso de desbloqueo en la tienda, otros desbloquearán el dispositivo de forma remota.',
        'To learn more about unlocking your device':
            'Para obtener más información sobre cómo desbloquear tu dispositivo',
        'click here': 'haga clic aquí',
        'Call your carrier to have your device unlocked.':
            'Llame a su operador para que su dispositivo se desbloquee.',
        'Learn more about unlocking your device':
            'Más información sobre cómo desbloquear tu dispositivo',
        'Select your current Phone / Wireless Carrier':
            'Seleccione su teléfono actual / portador inalámbrico',
        'AT&T': 'AT&T',
        Sprint: 'Sprint',
        'T-Mobile': 'T-Mobile',
        Verizon: 'Verizon',
        'Your Phone': 'Your Phone',
        'I want a SIM for a different phone':
            'I want a SIM for a different phone',
        'If your device is not on the list above, it is currently not supported.':
            'Si su dispositivo no está en la lista anterior, actualmente no es compatible.',
        'Great, your device is eligible for a SIM Card':
            'Grande, el dispositivo es elegible para una tarjeta SIM.',
        Next: 'Siguiente',
        'Your account balance will cover your':
            'El balance de su cuenta cubrirá su',
        'plan.': 'plan.',
        'Confirm your order to proceed.': 'Confirme su pedido para proceder.',
        'Your order is complete!': 'Su pedido está completo!',
        'My wireless account': 'My wireless account',
        'Add another line': 'Add another line',
        'Please note:': 'Tenga en cuenta:',
        'When your SIM card arrives, make sure you use this user account:':
            'Cuando llegue su tarjeta SIM, asegúrese de usar esta cuenta de usuario:',
        'What to expect next': 'Qué esperar después',
        'You may receive an email / text from <b>confirm.orders@textnow.com</b> within the next business day to confirm some details regarding your order. Please check your inbox and junk mail folder and reply to our team to ensure timely delivery of your order.':
            'Puede recibir un correo electrónico / texto de <b> confirm.orders@textnow.com </ b> dentro del siguiente día hábil para confirmar algunos detalles relacionados con su pedido. Por favor, compruebe su bandeja de entrada y la carpeta de correo basura y responda a nuestro equipo para garantizar la entrega oportuna de su pedido.',
        'Thank you for your order': 'Thank you for your order',
        'Your order is under review. Someone from our customer service team will reach out to you within the next 24 hours with next steps.':
            'Your order is under review. Someone from our customer service team will reach out to you within the next 24 hours with next steps.',
        "Don't forget your digital e-gift card":
            'No se olvide de su tarjeta de regalo electrónico digital',
        '(We also emailed it to you)':
            '(También le hemos enviado por correo electrónico)',
        'e-gift card': 'Tarjeta de regalo electrónico',
        'PIN CODE': 'CÓDIGO PIN',
        'Your e-gift message': 'Su mensaje de regalo electrónico',
        To: 'A',
        From: 'De',
        'Print gift card': 'Imprimir tarjeta de regalo',
        "Can't wait to get started?": 'No puedo esperar para empezar?',
        'Download the free app': 'Descargar la aplicación gratuita',
        'Same as Billing': 'Igual que la facturación',
        'Existing TextNow Device': 'Equipo TextNow existente',
        'You have previously purchased a device for this TextNow account. Each account can only have one active device at a time.':
            'Previamente haz comprado un equipo para esta cuenta TextNow. Cada cuenta sólo puede tener un equipo activo a la vez.',
        The: 'El',
        'you are about to purchase is:': 'que estás a punto de comprar es:',
        'Upgrading or replacing my current TextNow device.':
            'Actualizar o sustituir mi equipo TextNow actual.',
        'This will continue using the number':
            'Este continuará utilizando el número',
        'An additional TextNow device. Both devices will be in use at the same time.':
            'Un equipo de TextNow adicional. Ambos equipos estarán en uso al mismo tiempo.',
        'You will be adding a new phone line.':
            'Vas a añadir una nueva línea telefónica.',
        'Additional Device': 'Equipo adicional',
        'To activate an additional device, you will have to sign in to a different TextNow account.':
            'Para activar un equipo adicional, tendrás que iniciar sesión en una cuenta de TextNow diferente.',
        'Would you like to sign out and continue?':
            '¿Te gustaría cerrar la sesión y continuar?',
        'Sign Out & Continue': 'Cerrar la sesión y continuar',
        'Upgrade/Replace Device': 'Actualizar/Reemplazar equipo',
        'Once you activate the newly shipped device, your current device (the previous TextNow device) will be automatically deactivated.':
            'Una vez que se activa el equipo recién enviado, tu equipo actual (el equipo TextNow anterior) se desactivará automáticamente.',
        'Are you sure you wish to proceed with the purchase?':
            '¿Estás seguro que deseas continuar con la compra?',
        'Upgrade/replace phone': 'Upgrade/replace phone',
        'When you activate your new device, your current device  will be automatically deactivated.':
            'When you activate your new device, your current device  will be automatically deactivated.',
        'Logged in as': 'Has ingresado como',
        'Free shipping <br/> & returns': 'Envío gratuito <br/> y devoluciones',
        '30-day money <br/> back guarantee':
            '30 días de dinero <br/> back guarantee',
        '1 year warranty <br/> on all phones':
            '1 año de garantía <br/> en todos los teléfonos',
        'No contract & <br/> no credit checks':
            'Ningún contrato y <br/> ninguna verificación de crédito',
        'Unlimited calling <br/> and texting':
            'Llamadas ilimitadas <br/> y mensajes de texto',
        'Starting at': 'A partir de',
        'Unlimited Calling and Texting':
            'Llamadas ilimitadas, mensajes de texto y datos',
        'Unlimited calling': 'Llamadas ilimitadas',
        'and texting': 'y mensajes de texto',
        'Plans starting at $9.99 per month':
            'Planes a partir de $ 9.99 por mes',
        'Bring your <br/> own phone': 'Traiga su <br/> propio teléfono',
        'Bring your': 'Traiga su',
        'own phone': 'propio teléfono',
        'TextNow SIM Card': 'Tarjeta SIM de TextNow',
        DETAILS: 'DETALLES',
        'Sold out': 'Actualmente agotado',
        'TextNow <br/> E-Gift Card':
            'TextNow <br/> Tarjeta de regalo electrónico',
        'and up': 'y arriba',
        'Featured On': 'Presentado en',
        "'Love textnow! My data plan is just <br/> what I wanted in phone service.'":
            "'¡Ame el texto ahora! Mi plan de datos es solo <br/> lo que quería en el servicio telefónico.'",
        "'I love being a customer with text <br/> now wireless. The service is great.'":
            "'Me encanta ser un cliente con texto <br/> ahora inalámbrico. El servicio es genial.'",
        'Talk & Text Only': 'Solo hablar y texto',
        'Plans starting at': 'Planes comenzando en',
        'Full price': 'Precio final',
        '{days} days': '{days} días',
        '{days} day': '{days} día',
        '{hours} hour': '{hours} hora',
        '{hours} hours': '{hours} horas',
        '{minutes} minute': '{minutes} minuto',
        '{minutes} minutes': '{minutes} minutos',
        '{seconds} second': '{seconds} segundo',
        '{seconds} seconds': '{seconds} segundos',
        'Available now.': 'Disponible ahora.',
        'Where do I get coverage?': '¿Dónde obtengo cobertura?',
        'With TextNow, you have coverage through WiFi and nation-wide data networks. To see if TextNow is a good fit, you can':
            'Con TextNow, tiene cobertura a través de WiFi y redes de datos nacionales. Para ver si TextNow es una buena opción, puede',
        'view our coverage here': 'mira nuestra cobertura aquí',
        'for our nation-wide CDMA and GSM coverage map. Our service is better than traditional carrier coverage, as it includes any location you have WiFi where traditional phone service may be unavailable.':
            'para nuestro mapa de cobertura CDMA y GSM nacional. Nuestro servicio es mejor que la cobertura de proveedor tradicional, ya que incluye cualquier ubicación donde tenga WiFi donde el servicio telefónico tradicional puede no estar disponible.',
        'Which network does TextNow mobile phone service use?':
            '¿Qué red utiliza el servicio de teléfono móvil TextNow?',
        "TextNow uses your WiFi connection first, when it is available (even for text & calls). When there is no WiFi connection, TextNow will use Sprint's nationwide 2G voice, 3G, and 4G network for our CDMA phones and SIM cards.":
            'TextNow utiliza su conexión WiFi primero, cuando está disponible (incluso para mensajes de texto y llamadas). Cuando no hay conexión WiFi, TextNow utilizará la red 2G de voz, 3G y 4G nacional de Sprint para nuestros teléfonos CDMA y tarjetas SIM.',
        'Is there a contract associated with the plan?':
            '¿Hay un contrato asociado con el plan?',
        'All plans have no contract and you may cancel your plan whenever you like, without any penalty or additional charges.':
            'Todos los planes no tienen contrato y usted puede cancelar su plan cuando lo desee, sin ninguna multa ni cargos adicionales.',
        'Do I need to get the monthly plan? Can I just buy the device?':
            '¿Necesito obtener el plan mensual? ¿Puedo comprar el dispositivo?',
        'All devices require the monthly plan for it to work. We do not sell phones without a monthly plan attached.':
            'Todos los dispositivos requieren el plan mensual para que funcione. No vendemos teléfonos sin un plan mensual adjunto.',
        "Does my data get used when I'm on WiFi?":
            '¿Mis datos se utilizan cuando estoy en WiFi?',
        'When you are on WiFi, data does not get used at all.':
            'Cuando estás en WiFi, los datos no se utilizan en absoluto.',
        'Will I be charged overage fees when I use up my 3G/4G data?':
            '¿Se me cobrarán tarifas excedentes cuando utilizo mis datos 3G / 4G?',
        'You will never be charged overage fees. You will be prompted with an option to upgrade to the next plan before you hit your limit. Should you choose not to upgrade, your data service will be available at 2G speed until the next billing cycle. You still can use your device at full speed on WiFi.':
            'Nunca se le cobrarán tarifas excedentes. Se le pedirá una opción para actualizar al siguiente plan antes de llegar a su límite. Si elige no actualizar, su servicio de datos estará disponible a una velocidad de 2G hasta el siguiente ciclo de facturación. Aún puedes usar tu dispositivo a toda velocidad con WiFi.',
        'What is your return policy?': '¿Cuál es su política de devolución?',
        'You may return your device for a full refund, including service fees and any applicable shipping fees, within thirty days from the date of purchase. The device must be returned with its original packaging and accessories and be in new condition in order to qualify for a full refund.':
            'Puede devolver su dispositivo para obtener un reembolso completo, incluidas las tarifas de servicio y las tarifas de envío aplicables, dentro de los treinta días a partir de la fecha de compra. El dispositivo debe ser devuelto con su embalaje y accesorios originales y debe estar en perfectas condiciones para poder obtener un reembolso completo.',
        'Is there a warranty?': '¿Hay una garantia?',
        'Refurbished and new devices come with a 1 year warranty. Used devices come with a 60 day warranty.  Batteries come with a 90 day warranty. Should something go wrong with your device, we will send you a replacement free of charge. Physical and water damage is not covered by the warranty. You will need to have an active subscription in order to activate the warranty process.':
            'Los dispositivos reacondicionados y nuevos vienen con una garantía de 1 año. Los dispositivos usados ​​vienen con una garantía de 60 días. Las baterías vienen con una garantía de 90 días. Si algo falla con su dispositivo, le enviaremos un reemplazo sin cargo. Los daños físicos y por agua no están cubiertos por la garantía. Deberá tener una suscripción activa para activar el proceso de garantía.',
        'Does TextNow work internationally?':
            '¿TextNow funciona internacionalmente?',
        'TextNow will work internationally when connected to a WiFi network. However, 3G and 4G data will not be available when you travel internationally.':
            'TextNow funcionará internacionalmente cuando se conecte a una red WiFi. Sin embargo, los datos 3G y 4G no estarán disponibles cuando viajes al exterior.',
        'How do I pay my bill once I sign up?':
            '¿Cómo pago mi factura una vez que me inscribo?',
        'When you sign up, auto-billing is setup with the credit/debit card you used. This means your credit/debit card will be billed every month automatically until you cancel your plan. Should you need to change your billing information, you can add new cards on your My Account page right here on':
            'Cuando se registra, la facturación automática se configura con la tarjeta de crédito / débito que utilizó. Esto significa que su tarjeta de crédito / débito se facturará automáticamente todos los meses hasta que cancele su plan. Si necesita cambiar su información de facturación, puede agregar nuevas tarjetas en su página de Mi cuenta aquí mismo.',
        'www.textnow.com': 'www.textnow.com',
        ', or through the app menu under Account Balance. If you would like to cancel service, see our Support article on':
            ', o a través del menú de la aplicación en Balance de cuenta. Si desea cancelar el servicio, consulte nuestro artículo de Soporte en',
        'cancelling service': 'cancelando el servicio',
        '. And finally, we also have': '. ¡Y finalmente, también tenemos',
        'gift cards available': 'tarjetas de regalo disponibles',
        'you can use towards your monthly bill!':
            'puede usarlo para su factura mensual!',
        'Can I port in my own phone number?':
            '¿Puedo portar en mi propio número de teléfono?',
        'We are able to port in most phone numbers into TextNow after you purchase a plan from us. You will be assigned a temporary number when you sign up until your phone number is ported in.':
            'Podemos transferir la mayoría de los números de teléfono a TextNow después de que nos compre un plan. Se le asignará un número temporal cuando se registre hasta que se transfiera su número de teléfono.',
        'How does your technology save me money?':
            '¿Cómo me ahorra su tecnología su dinero?',
        'Our technology automatically uses WiFi for calling and texting wherever possible, allowing us to pass the cost savings to you to help you save money. Our service takes advantage of voice-over-internet and text-over-internet technology without sacrificing quality. When your internet is not good, our TextNow phones can fall back to the cellular network for calls.':
            'Nuestra tecnología utiliza automáticamente WiFi para llamadas y mensajes de texto siempre que sea posible, lo que nos permite transferirle los ahorros de costos para ayudarlo a ahorrar dinero. Nuestro servicio aprovecha la tecnología de voz por Internet y texto por Internet sin sacrificar la calidad. Cuando su conexión a Internet no es buena, nuestros teléfonos TextNow pueden recurrir a la red móvil para realizar llamadas.',
        'Can I use my existing phone with TextNow Wireless?':
            '¿Puedo usar mi teléfono actual con TextNow Wireless?',
        "We have many options available to use your existing phone with TextNow! If you have a smartphone from Sprint that is off-contract, there's a good chance we can":
            '¡Tenemos muchas opciones disponibles para usar su teléfono actual con TextNow! Si tiene un teléfono inteligente de Sprint sin contrato, hay muchas posibilidades de que podamos',
        'activate it directly here': 'activarlo directamente aquí',
        '. If you have a CDMA phone, we now have SIM cards available for purchase -':
            '. Si tiene un teléfono CDMA, ahora tenemos tarjetas SIM disponibles para la compra.',
        "click here to find the card that's right for you!":
            '¡Haga clic aquí para encontrar la tarjeta adecuada para usted!',
        'Can I use my device without a subscription?':
            '¿Puedo usar mi dispositivo sin una suscripción?',
        'Our phones are designed to be used with our TextNow service and require a monthly subscription. We heavily subsidize the cost of our phones to make them as affordable as possible for our customers, and we hope to recoup the cost of the phones via the monthly subscription.':
            'Nuestros teléfonos están diseñados para ser utilizados con nuestro servicio TextNow y requieren una suscripción mensual. Subvencionamos fuertemente el costo de nuestros teléfonos para que sean lo más asequibles posible para nuestros clientes, y esperamos recuperar el costo de los teléfonos a través de la suscripción mensual.',
        'Are TextNow Wireless phones unlocked or un-lockable?':
            '¿Los teléfonos inalámbricos de TextNow están desbloqueados o desbloqueables?',
        'TextNow Wireless phones are not compatible with other phone companies. Taking your TextNow Wireless phone for use with another phone company is not supported.':
            'Los teléfonos inalámbricos de TextNow no son compatibles con otras compañías telefónicas. No es compatible con su teléfono inalámbrico TextNow para usar con otra compañía telefónica.',
        'Can I call internationally?': '¿Puedo llamar internacionalmente?',
        'Yes, you can call internationally with TextNow. International calling rates can be found':
            'Sí, puedes llamar internacionalmente con TextNow. Las tarifas de llamadas internacionales se pueden encontrar',
        'here.': 'aquí.',
        'You can add international calling balance by going to Account Balance inside the TextNow app or while logged in on the web.':
            'Puede agregar saldo de llamadas internacionales yendo a Balance de cuenta dentro de la aplicación TextNow o mientras está conectado en la web.',
        'Staff Pick': 'Selección de personal',
        'SEE DETAILS': 'VER DETALLES',
        'Sale ends in': 'La venta termina en',
        '©': '©',
        'Motorola Moto E4': 'Motorola Moto E4',
        'Bring your own phone': 'Traiga su propio teléfono',
        'Get a free TextNow SIM Card':
            'Obtenga una tarjeta SIM gratuita de TextNow',
        'Get started': 'Empezar',
        'Check if your phone is compatible':
            'Compruebe si su teléfono es compatible',
        'Dial *#06#': 'Marque *#06#',
        'in your phone app to get your IMEI/MEID/ESN.':
            'en la aplicación de tu teléfono para obtener tu IMEI / MEID / ESN.',
        'Where can I find my IMEI / MEID / ESN?':
            '¿Dónde puedo encontrar mi IMEI / MEID / ESN?',
        'Enter your IMEI/MEID/ESN': 'Ingrese su IMEI / MEID / ESN',
        'How to find your IMEI / MEID / ESN':
            'Cómo encontrar tu IMEI / MEID / ESN',
        'Get it by Dialing *#06#': 'Consíguelo marcando *#06#',
        "Dial *#06# in your native dialer of the phone you'd like to bring to TextNow.":
            'Marque *#06# en su marcador nativo del teléfono que desea traer a TextNow.',
        'Check in your settings': 'Compruebe en su configuración',
        'Android: Go to settings > About phone > Status > IMEI information':
            'Android: ve a configuración > Acerca del teléfono > Estado > Información IMEI',
        'iPhone: Go to settings > General > About > IMEI':
            'iPhone: ve a configuración > General > Acerca de > IMEI',
        'Is your phone locked?': '¿Su teléfono está bloqueado?',
        "Carriers lock phones to prevent people from using it on another carrier. If you didn't purchase your phone through a carrier your phone is likely unlocked.":
            'Los operadores bloquean los teléfonos para evitar que las personas lo usen en otro proveedor. Si no compró su teléfono a través de un proveedor, es probable que su teléfono esté desbloqueado.',
        'Unfortunately, we cannot activate locked phones':
            'Desafortunadamente, no podemos activar los teléfonos bloqueados',
        'If your phone is paid in full, you can request to have it unlocked by contacting your carrier.':
            'Si su teléfono está totalmente pagado, puede solicitar que se lo desbloquee comunicándose con su proveedor.',
        'Sorry, your phone seems to have an active contract with another carrier':
            'Lo sentimos, su teléfono parece tener un contrato activo con otro proveedor',
        'Please contact your current carrier to resolve this issue.':
            'Por favor, póngase en contacto con su operador actual para resolver este problema.',
        "Sorry, your phone isn't currently supported by TextNow":
            'Lo sentimos, tu teléfono actualmente no es compatible con TextNow.',
        'Still interested in joining TextNow? Enjoy low-priced phones and plans starting from $9.99 /month.':
            '¿Sigues interesado en unirte a TextNow? Disfrute de teléfonos y planes a bajo precio desde $ 9.99 / mes.',
        'Bring a different phone': 'Trae un teléfono diferente',
        'Congratulations, your phone is compatible!':
            '¡Felicidades, tu teléfono es compatible!',
        'Buy Now': 'Compra ahora',
        'To activate your phone, complete your purchase with one of our free SIM cards!':
            '¡Para activar su teléfono, complete su compra con una de nuestras tarjetas SIM gratuitas!',
        'Your phone can be activated on our network today!':
            '¡Tu teléfono puede ser activado en nuestra red hoy!',
        'Please select your plan.': 'Por favor seleccione su plan.',
    },
};

/* eslint-enable max-len */
/* eslint-enable */
