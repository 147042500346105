import * as React from 'react';
import { Column, Badge, Flex } from '@tn/retune';

import './index.scss';

class TileContent extends React.PureComponent<Props> {
    render() {
        let className = `tile__content`;

        if (this.props.disabled) {
            className += ' tile__content--disabled';
        }

        return (
            <Column xs={8} lg={6}>
                <div className={className}>
                    <div className="tile__content__header">
                        {this.props.badge && (
                            <Flex marginBottom={2}>
                                <Badge appearance="danger">
                                    {this.props.badge}
                                </Badge>
                            </Flex>
                        )}
                        {this.props.title}
                    </div>

                    <div className="tile__content__detail">
                        {this.props.subTitle && (
                            <div className="tile__content__detail__header">
                                {this.props.subTitle}
                            </div>
                        )}
                        {this.props.detail}
                    </div>

                    <div className="tile__content__footer">
                        {this.props.footer}
                    </div>
                </div>
            </Column>
        );
    }
}

export interface Props {
    title: React.ReactNode | React.ReactNode[];
    badge?: string;
    subTitle?: React.ReactNode;
    detail: React.ReactNode;
    footer: React.ReactNode;
    disabled?: boolean;
    productSetId: number;
}

export default TileContent;
