export default class Amount {
    dollars: number = 0;
    cents: number = 0;
    currencySymbol: string;

    constructor(amount: number = 0) {
        this.setAmount(amount);

        this.currencySymbol = '$';
    }

    static fromCents = (cents: number) => {
        return new Amount(cents);
    };

    static fromDollars = (dollars: number) => {
        return Amount.fromCents(dollars * 100);
    };

    setAmount = (amount: number) => {
        const tempAmount = amount / 100;
        const splitAmount = tempAmount.toFixed(2).split('.');
        this.dollars = parseInt(splitAmount[0], 10);
        this.cents = parseInt(splitAmount[1], 10);
    };

    toString = () => {
        if (this.dollars === 0 && this.cents === 0) {
            return 'Free';
        } else {
            const dollars = this.dollars + this.cents / 100;
            return `${this.currencySymbol}${dollars.toFixed(2)}`;
        }
    };
}
