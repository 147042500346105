import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import * as _window from '@tn/window';
import { Flex, Label, Button, Link, Container } from '@tn/retune';

import { inferQueryString } from '@tn/modules/dist/product-set/helpers';
import * as deepLinkSelectors from '@tn/modules/dist/deep-link/selectors';
import * as productsSelectors from '@tn/modules/dist/product-set/selectors';
import RelatedProductsTile from 'containers/DetailPage/components/RelatedProductsTile';

import './index.scss';

export class RelatedProducts extends React.PureComponent<Props> {
    static defaultProps: Partial<Props> = {
        title: 'Other powerful smartphones',
        isMobile: false,
    };

    createRelatedTiles = (maxIndex: number) => {
        return this.props.relatedProductSets.map(
            (relatedProductSet: number, index: number) => {
                if (index >= maxIndex) {
                    return null;
                } else {
                    return (
                        <RelatedProductsTile
                            key={index}
                            productSetId={relatedProductSet.toString()}
                        />
                    );
                }
            }
        );
    };

    getWirelessPageUrl() {
        const queryString = inferQueryString();

        const qs =
            Object.keys(queryString).length > 0
                ? _window.createQueryStringFromHash(queryString)
                : '';

        if (this.props.isMobile) {
            return `/wireless/devices${qs}`;
        } else {
            return `/wireless${qs}`;
        }
    }

    render() {
        if (
            !this.props.relatedProductSets ||
            this.props.relatedProductSets.length === 0
        ) {
            return null;
        }

        return (
            <div className="related-products">
                <Container automationId="otherProducts">
                    <Flex
                        className="related-products-title"
                        justify="center"
                        xs
                        paddingTop={5}
                        paddingBottom={4}
                    >
                        <Label size="heading2" centered>
                            {this.props.title}
                        </Label>
                    </Flex>
                    <Flex
                        className="related-products-images"
                        justify="between"
                        xs={false}
                        md
                    >
                        {this.createRelatedTiles(4)}
                    </Flex>
                    <Flex
                        className="related-products-images"
                        justify="around"
                        xs
                        md={false}
                    >
                        {this.createRelatedTiles(2)}
                    </Flex>
                    <Flex
                        className="related-products-button"
                        justify="center"
                        xs
                        paddingTop={3}
                        paddingBottom={4}
                    >
                        <Link
                            href={this.getWirelessPageUrl()}
                            appearance="none"
                        >
                            <Button label="EXPLORE ALL PHONES" />
                        </Link>
                    </Flex>
                </Container>
            </div>
        );
    }
}

interface OwnProps {
    currentProduct: string;
    title?: string;
}

interface Props extends OwnProps {
    relatedProductSets: number[];
    isMobile: boolean;
}

const mapStateToProps = () => {
    const relatedProductSets = productsSelectors.relatedProductSetIds;
    return (state, props: OwnProps) => {
        return {
            isMobile: deepLinkSelectors.isMobileApp(state),
            relatedProductSets: relatedProductSets(state, props.currentProduct),
        };
    };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(RelatedProducts);
