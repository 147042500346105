import * as React from 'react';
import { Size } from '@tn/retune/build/lib/components/types';
import { Flex, Link } from '@tn/retune';

import './index.scss';

class Tile extends React.PureComponent<Props> {
    render() {
        const className = `tile`;

        const label = (
            <Flex direction="row" justify="center">
                {this.props.children}
            </Flex>
        );

        return (
            <div className={className} onClick={this.props.onClick}>
                {this.props.disabled && !this.props.enableDisabledLink && label}
                {(!this.props.disabled || this.props.enableDisabledLink) && (
                    <Link
                        automationId={this.props.automationId}
                        appearance="none"
                        to={this.props.to}
                        href={this.props.href || ''}
                        label={label}
                    />
                )}
            </div>
        );
    }
}

export interface Props extends Size {
    children?: React.ReactNode;

    automationId?: string;

    /**
     * tile link
     */
    href?: string;

    /** uses react-router to redirect the user */
    to?: string;

    /**
     * disabled
     */
    disabled?: boolean;

    /**
     * enable link even when it's on disabled state
     */
    enableDisabledLink?: boolean;

    /**
     * Allowing an onClick to be triggered on a Tile
     */
    onClick?: () => void;
}

export default Tile;
