import * as React from 'react';
import Analytics from '@tn/analytics';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { JSONLD, Product as ProductLD } from 'react-structured-data';

import { Column } from '@tn/retune';
import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';

import * as productSetSelectors from '@tn/modules/dist/product-set/selectors';
import SimCard from 'containers/WirelessPage/components/ProductSetTile/DeviceTypes/SimCard';
import PhoneCard from 'containers/WirelessPage/components/ProductSetTile/DeviceTypes/PhoneCard';
import Tile from 'components/Tile';
import TileImage from 'components/TileImage';
import TileImageOverlay from 'components/TileImageOverlay';

export class ProductSetTile extends React.PureComponent<Props> {
    tileClicked = () => {
        if (!this.props.productSet) {
            return;
        }
        Analytics.track('store', 'viewed_device', this.props.productSet.name);
    };

    renderTileContent() {
        // Do not show anything if the product set is disabled
        if (
            !this.props.productSet ||
            !this.props.productSet.web_store_enabled
        ) {
            return null;
        }

        const isSoldOut = this.props.productSet.sold_out;

        return (
            <>
                <JSONLD>
                    <ProductLD
                        name={this.props.productSet.name}
                        brand={this.props.productSet.make}
                        model={this.props.productSet.model}
                        url={this.props.productSet.url}
                        image={this.props.productSet.img_url}
                    />
                </JSONLD>
                <TileImage
                    src={this.props.productSet.img_url}
                    alt={this.props.productSet.name}
                    overlay={
                        isSoldOut && (
                            <TileImageOverlay sm={false} label="Sold out" />
                        )
                    }
                />
                {this.props.productSet.is_sim_card && (
                    <SimCard productSet={this.props.productSet} />
                )}
                {!this.props.productSet.is_gift_card &&
                    !this.props.productSet.is_sim_card && (
                        <PhoneCard productSet={this.props.productSet} />
                    )}
            </>
        );
    }

    render() {
        // Do not show anything if the product set is disabled
        if (
            !this.props.productSet ||
            !this.props.productSet.web_store_enabled
        ) {
            return null;
        }

        const link = this.props.productSet.url;

        return (
            <Column xs={12} md={6}>
                <Tile
                    automationId={`product-set-tile-${this.props.productSet.id}`}
                    to={link}
                    onClick={this.tileClicked}
                    disabled={this.props.productSet.sold_out}
                    enableDisabledLink
                >
                    {this.renderTileContent()}
                </Tile>
            </Column>
        );
    }
}

interface OwnProps {
    productSetId: string;
}

interface Props extends OwnProps {
    productSet: ProductSetRecord;
}

const makeMapStateToProps = () => {
    const getProductSet = productSetSelectors.productSet;
    const mapStateToProps = (state, props: OwnProps) => {
        return {
            productSet: getProductSet(state, props.productSetId),
        };
    };
    return mapStateToProps;
};

const withConnect = connect(makeMapStateToProps);

export default compose(withConnect)(ProductSetTile);
