import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { Action, compose } from 'redux';
import { connect } from 'react-redux';

import { Modal, H2, Flex, List, ListItem, Label, Divider } from '@tn/retune';

import { isModalOpened } from '@tn/modules/dist/modals/selectors';
import { MODALS } from '@tn/modules/dist/modals/types';
import { toggleModal } from '@tn/modules/dist/modals/actions';

import './index.scss';

export class PhoneCompatibilityModal extends React.PureComponent<Props> {
    toggle = () => this.props.toggleModal();

    renderBody = () => {
        return (
            <List>
                <ListItem appearance="none" bold>
                    <Flex direction="column" fluid xs>
                        <Flex marginBottom={3}>
                            <Label bold>Get it by Dialing *#06#</Label>
                        </Flex>
                        <Label>
                            Dial *#06# in your native dialer of the phone you'd
                            like to bring to TextNow.
                        </Label>
                    </Flex>
                </ListItem>
                <Divider />
                <ListItem appearance="none" bold>
                    <Flex direction="column" fluid xs>
                        <Flex marginBottom={3}>
                            <Label bold>Check in your settings</Label>
                        </Flex>
                        <Flex marginBottom={2}>
                            <Label>
                                Android: Go to settings &gt; About phone &gt;
                                Status &gt; IMEI information
                            </Label>
                        </Flex>
                        <Label>
                            iPhone: Go to settings &gt; General &gt; About &gt;
                            IMEI
                        </Label>
                    </Flex>
                </ListItem>
            </List>
        );
    };

    render() {
        const body = this.renderBody();

        return (
            <Modal
                className="phone-compatibility-modal"
                isOpen={this.props.isModalOpened}
                toggle={this.toggle}
            >
                <Flex
                    paddingLeft={5}
                    paddingRight={5}
                    direction="column"
                    justify="center"
                    sm
                    xs={false}
                >
                    <H2>How to find your IMEI / MEID / ESN</H2>
                    {body}
                </Flex>
                <Flex
                    paddingLeft={1}
                    paddingRight={1}
                    direction="column"
                    sm={false}
                    xs
                >
                    <H2>How to find your IMEI / MEID / ESN</H2>
                    {body}
                </Flex>
            </Modal>
        );
    }
}

export interface State {
    width: number;
}

interface Props {
    isModalOpened: boolean;
    toggleModal: () => void;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        toggleModal: () => dispatch(toggleModal(MODALS.PHONE_COMPATIBILITY)),
    };
}

const mapStateToProps = createStructuredSelector({
    isModalOpened: isModalOpened(MODALS.PHONE_COMPATIBILITY),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PhoneCompatibilityModal);
