import * as React from 'react';
import CountdownComponent from 'react-countdown-now';
import isEmpty from 'lodash-es/isEmpty';
import initial from 'lodash-es/initial';
import { Label, SizeRange } from '@tn/retune';

export default class Countdown extends React.PureComponent<Props> {
    constructor(props: Props) {
        super(props);

        this.counterRenderer = this.counterRenderer.bind(this);
        this.counterTime = this.counterTime.bind(this);
        this.renderPrefix = this.renderPrefix.bind(this);
    }

    counterTime(days: number, hours: number, minutes: number, seconds: number) {
        const returnLabels: React.ReactNodeArray = [];

        if (this.props.daysOnly || days > 0) {
            if (days > 0) {
                returnLabels.push(
                    <Label
                        key="countdown-days"
                        values={{ days: days }}
                        size={this.props.size}
                    >
                        {`{days} day${days !== 1 ? 's' : ''}`}
                    </Label>
                );
                returnLabels.push(
                    <React.Fragment key="countdown-days-space">
                        &nbsp;
                    </React.Fragment>
                );
            } else {
                returnLabels.push(
                    <Label key="countdown-today" size={this.props.size}>
                        today
                    </Label>
                );
                returnLabels.push(
                    <React.Fragment key="countdown-today-space">
                        &nbsp;
                    </React.Fragment>
                );
            }
        }

        if (!this.props.daysOnly) {
            if (hours > 0) {
                returnLabels.push(
                    <Label
                        key="countdown-hours"
                        values={{ hours: hours }}
                        size={this.props.size}
                    >
                        {`{hours} hour${hours !== 1 ? 's' : ''}`}
                    </Label>
                );
                returnLabels.push(
                    <React.Fragment key="countdown-hours-space">
                        &nbsp;
                    </React.Fragment>
                );
            }

            if (minutes > 0 || hours > 0) {
                returnLabels.push(
                    <Label
                        key="countdown-minutes"
                        values={{ minutes: minutes }}
                        size={this.props.size}
                    >
                        {`{minutes} minute${minutes !== 1 ? 's' : ''}`}
                    </Label>
                );
                returnLabels.push(
                    <React.Fragment key="countdown-minutes-space">
                        &nbsp;
                    </React.Fragment>
                );
            }

            if (seconds > 0 || minutes > 0 || hours > 0) {
                returnLabels.push(
                    <Label
                        key="countdown-seconds"
                        values={{ seconds: seconds }}
                        size={this.props.size}
                    >
                        {`{seconds} second${seconds !== 1 ? 's' : ''}`}
                    </Label>
                );
                returnLabels.push(
                    <React.Fragment key="countdown-seconds-space">
                        &nbsp;
                    </React.Fragment>
                );
            }
        }

        return initial(returnLabels);
    }

    counterPrefix(days: number) {
        if (this.props.daysOnly && days <= 0) {
            return this.renderPrefix(
                this.props.todayPrefix || this.props.prefix
            );
        } else {
            return this.renderPrefix(this.props.prefix);
        }
    }

    renderPrefix(prefix?: string) {
        return (
            <>
                {!isEmpty(prefix) ? (
                    <>
                        <Label
                            size={this.props.size}
                            className="countdown__prefix"
                        >
                            {prefix}
                        </Label>
                        &nbsp;
                    </>
                ) : null}
            </>
        );
    }

    counterRenderer({
        days,
        hours,
        minutes,
        seconds,
        completed,
    }: CountdownProps) {
        if (completed) {
            return null;
        } else {
            return (
                <>
                    {this.counterPrefix(days)}
                    {this.counterTime(days, hours, minutes, seconds)}
                </>
            );
        }
    }

    render() {
        return (
            <CountdownComponent
                date={this.props.date}
                renderer={this.counterRenderer}
                onComplete={this.props.onComplete}
            />
        );
    }
}

interface CountdownProps {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
}

interface Props {
    date: string | Date | number;
    prefix?: string;
    className?: string;
    onComplete?: () => void;
    daysOnly?: boolean;
    todayPrefix?: string;
    size?: SizeRange;
}
