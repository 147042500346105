import { Action, compose } from 'redux';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import { TranslatorProvider, ThemeProvider } from '@tn/retune';
import * as _window from '@tn/window';
import Analytics from '@tn/analytics';
import { initModules } from '@tn/modules/dist/actions';

import './index.scss';
import { injectSaga } from '@tn/redux';
import saga from 'containers/App/saga';
import Footer from 'containers/App/components/Footer';
import Navbar from 'containers/App/components/Navbar';
import * as plansActions from '@tn/modules/dist/plans/actions';
import DetailPage from 'containers/DetailPage';
import WirelessPage from 'containers/WirelessPage';
import DevicesPage from 'containers/DevicesPage';
import DeviceCheckerPage from 'containers/DeviceCheckerPage';
import * as internationalizationSelectors from '@tn/modules/dist/internationalization/selectors';
import localization from 'containers/App/language/localization';
import ConnectedErrorHandlingModal from 'containers/App/components/ConnectedErrorHandlingModal';

class App extends React.PureComponent<Props> {
    componentDidMount() {
        const location = _window.getFilteredLocation();
        Analytics.trackPageView(location);

        this.props.initModules();
        this.props.loadPlans();
    }

    renderMetatags() {
        if (_window.getLocation().hostname.indexOf('www.textnow.com') === -1) {
            return (
                <Helmet key="helmet">
                    <meta name="robots" content="noindex,nofollow" />
                </Helmet>
            );
        }
        return null;
    }

    render() {
        const currentLanguage = this.props.language;

        return (
            <div>
                {this.renderMetatags()}
                <TranslatorProvider
                    translations={localization}
                    locale={currentLanguage}
                    defaultLanguage="en"
                >
                    <div className="main webflowWrapperV1">
                        <ThemeProvider branded>
                            <>
                                <Navbar />
                                <ConnectedErrorHandlingModal />
                                <Switch key="router">
                                    <Route
                                        exact
                                        path="/wireless"
                                        component={WirelessPage}
                                    />
                                    <Route
                                        exact
                                        path="/wireless/devices"
                                        component={DevicesPage}
                                    />
                                    <Route
                                        exact
                                        path="/wireless/sim-card"
                                        component={DetailPage}
                                    />
                                    <Route
                                        exact
                                        path="/wireless/device-checker"
                                        component={DeviceCheckerPage}
                                    />
                                    <Route
                                        exact
                                        path="/wireless/device/:productSetId/:name?"
                                        component={DetailPage}
                                    />
                                    <Route
                                        exact
                                        path="/wireless/phones/:productSetId/:name?"
                                        component={DetailPage}
                                    />
                                </Switch>
                                <Footer />
                            </>
                        </ThemeProvider>
                    </div>
                </TranslatorProvider>
            </div>
        );
    }
}

interface Props {
    loadSalesConfig: () => void;
    loadPlans: () => void;
    initModules: () => void;
    language: string;
    startExperiment: (name: string) => undefined;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        loadPlans: () => dispatch(plansActions.loadPlans()),
        initModules: () => dispatch(initModules()),
    };
}

const mapStateToProps = createStructuredSelector({
    language: internationalizationSelectors.getLanguage,
});

const withSaga = injectSaga({ key: 'global', saga });
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withRouter, withSaga, withConnect)(App);
