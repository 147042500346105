import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { Image, Flex, Label, Link } from '@tn/retune';
import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';

import * as productSetSelectors from '@tn/modules/dist/product-set/selectors';
import PriceLabel from 'components/PriceLabel';

import './index.scss';

export class RelatedProductsTile extends React.PureComponent<Props> {
    render() {
        if (!this.props.productSet) {
            return false;
        }
        return (
            <Link
                to={this.props.productSet.url}
                appearance="none"
                className="related-products-tile"
            >
                <Flex
                    direction="column"
                    alignItems="center"
                    xs
                    paddingTop={4}
                    paddingBottom={4}
                >
                    <Flex marginBottom={2}>
                        <Image
                            src={this.props.productSet.img_url}
                            alt="temp"
                            width="99px"
                        />
                    </Flex>
                    <Label appearance="muted" centered>
                        {this.props.productSet.name}
                    </Label>
                    <Flex marginTop={3} xs>
                        <Flex paddingRight={2}>
                            <PriceLabel
                                amount={this.props.productSet.max_sale_price}
                                className="sale-price"
                                hideCents={false}
                                size="small"
                            />
                        </Flex>
                        <PriceLabel
                            crossOut
                            amount={this.props.productSet.max_price}
                            hideCents={false}
                            size="small"
                        />
                    </Flex>
                </Flex>
            </Link>
        );
    }
}

interface OwnProps {
    productSetId: string;
}

interface Props extends OwnProps {
    productSet: ProductSetRecord;
}

const mapStateToProps = () => {
    const getProductSet = productSetSelectors.productSet;
    return (state, props: OwnProps) => {
        return {
            productSet: getProductSet(state, props.productSetId),
        };
    };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(RelatedProductsTile);
