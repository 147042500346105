import * as React from 'react';

import { Flex, Label } from '@tn/retune';

export default class PhoneUpgrade extends React.PureComponent {
    render() {
        return (
            <Flex xs direction="column" className="product--device-upgrade">
                <Label size="small" bold>
                    Phone Upgrade
                </Label>
                <Flex marginTop={2}>
                    <Label size="small" appearance="muted">
                        Order this phone to be used with your existing TextNow
                        account.
                    </Label>
                </Flex>
            </Flex>
        );
    }
}
