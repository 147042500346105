import * as React from 'react';
import { Divider, Flex, H2, Label } from '@tn/retune';
import SaleBadge from 'containers/DetailPage/components/ProductSetContent/SaleBadge';
import PriceLabel from 'components/PriceLabel';
import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';
import { Product } from '@tn/types/dist/domain/Product';

export default class ProductSetHeader extends React.PureComponent<Props> {
    renderTitle(currentProduct: Product, isSIM?: boolean) {
        return (
            <>
                {!isSIM && this.props.productSet.products.length === 1 && (
                    <Label appearance="muted" size="regular">
                        {currentProduct.webstore_quality}
                    </Label>
                )}
                {isSIM && (
                    <Label size="regular">
                        Free Nationwide Calling & Texting
                    </Label>
                )}
            </>
        );
    }

    renderNonSimPriceShipping(hasShippingDelay: boolean) {
        return (
            <>
                <Flex xs direction="row" marginBottom={3}>
                    {this.props.isOnSale && (
                        <SaleBadge currentProduct={this.props.currentProduct} />
                    )}

                    <Flex>
                        {hasShippingDelay && (
                            <Label size="small">Free shipping</Label>
                        )}
                        {!hasShippingDelay && (
                            <Label size="small">Free 2-day shipping</Label>
                        )}
                    </Flex>
                </Flex>

                <Flex marginBottom={3}>
                    {this.props.isOnSale && (
                        <Flex direction="row">
                            <PriceLabel
                                amount={this.props.currentProduct.sale_price}
                                appearance="danger"
                                size="subheading"
                            />

                            <Flex marginLeft={2}>
                                <PriceLabel
                                    amount={this.props.currentProduct.price}
                                    crossOut
                                    appearance="muted"
                                    size="subheading"
                                />
                            </Flex>
                        </Flex>
                    )}

                    {!this.props.isOnSale && (
                        <PriceLabel
                            amount={this.props.currentProduct.price}
                            appearance={
                                this.props.currentProduct.price === 0
                                    ? 'danger'
                                    : undefined
                            }
                        />
                    )}
                </Flex>
            </>
        );
    }

    renderSimPriceShipping() {
        return (
            <Flex xs direction="row" marginBottom={3}>
                <Flex marginRight={3}>
                    <PriceLabel
                        amount={this.props.currentProduct.sale_price}
                        appearance="danger"
                        size="subheading"
                    />
                </Flex>

                <Label size="subheading">Free Shipping</Label>
            </Flex>
        );
    }

    render() {
        const isSIM = this.props.isSIM;
        const currentProduct = this.props.currentProduct;
        const hasShippingDelay = isSIM
            ? this.props.hasSubscription
            : currentProduct.shipping_delay > 0;
        let priceShipping;
        if (!isSIM && hasShippingDelay !== undefined) {
            priceShipping = this.renderNonSimPriceShipping(hasShippingDelay);
        } else {
            priceShipping = this.renderSimPriceShipping();
        }

        return (
            <>
                {!isSIM && (
                    <H2 marginBottom={2}>{this.props.productSet.name}</H2>
                )}
                {isSIM && <H2 marginBottom={2}>SIM Activation Kit</H2>}

                <Flex xs md={false} marginBottom={2}>
                    {this.renderTitle(currentProduct, isSIM)}
                </Flex>

                <Flex marginBottom={4} xs={false} md>
                    {this.renderTitle(currentProduct, isSIM)}
                </Flex>

                {!isSIM && this.props.productSet.products.length === 1 && (
                    <Divider className="product-set__divider" />
                )}

                {priceShipping}
            </>
        );
    }
}

export interface Props {
    isSIM?: boolean;
    isOnSale: boolean;
    currentProduct: Product;
    hasSubscription?: boolean;
    productSet: ProductSetRecord;
}
