import * as React from 'react';
import { Label, ThemeProvider, Flex } from '@tn/retune';
import Countdown from 'components/Countdown';
import 'moment-timezone';
import * as moment from 'moment-business-days';
import './index.scss';

const NO_SHIPPING_DATE_FORMAT = 'MMMM D, YYYY';
// NOTE: This information is duplicated in businessDayMath.js in textnow-web and must be updated there
// as well.
const noShippingDays = [
    'February 21, 2022',
    'April 15, 2022',
    'May 23, 2022',
    'May 30, 2022',
    'July 1, 2022',
    'July 4, 2022',
    'August 1, 2022',
    'September 5, 2022',
    'September 30, 2022',
    'October 10, 2022',
    'November 24, 2022',
    'December 23, 2022',
    'December 26, 2022',
    'December 30, 2022',
    'January 2, 2023',
];

export class DeliveryPromise extends React.PureComponent<Props> {
    static defaultProps: Partial<Props> = {
        label: 'Want a phone by {shippingPromiseDay}?',
    };

    componentDidMount() {
        moment.updateLocale('us', {
            holidays: noShippingDays,
            holidayFormat: NO_SHIPPING_DATE_FORMAT,
        });
    }

    getLastOrderTime() {
        const currentTime = moment.default();
        let endOfDay = moment
            .default()
            .tz('America/Toronto')
            .set({ h: 15, m: 0, s: 0, ms: 0 });

        if (currentTime.isAfter(endOfDay)) {
            if (currentTime.add(1, 'days').format('MMM DD') === 'Dec 24') {
                endOfDay = endOfDay
                    .businessAdd(1)
                    .tz('America/Toronto')
                    .set({ h: 13, m: 30, s: 0 });
            } else {
                endOfDay = endOfDay
                    .businessAdd(1)
                    .tz('America/Toronto')
                    .set({ h: 15, m: 0, s: 0, ms: 0 });
            }
        }
        return endOfDay;
    }

    getPromiseDate() {
        const deliveryDate = this.getLastOrderTime().businessAdd(
            2 + this.props.shipping_delay
        );
        if (deliveryDate.diff(moment.default(), 'days') > 6) {
            return deliveryDate.format('MMM Do');
        } else {
            return deliveryDate.format('dddd');
        }
    }

    render() {
        const shippingPromiseDay = this.getPromiseDate();

        return (
            <Flex
                className="delivery-promise"
                automationId="shippingPromise"
                padding={[3]}
                xs
                direction="column"
                fluid
            >
                <Label size="small" bold values={{ shippingPromiseDay }}>
                    {this.props.label}
                </Label>
                <Label className="delivery-promise__countdown" size="small">
                    <Label size="small">Order within the next&nbsp;</Label>
                    <ThemeProvider bold>
                        <Countdown
                            size="small"
                            date={this.getLastOrderTime().toDate()}
                        />
                    </ThemeProvider>
                </Label>
            </Flex>
        );
    }
}

interface Props {
    label?: string;
    shipping_delay: number;
}

export default DeliveryPromise;
