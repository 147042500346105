import React from 'react';
import footerHtml from '../../../../templates/webflow/footer.html';

class Footer extends React.PureComponent {
    render() {
        return <div dangerouslySetInnerHTML={{ __html: footerHtml }} />;
    }
}

export default Footer;
