import * as React from 'react';

import { Badge, Flex, Label } from '@tn/retune';
import { Product } from '@tn/types/dist/domain/Product';

export interface Props {
    currentProduct: Product;
}

export default class SaleBadge extends React.PureComponent<Props> {
    render() {
        const savings =
            this.props.currentProduct.price -
            this.props.currentProduct.sale_price;
        const percentage = Math.round(
            (savings / this.props.currentProduct.price) * 100
        );

        return (
            <Flex marginRight={2}>
                <Badge appearance="danger">
                    <Label
                        appearance="none"
                        size="small"
                        values={{ savings: savings.toFixed(2), percentage }}
                    >{`Save \${savings} ({percentage}%)`}</Label>
                </Badge>
            </Flex>
        );
    }
}
