import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { Action, compose } from 'redux';
import { connect } from 'react-redux';
import { List } from 'immutable';

import { Modal } from '@tn/retune';

import { isModalOpened } from '@tn/modules/dist/modals/selectors';
import { MODALS } from '@tn/modules/dist/modals/types';
import { toggleModal } from '@tn/modules/dist/modals/actions';
import { plans, isLoading } from '@tn/modules/dist/plans/selectors';
import { PlanRecord } from '@tn/modules/dist/plans/types';
import './index.scss';
import PlansModalContent from 'containers/WirelessPage/components/PlansModalContent';

export class PlansModal extends React.PureComponent<Props> {
    toggle = () => this.props.toggleModal();

    render() {
        return (
            <Modal
                className="plans-modal"
                size="lg"
                isOpen={this.props.isModalOpened}
                toggle={this.toggle}
            >
                <PlansModalContent
                    plans={this.props.plans}
                    isLoadingPlans={this.props.isLoadingPlans}
                />
            </Modal>
        );
    }
}

interface Props {
    isModalOpened: boolean;
    toggleModal: () => void;
    plans: List<PlanRecord>;
    isLoadingPlans: boolean;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        toggleModal: () => dispatch(toggleModal(MODALS.PLANS)),
    };
}

const mapStateToProps = createStructuredSelector({
    isModalOpened: isModalOpened(MODALS.PLANS),
    plans: plans,
    isLoadingPlans: isLoading(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PlansModal);
