import * as React from 'react';

import { Button, Column, Container, Flex, H1, Image, Label } from '@tn/retune';
import handPhone2x from './images/Hand_Phone@2x.png';
import './index.scss';

import SimPage from '../DetailPage/SimPage';
import { List } from 'immutable';
import { PlanRecord } from '@tn/modules/dist/plans/types';
import { injectReducer, injectSaga } from '@tn/redux';
import reducer from 'containers/DeviceCheckerPage/reducers';
import saga from 'containers/DeviceCheckerPage/saga';
import { compose } from 'redux';
import { connect } from 'react-redux';

export class DeviceCheckerPage extends React.PureComponent {
    render() {
        return (
            <>
                <SimPage
                    plans={List<PlanRecord>([new PlanRecord()])}
                    planPicked={false}
                    deviceCheckerPage
                />
                <Flex fluid className="device-checker-page">
                    <Container>
                        {/* ---DITCH-YOUR-PHONE-BILL----------------------------------------------------------------------------------------------------------------------------*/}
                        <Flex
                            xs
                            justify="center"
                            directionMd="row-reverse"
                            direction="column"
                            className="device-checker-page_ditch-section"
                        >
                            {/* Right Column - Image*/}
                            <Column
                                xs={12}
                                md={9}
                                className="device-checker-page__hand-image"
                                justify="center"
                            >
                                <Image
                                    src={handPhone2x}
                                    alt="Hand Image"
                                    width="100%"
                                />
                            </Column>
                            {/* Left Column* - Text*/}
                            <Column xs={12} md={3} justify="center">
                                <Flex
                                    xs
                                    direction="column"
                                    justify="center"
                                    className="device-checker-page__ditch_column"
                                >
                                    <Flex className="device-checker-page__heading_text device-checker-page__ditch-width">
                                        <H1>
                                            Ditch your phone bill, but keep the
                                            phone service.
                                        </H1>
                                    </Flex>
                                    <Label
                                        size="regular"
                                        className="device-checker-page__paragraph_text"
                                    >
                                        TextNow is the only app that offers free
                                        nationwide cellular service, supported
                                        by ads. Start saving on our $0/month
                                        Nationwide Talk & Text plan.
                                    </Label>
                                </Flex>
                            </Column>
                        </Flex>
                        <Flex className="device-checker-page__mobile-background">
                            {/* ---GET-A-PLAN-THAT-WILL-KEEP-UP---------------------------------------------------------------------------------------------------------------------*/}
                            <Column
                                xs={12}
                                md={12}
                                justify="center"
                                className="device-checker-page__get-plan-margin"
                            >
                                <Flex className="device-checker-page__get_a_plan-column">
                                    <Flex
                                        alignItems="center"
                                        className="device-checker-page__heading_text device-checker-page__get-plan-heading"
                                    >
                                        <H1>Get a plan that will keep up.</H1>
                                    </Flex>
                                    <Label
                                        size="regular"
                                        className="device-checker-page__paragraph_text"
                                    >
                                        Your needs change, and so does your
                                        budget. Simply switch back to Free
                                        Nationwide Talk & Text. No penalty fees,
                                        no need to even contact us. The power is
                                        in your hands.
                                    </Label>
                                </Flex>
                            </Column>

                            {/* ADD ONS: Data plans (copied directly from "How It Works" page) */}
                            <div className="add-ons__table add-ons__table--data">
                                <div className="add-ons__row add-ons__row--bordered">
                                    <div className="add-ons__column">
                                        <h2 className="add-ons__heading">
                                            High Speed Data
                                        </h2>
                                        <p className="add-ons__text">
                                            Includes free roaming in Canada and
                                            Mexico and Unlimited 2G speeds after
                                            high speed data used.
                                        </p>
                                    </div>
                                </div>

                                <div className="add-ons__row add-ons__row--max-height add-ons__row--data">
                                    <div className="add-ons__group">
                                        <div className="add-ons__column add-ons__column--bordered">
                                            <div className="add-ons__details">
                                                <p className="add-ons__text">
                                                    <strong>
                                                        1GB High Speed Data
                                                    </strong>
                                                </p>
                                                <p className="add-ons__text add-ons__text--purple">
                                                    Includes Ad Free Lite
                                                </p>
                                            </div>

                                            <h3 className="add-ons__price">
                                                $8.99 /month
                                            </h3>
                                        </div>

                                        <div className="add-ons__column add-ons__column--bordered">
                                            <div className="add-ons__details">
                                                <p className="add-ons__text">
                                                    <strong>
                                                        2GB High Speed Data
                                                    </strong>
                                                </p>
                                                <p className="add-ons__text add-ons__text--green">
                                                    Includes Ad Free+
                                                </p>
                                            </div>

                                            <h3 className="add-ons__price">
                                                $15.99 /month
                                            </h3>
                                        </div>
                                    </div>

                                    <div className="add-ons__group">
                                        <div className="add-ons__column add-ons__column--bordered">
                                            <div className="add-ons__details">
                                                <p className="add-ons__text">
                                                    <strong>
                                                        3GB High Speed Data
                                                    </strong>
                                                </p>
                                                <p className="add-ons__text add-ons__text--green">
                                                    Includes Ad Free+
                                                </p>
                                            </div>

                                            <h3 className="add-ons__price">
                                                $19.99 /month
                                            </h3>
                                        </div>

                                        <div className="add-ons__column add-ons__column--bordered">
                                            <div className="add-ons__details">
                                                <p className="add-ons__text">
                                                    <strong>
                                                        5GB High Speed Data
                                                    </strong>
                                                </p>
                                                <p className="add-ons__text add-ons__text--green">
                                                    Includes Ad Free+
                                                </p>
                                            </div>

                                            <h3 className="add-ons__price">
                                                $27.99 /month
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ADD ONS: Ad Free (copied directly from "How It Works" page) */}
                            <div className="add-ons__table-group">
                                <div className="add-ons__table add-ons__table--green">
                                    <div className="add-ons__row add-ons__row--bordered add-ons--border-green">
                                        <div className="add-ons__column">
                                            <h2 className="add-ons__heading add-ons__text--green">
                                                Ad Free+
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="add-ons__row add-ons__row--max-height">
                                        <div className="add-ons__column">
                                            <div className="add-ons__details">
                                                <p className="add-ons__text add-ons__text--green">
                                                    No Ads
                                                </p>
                                                <p className="add-ons__text add-ons__text--green">
                                                    Voicemail Transcription
                                                </p>
                                                <p className="add-ons__text add-ons__text--green">
                                                    Unlimited Photo & Video
                                                    History
                                                </p>
                                            </div>

                                            <h3 className="add-ons__price add-ons__text--green">
                                                $9.99 /month
                                            </h3>
                                        </div>
                                    </div>
                                </div>

                                <div className="add-ons__table add-ons__table--purple">
                                    <div className="add-ons__row add-ons__row--bordered add-ons--border-purple">
                                        <div className="add-ons__column">
                                            <h2 className="add-ons__heading add-ons__text--purple">
                                                Ad Free Lite
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="add-ons__row add-ons__row--max-height">
                                        <div className="add-ons__column">
                                            <div className="add-ons__details">
                                                <p className="add-ons__text add-ons__text--purple">
                                                    Reduce ads to only the
                                                    bottom banner for a better
                                                    experience.
                                                </p>
                                            </div>

                                            <h3 className="add-ons__price add-ons__text--purple">
                                                $1.99 /month
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* ---READY-FOR-FREE-SERVICE-?------------------------------------------------------------------------------------------------------------------------*/}
                            <Flex className="device-checker-page__ready-section-margin">
                                <Flex xs direction="column" alignItems="center">
                                    <Flex className="device-checker-page__heading_text device-checker-page__ready-heading">
                                        <H1>Ready for Free Service?</H1>
                                    </Flex>
                                    <a href="#globalNavbar">
                                        <Button
                                            className={
                                                'device-checker-page__button'
                                            }
                                            label={'Check Phone Compatibility'}
                                        />
                                    </a>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Container>
                </Flex>
            </>
        );
    }
}

const withReducer = injectReducer({ key: 'detailPage', reducer });
const withSaga = injectSaga({ key: 'detailPage', saga });
const withConnect = connect();
export default compose(withReducer, withSaga, withConnect)(DeviceCheckerPage);
