import * as React from 'react';

import moment from 'moment';
import { Plan } from '@tn/types/dist/domain/Plan';

import {
    Flex,
    Label,
    SlidingItemSelector,
    SlidingItemSelectorElement,
} from '@tn/retune';
import { PlanRecord } from '@tn/modules/dist/plans/types';
import PriceLabel from 'components/PriceLabel';
import Amount from 'components/PriceLabel/Amount';
import { List } from 'immutable';
import { Product } from '@tn/types/dist/domain/Product';
import OutOfStock from 'containers/DetailPage/components/ProductSetContent/OutOfStock';
import PhoneUpgrade from 'containers/DetailPage/components/ProductSetContent/PhoneUpgrade';

import * as PlanConstants from '@tn/modules/dist/plans/constants';

export interface State {
    isOpen: boolean;
}

export default class PlansSelector extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    getPlanPrice(plan: Plan, isPromo: boolean) {
        if (isPromo) {
            if (plan.promoForPlan) {
                return plan.price;
            } else {
                return plan.promo_price;
            }
        } else {
            if (plan.promoForPlan) {
                return plan.promoForPlan.price;
            } else {
                return plan.price;
            }
        }
    }

    renderSelectedPlaceHolder(plan: Plan) {
        const unlimited = plan.category === 'UNLIMITED' ? ' †' : '';
        return (
            <>
                {plan.promoType === PlanConstants.PROMO_TYPE_DATE &&
                    plan.promoUntil && (
                        <Flex marginTop={2} xs direction="column">
                            <Label
                                automationId="selectedPlanName"
                                size="micro"
                                appearance="muted"
                            >
                                {plan.name}
                            </Label>

                            <Flex marginTop={1}>
                                <Label
                                    size="micro"
                                    italic
                                    appearance="muted"
                                    values={{
                                        price: Amount.fromCents(
                                            this.getPlanPrice(plan, false)
                                        ).toString(),
                                        date: moment(
                                            plan.promoUntil.date
                                        ).format('MMM D, YYYY'),
                                    }}
                                >
                                    {`*{price} / month starting {date}`}
                                </Label>
                            </Flex>
                        </Flex>
                    )}

                {plan.promoType === PlanConstants.PROMO_TYPE_PERIOD &&
                    plan.promo_period && (
                        <Flex marginTop={2} xs direction="column">
                            <Label
                                automationId="selectedPlanName"
                                size="micro"
                                appearance="muted"
                            >
                                {plan.name}
                            </Label>

                            <Flex marginTop={1}>
                                <Label
                                    size="micro"
                                    italic
                                    appearance="muted"
                                    values={{
                                        price: Amount.fromCents(
                                            this.getPlanPrice(plan, false)
                                        ).toString(),
                                        months:
                                            plan.promo_period > 1
                                                ? plan.promo_period
                                                : '',
                                        plural:
                                            plan.promo_period > 1 ? 's' : '',
                                    }}
                                >
                                    {`*{price} / month after the first {months} month{plural}`}
                                </Label>
                            </Flex>
                        </Flex>
                    )}

                {(!plan.promoType ||
                    plan.promoType === PlanConstants.PROMO_TYPE_NONE) && (
                    <Flex marginTop={2} xs direction="column">
                        <Label
                            automationId="selectedPlanName"
                            appearance="muted"
                            size="small"
                        >{`${plan.name}${unlimited}`}</Label>
                    </Flex>
                )}
            </>
        );
    }

    renderPlaceholderRight(plan: Plan) {
        const supText =
            plan.promoType !== PlanConstants.PROMO_TYPE_NONE ? '*' : '';
        return (
            <PriceLabel
                className="product-set__plan__price"
                size="regular"
                supText={supText}
                amount={
                    this.getPlanPrice(
                        plan,
                        plan.promoType !== PlanConstants.PROMO_TYPE_NONE
                    ) / 100
                }
            />
        );
    }

    renderSubTitle(plan: Plan) {
        return (
            <>
                {plan.promoType === PlanConstants.PROMO_TYPE_DATE &&
                    plan.promoUntil && (
                        <Flex marginTop={2} xs direction="column">
                            <Label
                                size="micro"
                                className="product-set__plan__price"
                                values={{
                                    price: Amount.fromCents(
                                        this.getPlanPrice(plan, true)
                                    ).toString(),
                                    date: moment(plan.promoUntil.date).format(
                                        'MMM D, YYYY'
                                    ),
                                }}
                            >
                                {`{price} until {date}*`}
                            </Label>

                            <Flex marginTop={1}>
                                <Label
                                    size="micro"
                                    italic
                                    appearance="muted"
                                    values={{
                                        price: Amount.fromCents(
                                            this.getPlanPrice(plan, false)
                                        ).toString(),
                                    }}
                                >{`*then {price} / month`}</Label>
                            </Flex>
                        </Flex>
                    )}

                {plan.promoType === PlanConstants.PROMO_TYPE_PERIOD &&
                    plan.promo_period && (
                        <Flex marginTop={2} xs direction="column">
                            <Label
                                size="micro"
                                className="product-set__plan__price"
                                values={{
                                    price: Amount.fromCents(
                                        this.getPlanPrice(plan, true)
                                    ).toString(),
                                    months:
                                        plan.promo_period > 1
                                            ? plan.promo_period
                                            : '',
                                    plural: plan.promo_period > 1 ? 's' : '',
                                }}
                            >
                                {`{price} for the first {months} month{plural}*`}
                            </Label>

                            <Flex marginTop={1}>
                                <Label
                                    size="micro"
                                    italic
                                    appearance="muted"
                                    values={{
                                        price: Amount.fromCents(
                                            this.getPlanPrice(plan, false)
                                        ).toString(),
                                    }}
                                >{`*then {price} / month`}</Label>
                            </Flex>
                        </Flex>
                    )}

                {(!plan.promoType ||
                    plan.promoType === PlanConstants.PROMO_TYPE_NONE) && (
                    <Flex marginTop={2} xs direction="row">
                        <PriceLabel
                            className="product-set__plan__price"
                            size="small"
                            amount={this.getPlanPrice(plan, false) / 100}
                        />
                        &nbsp;
                        <Label
                            size="small"
                            className="product-set__plan__price"
                        >
                            / month
                        </Label>
                    </Flex>
                )}
            </>
        );
    }

    renderPlans() {
        return this.props.plans.map((plan: PlanRecord, index: number) => {
            const category = plan.promoForPlan
                ? plan.promoForPlan.category
                : plan.category;
            const unlimited = category === 'UNLIMITED' ? ' †' : '';

            return (
                <SlidingItemSelectorElement
                    automationId={`selectionSlideOut_${index}`}
                    key={index}
                    title={`${plan.name}${unlimited}`}
                    value={plan.stripe_id}
                    selectedPlaceholder={this.renderSelectedPlaceHolder(plan)}
                    selectedPlaceholderRight={this.renderPlaceholderRight(plan)}
                    subTitle={this.renderSubTitle(plan)}
                />
            );
        });
    }

    renderSelectPlans() {
        return (
            <SlidingItemSelector
                automationId="planSelection"
                title="Select your plan"
                description="All plans include unlimited talk & text to U.S and Canada. High speed plans include unlimited 2G data and Ad Free+."
                selectedPlaceholder="Selected Monthly Plan"
                selectedItem={this.props.getValidCurrentPlan}
                onSelectedItem={this.props.onUpdateCurrentPlan}
                width="390px"
            >
                {this.renderPlans()}

                <Label size="micro" appearance="muted" noTranslate>
                    †
                </Label>
                <Label size="micro" appearance="muted">
                    Users who exceed their included data allowance will
                    experience reduced speeds until the next cycle.
                </Label>
            </SlidingItemSelector>
        );
    }

    onClick = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
        return e.preventDefault();
    };

    toggle = () => {
        if (this.state.isOpen) {
            this.setState({
                isOpen: false,
            });
        }
    };

    render() {
        if (this.props.currentProduct && this.props.currentProduct.sold_out) {
            return <OutOfStock />;
        }

        if (!this.props.isPhoneUpgrade) {
            return <Flex marginTop={3}>{this.renderSelectPlans()}</Flex>;
        }

        return (
            <Flex marginTop={4} marginBottom={1}>
                <Flex direction="row" marginBottom={3}>
                    <PhoneUpgrade />
                </Flex>

                {this.renderSelectPlans()}
            </Flex>
        );
    }
}

export interface Props {
    getValidCurrentPlan?: string;
    onUpdateCurrentPlan?: (stripeId: string) => void;
    currentProduct?: Product;
    plans: List<PlanRecord>;
    isPhoneUpgrade: boolean;
    onTogglePlanOption?: (e: Event) => void;
    planOption?: string;
}
