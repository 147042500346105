import * as React from 'react';
import { SizeRange, JustifyRange } from '@tn/retune/build/lib/components/types';
import { Label, Flex, ThemeProvider } from '@tn/retune';
import Amount from './Amount';

import './index.scss';

class PriceLabel extends React.PureComponent<Props> {
    renderSupText() {
        return (
            this.props.supText && (
                <Label
                    key="supText"
                    size="small"
                    xs="small"
                    appearance="none"
                    className="price-label__sup-text"
                    noTranslate
                >
                    {this.props.supText}
                </Label>
            )
        );
    }

    renderSubText() {
        return (
            <div key="subText" className="price-label__sub-text">
                {this.props.subText && typeof this.props.subText === 'string' && (
                    <Label size="small" xs="small" noTranslate>
                        {this.props.subText}
                    </Label>
                )}
                {this.props.subText &&
                    typeof this.props.subText !== 'string' &&
                    this.props.subText}
            </div>
        );
    }

    renderPrice() {
        const amount = Amount.fromDollars(this.props.amount);

        if (
            this.props.amount &&
            (this.props.size === 'big' || this.props.size === 'jumbo')
        ) {
            return [
                <Label
                    key="dollarSign"
                    size="regular"
                    xs="small"
                    className="price-label__dollar-sign price-label__sup-text"
                    noTranslate
                >
                    {amount.currencySymbol}
                </Label>,
                <Label
                    key="dollar"
                    size="none"
                    className={`price-label__dollar`}
                    noTranslate
                >
                    {amount.dollars}
                </Label>,
                !this.props.hideCents && (
                    <Flex xs direction="column" key="subText">
                        <Label
                            key="cents"
                            size="regular"
                            xs="small"
                            className="price-label__cents price-label__sup-text"
                            noTranslate
                        >
                            {amount.cents}
                        </Label>
                        {this.renderSubText()}
                    </Flex>
                ),
                this.renderSupText(),
            ];
        } else {
            if (this.props.size === 'big' || this.props.size === 'jumbo') {
                return (
                    <Label className="price-label__dollar" size="none">
                        {amount.toString().toUpperCase()}
                    </Label>
                );
            } else {
                return (
                    <>
                        <Label size={this.props.size}>
                            {amount.toString()}
                        </Label>
                        <Flex key={1} direction="column" xs justify="center">
                            {this.renderSupText()}
                            {this.renderSubText()}
                        </Flex>
                    </>
                );
            }
        }
    }

    render() {
        let className = `price-label`;
        let ariaLabel = '';

        if (this.props.crossOut) {
            className += ' price-label--cross-out';
            ariaLabel = 'Original Price: $' + this.props.amount;
        } else {
            ariaLabel = 'Sale Price: $' + this.props.amount;
        }

        if (this.props.size) {
            className += ` price-label--${this.props.size}`;
        }

        if (this.props.appearance) {
            className += ` price-label--${this.props.appearance}`;
        }

        return (
            <ThemeProvider branded={false}>
                <Flex direction="column" xs className={this.props.className}>
                    <Flex xs direction="row" justify={this.props.justify}>
                        <span aria-label={ariaLabel} className={className}>
                            {this.renderPrice()}
                        </span>
                    </Flex>

                    {this.props.disclaimer && (
                        <Flex
                            marginTop={2}
                            className="price-label__disclaimer"
                            xs
                        >
                            {typeof this.props.disclaimer === 'string' && (
                                <Label>{this.props.disclaimer}</Label>
                            )}
                            {typeof this.props.disclaimer !== 'string' &&
                                this.props.disclaimer}
                        </Flex>
                    )}
                </Flex>
            </ThemeProvider>
        );
    }
}

export interface Props {
    /**
     * price amount
     */
    amount: number;

    /** class name */
    className?: string;

    /** crossOut label text */
    crossOut?: boolean;

    /** appearance: danger */
    appearance?: 'danger' | 'muted';

    /** hide amount cents */
    hideCents?: boolean;

    /** amount superscript text */
    supText?: string;

    /** amount subscript text */
    subText?: string | React.ReactNode;

    disclaimer?: string | React.ReactNode;

    justify?: JustifyRange;

    size?: SizeRange | 'big' | 'jumbo';
}

export default PriceLabel;
