import { reducer as formReducer } from 'redux-form/immutable';

import { configureStore, history as History } from '@tn/redux';
import { getReducers, getMiddlewares } from '@tn/modules';

const modules = [
    'deep-link',
    'error-handling',
    'geo-location',
    'internationalization',
    'modals',
    'plans',
    'product-set',
    'product-set-list',
    'product-set-list-filters',
    'product-stock',
    'session',
    'subscription',
    'user',
];

export const store = configureStore(
    {},
    { ...{ form: formReducer }, ...getReducers(...modules) },
    ...getMiddlewares()
);
export const history = History;
