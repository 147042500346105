import * as React from 'react';
import { compose, Action } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Container, Row, Flex, Column } from '@tn/retune';
import * as _window from '@tn/window';
import { H2, Label } from '@tn/retune';

import { injectSaga, injectReducer } from '@tn/redux';
import {
    SHIPPING,
    MONEY_BACK,
    WARRANTY,
    NATIONWIDE,
} from 'containers/App/modules/guaratee/constants';
import IconList from 'containers/App/components/IconList';
import HeaderBanner from 'containers/WirelessPage/components/HeaderBanner';
import ProductsList from 'containers/WirelessPage/components/ProductSetTileList';
import FAQ from 'containers/App/components/FAQ';
import FeaturedOn from 'containers/WirelessPage/components/FeaturedOn';
import PlansModal from 'containers/WirelessPage/components/PlansModal';
import * as deepLinkActions from '@tn/modules/dist/deep-link/actions';
import saga from 'containers/WirelessPage/saga';
import reducer from 'containers/WirelessPage/reducers';
import './index.scss';

export class WirelessPage extends React.PureComponent<Props> {
    static defaultProps: Partial<Props> = {
        location: {},
    };

    componentDidMount() {
        // Setting the deep linking for currentPlan
        const plansString = _window.getQueryVariable(
            'plan',
            this.props.location.search
        );
        if (plansString) {
            this.props.setPlanString(plansString);
        }
    }

    renderContent() {
        return (
            <>
                <HeaderBanner key="banners" />
                <Flex key="banners-icons" xs={false} md fluid justify="between">
                    <IconList
                        appearance="inverted"
                        iconItemList={[
                            SHIPPING,
                            MONEY_BACK,
                            WARRANTY,
                            NATIONWIDE,
                        ]}
                    />
                </Flex>

                <Flex
                    xs
                    direction="column"
                    fluid
                    justify="center"
                    alignItems="center"
                    padding={[5, 3, 5, 3]}
                >
                    <H2 centered>TextNow Enabled Phones</H2>
                    <Label
                        className="phone-message-label"
                        centered
                        appearance="muted"
                    >
                        Each phone is shipped with our unique technology that
                        gives you Free Nationwide Talk &amp; Text right out of
                        the box.
                    </Label>
                </Flex>

                <ProductsList location={this.props.location} />
            </>
        );
    }

    render() {
        return (
            <div id="wirelessPage">
                <Helmet key="helmet">
                    <title>
                        Wireless Phone Plans | Cell Phone Plans | TextNow
                        Wireless
                    </title>
                    <meta
                        name="description"
                        content="No contract cell phone plans from TextNow Wireless. Pay as you go mobile phone plans start at $9.99. Free Shipping & hassle free returns on all smartphones!"
                    />
                    <link
                        rel="stylesheet"
                        href="https://widget.reviews.io/rating-snippet/dist.css"
                    />
                </Helmet>

                {this.renderContent()}

                <Flex key="banners-icons-mobile" md={false} fluid>
                    <IconList iconItemList={[NATIONWIDE, SHIPPING]} />
                </Flex>

                <FeaturedOn key="featured-on" />

                <Container key="faq">
                    <Row>
                        <Column>
                            <FAQ />
                            <Flex marginBottom={5}>
                                <Label size="micro" appearance="muted">
                                    TextNow's wireless network services are
                                    provided using the wireless network of a
                                    leading national mobile service provider*
                                </Label>
                            </Flex>
                        </Column>
                    </Row>
                </Container>

                <PlansModal />
            </div>
        );
    }
}

interface Props {
    location: {
        search?: string;
    };
    setPlanString: (queryString: string) => void;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        setPlanString: (queryString: string) =>
            dispatch(deepLinkActions.setDeepLink({ planString: queryString })),
    };
}

const withConnect = connect(null, mapDispatchToProps);

const withReducer = injectReducer({ key: 'wirelessPage', reducer });
const withSaga = injectSaga({ key: 'wirelessPage', saga });

export default compose(withReducer, withSaga, withConnect)(WirelessPage);
