import * as React from 'react';

import { Label, H2, Flex, Button, Link, H1 } from '@tn/retune';
import * as _window from '@tn/window';

import './index.scss';

export class SimAssignFailed extends React.PureComponent<Props> {
    navigateToWireless = (): void => {
        const purchaseType = _window.getQueryVariable('purchaseType')
            ? `?purchaseType=${_window.getQueryVariable('purchaseType')}`
            : '';
        document.location.assign(`/wireless/devices${purchaseType}`);
    };

    renderSimError = () => {
        // TODO: Move to component.
        return (
            <Flex
                direction="column"
                className="sim-assign-failed"
                alignSelf="center"
                fluid
                xs
            >
                <H2>{this.props.title}</H2>
                <Flex justify="center" marginBottom={3} xs>
                    <Label>{this.props.subTitle}</Label>
                </Flex>
                <Flex
                    alignItems="start"
                    direction="column"
                    md
                    xs={false}
                    className="sim-assign-failed__button"
                >
                    <Button
                        onClick={
                            this.props.onButtonClick
                                ? this.props.onButtonClick
                                : this.navigateToWireless
                        }
                        label="Shop Phones"
                    />
                    <Link onClick={this.props.onLinkClick} size="regular">
                        Bring a different phone
                    </Link>
                </Flex>
                <Flex
                    alignItems="center"
                    direction="column"
                    md={false}
                    xs
                    className="sim-assign-failed__button"
                >
                    <Button
                        onClick={
                            this.props.onButtonClick
                                ? this.props.onButtonClick
                                : this.navigateToWireless
                        }
                        label="Shop Phones"
                        wide
                    />
                    <Link onClick={this.props.onLinkClick} size="regular">
                        Bring a different phone
                    </Link>
                </Flex>
            </Flex>
        );
    };

    renderDeviceCheckerError = () => {
        // TODO: Move to component.
        return (
            <Flex
                direction="column"
                className="device-check"
                alignSelf="center"
                fluid
                xs
                justify="center"
            >
                <Flex className="device-check-sim-assign-failed">
                    <H2>Sorry.</H2>
                    <H1>This phone is not compatible.</H1>
                    <Flex
                        marginBottom={4}
                        xs
                        className="subText"
                        justify="center"
                    >
                        Don’t worry, we have a selection of affordable phones
                        available in our store.
                    </Flex>
                    <Flex
                        xs
                        alignSelf="center"
                        justify="center"
                        className="phone-not-compatible-button-group device-checker-button--opacity"
                    >
                        <Button
                            inverted
                            onClick={
                                this.props.onButtonClick
                                    ? this.props.onButtonClick
                                    : this.navigateToWireless
                            }
                            label="Shop Phones"
                            className="device-checker-button"
                        />
                        <Button
                            inverted
                            onClick={this.props.onLinkClick}
                            size="regular"
                            label="Check another phone"
                            className="device-checker-button check-another-phone"
                        />
                    </Flex>
                </Flex>
            </Flex>
        );
    };

    render() {
        if (this.props.deviceCheckerPage) {
            return this.renderDeviceCheckerError();
        }

        return this.renderSimError();
    }
}

interface Props {
    onLinkClick: () => void;
    onButtonClick?: () => void;
    title: string;
    subTitle: string;
    deviceCheckerPage?: boolean;
}

export default SimAssignFailed;
