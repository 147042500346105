import * as React from 'react';
import { Flex, Image, Container, H2 } from '@tn/retune';

import Usatoday from './images/UsaToday.png';
import Times from './images/TimesLogo.png';
import Bloomberg from './images/Bloomberg.svg';
import Cnet from './images/Cnet.png';
import WallStreetJournal from './images/WallStreetJournal.svg';
import './index.scss';

export class FeaturedOn extends React.PureComponent {
    render() {
        return (
            <Flex className="featured-on" paddingTop={5} lg xs={false}>
                <Container>
                    <H2>Featured On</H2>
                    <Flex wrap="wrap" justify="around" alignItems="center" xs>
                        <Flex margin={[3, 4, 3, 4]}>
                            <Image lazyLoad alt="USA Today" src={Usatoday} />
                        </Flex>
                        <Flex margin={[3, 4, 3, 4]}>
                            <Image lazyLoad alt="Times" src={Times} />
                        </Flex>
                        <Flex margin={[3, 4, 3, 4]}>
                            <Image
                                lazyLoad
                                alt="Wall Street Journal"
                                src={WallStreetJournal}
                            />
                        </Flex>
                        <Flex margin={[3, 4, 3, 4]}>
                            <Image lazyLoad alt="Cnet" src={Cnet} />
                        </Flex>
                        <Flex margin={[3, 4, 3, 4]}>
                            <Image lazyLoad alt="Bloomberg" src={Bloomberg} />
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
        );
    }
}

export default FeaturedOn;
