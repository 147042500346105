import * as React from 'react';
import { connect } from 'react-redux';
import { List } from 'immutable';

import * as Window from '@tn/window';
import Analytics from '@tn/analytics';
import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';
import { Container, Row, Column, Flex, Image } from '@tn/retune';
import { campaignTracker } from '@tn/campaign-tracker';

import DevicePreview from 'containers/DetailPage/components/DevicePreview';
import PurchaseDetails from 'containers/DetailPage/components/PurchaseDetails';
import ProductSetHeader from 'containers/DetailPage/components/ProductSetContent/ProductSetHeader';
import DevicePopOuts from 'containers/DetailPage/components/DevicePopOuts';
import checkoutUrlGenerator from 'containers/DetailPage/components/ProductSetContent/checkoutUrlGenerator';

import * as subscriptionSelectors from '@tn/modules/dist/subscription/selectors';
import * as userSelectors from '@tn/modules/dist/user/selectors';

import { PlanRecord } from '@tn/modules/dist/plans/types';

import { PRODUCT_ID_DEFAULT_TMOBILE_SIM } from './constants';
import './index.scss';
import { Product } from '@tn/types/dist/domain/Product';

export class ProductSetContent extends React.PureComponent<Props, State> {
    baseSimProduct: Product = {
        color: '',
        dropdown_label: '',
        giftable: false,
        id: 0,
        img_url: '',
        is_lte: false,
        is_wimax: false,
        product_descriptions: [],
        product_features: [],
        product_images: [],
        product_specifications: [],
        refurbished: false,
        sale_price: 0,
        shipping_delay: 0,
        short_description: '',
        sold_out: false,
        upgrade_price: 0,
        web_store_enabled: false,
        webstore_quality: '',
        model: 'Tri-Cut SIM Home',
        name: 'SIM Card (GSM Tri-cut)',
        price: 0.99,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            toggleUsedModal: false,
        };
    }

    componentDidMount() {
        if (this.props.isSim) {
            campaignTracker.addCampaign({ exp_sim_flow: '1' });
        }
    }

    getCurrentPlan = () => {
        const currentPlan = this.props.plans.find(
            (plan) => plan.stripe_id === this.props.currentPlan
        );
        // in case the current plan is not yet set in the store
        return currentPlan ? currentPlan : this.props.plans.get(0);
    };

    // This is the function that is triggered, and redirects to checkout, when a user is purchasing a non sim device
    // This is called from the button on purchase details
    onBuy = (purchaseType?: string) => {
        const currentProduct =
            this.props.productSet.products.find(
                (product) =>
                    product.id.toString() === this.getCurrentProductId()
            ) || this.props.productSet.products[0];

        const isUserNameForPurchase = Window.getQueryVariable(
            'usernameForPurchase'
        );
        const currentPlan = !isUserNameForPurchase
            ? this.getCurrentPlan()
            : undefined;

        if (document.location) {
            let planId: number | undefined = undefined;

            Analytics.setECProduct(currentProduct);

            let label = `${this.props.productSet.id}||${this.props.productSet.name}`;

            if (currentPlan) {
                planId = currentPlan.id;
                label = `${currentPlan.stripe_id}||${currentPlan.name}||${this.props.productSet.id}||${this.props.productSet.name}`;

                Analytics.setECPlan(currentPlan);
            }

            Analytics.track('store', 'clicked_buy_now', label);

            Analytics.setECAction('setAction', 'add');

            const checkoutUrl = checkoutUrlGenerator({
                purchaseType,
                planId,
                productId: currentProduct.id,
                productSetId: this.props.productSet.id,
                utmCampaign: this.props.utmCampaign,
            });
            document.location.assign(checkoutUrl);
        }
    };

    onGetSimCard = () => {
        const currentPlan = this.getCurrentPlan();

        const purchaseType = Window.getQueryVariable('purchaseType')
            ? Window.getQueryVariable('purchaseType')
            : '';
        const productId =
            this.props.currentProduct || PRODUCT_ID_DEFAULT_TMOBILE_SIM;

        if (!currentPlan) {
            return;
        }

        Analytics.setECProduct(this.baseSimProduct);
        Analytics.track('store', 'clicked_buy_now');

        const urlData: {
            planId: number;
            purchaseType: string;
            utmCampaign?: string;
        } = {
            planId: currentPlan.id,
            purchaseType,
            utmCampaign: this.props.utmCampaign,
        };
        let checkoutUrl = checkoutUrlGenerator(urlData);

        // We have to add the product id after the fact because the generator requires a product set with the id
        // Sim does not have the requirement
        checkoutUrl = `${checkoutUrl}&product_id=${productId}`;

        document.location.assign(checkoutUrl);
    };

    getCurrentProductId() {
        let currentProductId: string =
            this.props.currentProduct ||
            Window.getQueryVariable('product', window.location.search);

        // select default product id
        if (!currentProductId) {
            currentProductId = this.props.productSet.default_product_id.toString();

            // check if this default product is sold_out, otherwise, set none
            const defaultProduct = this.props.productSet.products.filter(
                (product) => product.id.toString() === currentProductId
            );

            if (defaultProduct.length > 0 && defaultProduct[0].sold_out) {
                currentProductId = '';
            }
        }

        // look for first product that is not sold_out
        this.props.productSet.products.forEach((product) => {
            if (!product.sold_out && !currentProductId) {
                currentProductId = product.id.toString();
                return;
            }
        });

        // fall back to select first if none is selected
        if (!currentProductId) {
            currentProductId = this.props.productSet.products[0].id.toString();
        }

        return currentProductId.toString();
    }

    // Rendering the right panel of the page
    renderRightPanel = () => {
        // The isSim param allows the purchase details button to show the proper text and call the correct function
        if (this.props.isSim) {
            return (
                <PurchaseDetails
                    hasSubscription={this.props.hasSubscription}
                    productSet={this.props.productSet}
                    plans={this.props.plans}
                    onUpdateCurrentProduct={this.props.onUpdateCurrentProduct}
                    currentProductId={this.getCurrentProductId()}
                    onClick={this.onGetSimCard}
                    isChildUser={this.props.isChildUser}
                    isSim
                />
            );
        } else {
            return (
                <PurchaseDetails
                    hasSubscription={this.props.hasSubscription}
                    productSet={this.props.productSet}
                    currentPlan={this.props.currentPlan}
                    plans={this.props.plans}
                    onUpdateCurrentPlan={this.props.onUpdateCurrentPlan}
                    onUpdateCurrentProduct={this.props.onUpdateCurrentProduct}
                    currentProductId={this.getCurrentProductId()}
                    onClick={this.onBuy}
                />
            );
        }
    };

    render() {
        if (!this.props.productSet.products) {
            return null;
        }

        const currentProduct =
            this.props.productSet.products.find(
                (product) =>
                    product.id.toString() === this.getCurrentProductId()
            ) || this.props.productSet.products[0];

        if (!currentProduct) {
            return null;
        }

        const imageGallery = (
            <>
                {!this.props.isSim && (
                    <Column
                        justify="center"
                        md={6}
                        xs={12}
                        sm={12}
                        className="product-set__preview"
                    >
                        <Flex xs justify="center" fullHeight>
                            <DevicePreview
                                productImages={currentProduct.product_images}
                                productSetId={this.props.productSet.id}
                            />
                        </Flex>
                    </Column>
                )}
                {this.props.isSim && (
                    <>
                        <Column
                            justify="end"
                            alignItems="end"
                            xl={6}
                            xs={false}
                            className="product-set__sim-preview"
                        >
                            <Image
                                src={currentProduct.product_images[0].img_url}
                                alt="TextNow SIM Card"
                                src2x
                                width="720px"
                            />
                        </Column>
                        <Column
                            justify="center"
                            alignItems="end"
                            lg={6}
                            xs={false}
                            xl={false}
                            className="product-set__sim-preview"
                        >
                            <Image
                                src={currentProduct.product_images[0].img_url}
                                alt="TextNow SIM Card"
                                src2x
                                width="720px"
                            />
                        </Column>
                        <Column
                            justify="center"
                            alignItems="end"
                            md={6}
                            lg={false}
                            xs={false}
                            className="product-set__sim-preview"
                        >
                            <Image
                                src={currentProduct.product_images[0].img_url}
                                alt="TextNow SIM Card"
                                src2x
                                width="600px"
                            />
                        </Column>
                        <Column
                            justify="end"
                            alignItems="end"
                            md={false}
                            xs={12}
                            className="product-set__sim-preview"
                        >
                            <Image
                                src={currentProduct.product_images[0].img_url}
                                alt="TextNow SIM Card"
                                src2x
                                width="100%"
                            />
                        </Column>
                    </>
                )}
            </>
        );

        const rightPanel = this.renderRightPanel();

        return (
            <Container fluid className="product-set">
                {/*
                    The reason the product set header is both here and in the purchase details is that the header must
                    be in very different places based on the size of the screen
                */}
                <Row xs md={false}>
                    <Column>
                        <Flex xs md={false} direction="column" marginBottom={4}>
                            <ProductSetHeader
                                isSIM={this.props.isSim}
                                isOnSale={
                                    currentProduct.price >
                                    currentProduct.sale_price
                                }
                                currentProduct={currentProduct}
                                hasSubscription={this.props.hasSubscription}
                                productSet={this.props.productSet}
                            />
                        </Flex>
                    </Column>
                </Row>
                <Row>
                    {imageGallery}

                    <Column
                        sm={12}
                        md={6}
                        className="d-flex product-set__right"
                        justify="center"
                    >
                        {rightPanel}
                    </Column>
                </Row>
                {!currentProduct.sold_out && (
                    <DevicePopOuts productId={currentProduct.id.toString()} />
                )}
            </Container>
        );
    }
}

interface Props {
    hasSubscription?: boolean;
    qntStock?: number;
    productSet: ProductSetRecord;
    isChildUser?: boolean;
    currentPlan?: string;
    currentProduct?: string;
    plans: List<PlanRecord>;
    onUpdateCurrentPlan?: (stripeId: string) => void;
    onUpdateCurrentProduct?: (id: string) => void;
    isSim?: boolean;
    utmCampaign: string;
}

interface State {
    toggleUsedModal: boolean;
}

const makeMapStateToProps = () => {
    const getHasSubscription = subscriptionSelectors.hasSubscription();
    const getUser = userSelectors.getUser;

    const mapStateToProps = (state) => {
        const user = getUser(state);
        const isChildUser = subscriptionSelectors.isChildUser(user.username)(
            state
        );

        return {
            hasSubscription: getHasSubscription(state) || false,
            isChildUser,
        };
    };
    return mapStateToProps;
};

const withConnect = connect<{}, {}, Props>(makeMapStateToProps);

export default withConnect(ProductSetContent);
