import * as React from 'react';
import { Product } from '@tn/types/dist/domain/Product';
import { Flex, Image } from '@tn/retune';

import './index.scss';

export interface Props {
    currentProduct: Product;
}

export default class FeaturedImages extends React.PureComponent<Props> {
    renderImages() {
        const featuredImages = this.props.currentProduct.product_images.filter(
            (image) => image.img_url.indexOf('feature_') >= 0
        );

        return featuredImages.map((image, idx) => {
            return (
                <Flex
                    key={idx}
                    className="device-content__featured__image"
                    fluid
                >
                    <Image src={image.img_url} alt="" />
                </Flex>
            );
        });
    }

    render() {
        return (
            <Flex
                paddingBottom={5}
                fluid
                xs
                className="device-content__featured"
            >
                {this.renderImages()}
            </Flex>
        );
    }
}
