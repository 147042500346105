import * as React from 'react';
import { Container, Column, Label, Flex, Image, H1, Link } from '@tn/retune';
import reviewIcon1 from './images/text-now-reviews_1.svg';
import reviewIcon2 from './images/text-now-reviews_2.svg';
import reviewIcon3 from './images/text-now-reviews_3.svg';
import reviewIcon4 from './images/text-now-reviews_4.svg';

import './index.scss';

export class SimCardContent extends React.PureComponent {
    render() {
        return (
            <Flex fluid className="sim-page">
                <Container>
                    {/* ---ALWAYS-CONNECTED----------------------------------------------------------------------------------------------------------------------------------*/}
                    <Flex
                        xs
                        justify="center"
                        directionMd="row-reverse"
                        direction="column"
                        className="sim-page__section-margin"
                    >
                        {/* DESKTOP - Right Column*/}
                        <Column
                            xs={12}
                            md={6}
                            className="sim-page__phone-image"
                            justify="center"
                        >
                            <Image
                                src="https://static.textnow.com/web_sales/non-sale/sim-page/sprint-sim-card@2x.png"
                                alt="CDMA SIM Card"
                                width="100%"
                            />
                        </Column>
                        {/* DESKTOP - Left Column*/}
                        <Column xs={12} md={6} justify="center">
                            <Flex
                                xs
                                direction="column"
                                alignItems="start"
                                justify="center"
                                className="sim-content-column-width"
                            >
                                <Flex>
                                    <H1>
                                        Always connected,
                                        <br /> always free
                                    </H1>
                                </Flex>
                                <Label size="regular">
                                    Welcome to Free Nationwide Talk & Text.
                                    Enjoy unlimited calling and texting over
                                    TextNow's Nationwide Network*. All paid for
                                    by ads, and not by you. Get all the features
                                    you love, like picture and video messages,
                                    unlimited voicemails, group texting, and so
                                    much more - at no cost at all.
                                </Label>
                            </Flex>
                        </Column>
                    </Flex>
                    {/* ---NEED-DATA-----------------------------------------------------------------------------------------------------------------------------------------*/}
                    <Flex
                        xs
                        justify="center"
                        directionMd="row"
                        direction="column"
                        className="sim-page__section-margin"
                    >
                        {/* DESKTOP - Left Column*/}
                        <Column xs={12} md={6}>
                            <Flex
                                xs={false}
                                lg
                                alignItems="center"
                                className="sim-page__logo-image"
                            >
                                <Image
                                    src="https://static.textnow.com/web_sales/non-sale/sim-page/app-icons@2x.png"
                                    alt="App Icons"
                                    width="663px"
                                />
                            </Flex>
                            <Flex
                                xs={false}
                                md
                                lg={false}
                                alignItems="center"
                                className="sim-page__logo-image"
                            >
                                <Image
                                    src="https://static.textnow.com/web_sales/non-sale/sim-page/app-icons@2x.png"
                                    alt="App Icons"
                                    width="500px"
                                />
                            </Flex>
                            <Flex
                                xs
                                md={false}
                                className="sim-page__logo-image-mobile"
                            >
                                <Image
                                    src="https://static.textnow.com/web_sales/non-sale/sim-page/app-icons@2x.png"
                                    alt="App Icons"
                                    width="100%"
                                />
                            </Flex>
                        </Column>
                        {/* DESKTOP - Right Column*/}
                        <Column xs={12} md={6} justify="center">
                            <Flex
                                xs
                                direction="column"
                                justify="center"
                                alignItems="start"
                                className="sim-content-column-width sim-content-need-data"
                            >
                                <Flex>
                                    <H1>
                                        Need Data?
                                        <br /> We’ve got you covered
                                    </H1>
                                </Flex>
                                <Label size="regular">
                                    If you do more on-the-go than at home, you
                                    can add fast reliable 4G LTE nationwide data
                                    coverage for more than just texts and calls.
                                    Access all your apps, share your pics, and
                                    stay online wherever you go. Premium data
                                    packages start at $19.99/month. No contract,
                                    no ads, no hassle.
                                </Label>
                            </Flex>
                        </Column>
                    </Flex>
                    {/* ---SEE-WHAT-TITLE------------------------------------------------------------------------------------------------------------------------------------*/}
                    <Column
                        xs={12}
                        md={12}
                        justify="start"
                        className="sim-page__section-margin sim-heading__column-margin"
                    >
                        <Flex className="sim-content-column-width">
                            <H1>
                                See what our
                                <br /> customers are saying
                            </H1>
                        </Flex>
                    </Column>
                    {/* ---SEE-WHAT-CONTENT----------------------------------------------------------------------------------------------------------------------------------*/}
                    <Flex
                        xs
                        direction="column"
                        directionMd="row"
                        className="sim-page__reviews-content"
                    >
                        <Column
                            xs={12}
                            md={6}
                            justify="center"
                            className="sim-testimonial-content__left-side-margin"
                        >
                            <Flex xs direction="column" alignItems="center">
                                <Flex
                                    xs
                                    className="sim-testimonial"
                                    marginBottom={4}
                                    direction="column"
                                >
                                    <Flex xs={false} lg className="review-icon">
                                        <Image
                                            src={reviewIcon1}
                                            alt="Review Icon 1"
                                            width="56px"
                                        />
                                    </Flex>
                                    <Flex
                                        xs
                                        className="sim-testimonial-content"
                                        direction="column"
                                    >
                                        <Label>
                                            &quot;I had cellular service
                                            everywhere I went. It has been
                                            amazing. I am so thankful to TextNow
                                            for offering this. It has helped me
                                            in so many ways words cant
                                            describe&quot;
                                        </Label>
                                        <Flex
                                            marginTop={3}
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <Flex xs lg={false} marginRight={2}>
                                                <Image
                                                    src={reviewIcon1}
                                                    alt="Review Icon 1"
                                                    width="40px"
                                                />
                                            </Flex>
                                            <Label size="subheading">
                                                Nicole C, Maryland
                                            </Label>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex
                                    xs={false}
                                    md
                                    className="sim-testimonial"
                                    direction="column"
                                >
                                    <Flex xs={false} lg className="review-icon">
                                        <Image
                                            src={reviewIcon3}
                                            alt="Review Icon 3"
                                            width="56px"
                                        />
                                    </Flex>
                                    <Label className="d-block sim-testimonial-content">
                                        &quot;I don't miss calls when I'm not
                                        connected to WiFi because I still have
                                        service .. I absolutely love it !!&quot;
                                    </Label>
                                    <Flex
                                        xs
                                        marginTop={3}
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Flex xs lg={false} marginRight={2}>
                                            <Image
                                                src={reviewIcon3}
                                                alt="Review Icon 3"
                                                width="40px"
                                            />
                                        </Flex>
                                        <Label size="subheading">
                                            Jazzmine M, Illinois
                                        </Label>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Column>
                        <Column
                            xs={12}
                            md={6}
                            justify="start"
                            className="sim-testimonial-content__right-side-margin"
                        >
                            <Flex xs direction="column" alignItems="center">
                                <Flex
                                    xs
                                    className="sim-testimonial"
                                    marginBottom={4}
                                    direction="column"
                                >
                                    <Flex xs={false} lg className="review-icon">
                                        <Image
                                            src={reviewIcon2}
                                            alt="Review Icon 2"
                                            width="56px"
                                        />
                                    </Flex>
                                    <Label className="d-block sim-testimonial-content">
                                        &quot;This is literally life changing
                                        because now I don't have to a pay
                                        monthly subscription and all I need is
                                        to call and text when I'm somewhere
                                        without WiFi.&quot;
                                    </Label>
                                    <Flex
                                        marginTop={3}
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Flex xs lg={false} marginRight={2}>
                                            <Image
                                                src={reviewIcon2}
                                                alt="Review Icon 2"
                                                width="40px"
                                            />
                                        </Flex>
                                        <Label size="subheading">
                                            Dipendra A, Ohio
                                        </Label>
                                    </Flex>
                                </Flex>
                                <Flex
                                    xs={false}
                                    md
                                    className="sim-testimonial"
                                    direction="column"
                                >
                                    <Flex xs={false} lg className="review-icon">
                                        <Image
                                            src={reviewIcon4}
                                            alt="Review Icon 4"
                                            width="56px"
                                        />
                                    </Flex>
                                    <Label className="d-block sim-testimonial-content">
                                        &quot;Even without being on WiFi I can
                                        at least still talk and text which no
                                        other app allows you to do so wonderful
                                        for TextNow to offer that&quot;
                                    </Label>
                                    <Flex
                                        marginTop={3}
                                        direction="row"
                                        alignItems="center"
                                    >
                                        <Flex xs lg={false} marginRight={2}>
                                            <Image
                                                src={reviewIcon4}
                                                alt="Review Icon 4"
                                                width="40px"
                                            />
                                        </Flex>
                                        <Label size="subheading">
                                            Christopher M, Texas
                                        </Label>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Column>
                    </Flex>
                    {/* ---FAQ-QUESTIONS-------------------------------------------------------------------------------------------------------------------------------------*/}
                    <Flex
                        xs
                        justify="center"
                        directionMd="row"
                        direction="column"
                        className="sim-page__faq sim-page__section-margin"
                    >
                        {/* DESKTOP - Left Column*/}
                        <Column
                            xs={12}
                            justify="start"
                            className="faq-heading__column-margin"
                        >
                            <Flex
                                xs
                                direction="column"
                                alignItems="start"
                                className="faq-content__flex-margin"
                            >
                                <Flex className="sim-content-column-width faq-heading__flex-margin">
                                    <H1>FAQs about bringing your own phone</H1>
                                </Flex>
                                <Label
                                    size="subheading"
                                    className="sim-page__faq-title"
                                >
                                    What can I expect if I buy a TextNow SIM
                                    Activation Kit?
                                </Label>
                                <Label className="sim-page__faq-content">
                                    Bringing your own device to TextNow is a
                                    cheap and easy option. If you want to save
                                    money on your cell phone bill but keep your
                                    current smartphone this is could be for you.
                                    Order a SIM Activation Kit to get phone
                                    service for free or unlimited data starting
                                    at only 19.99/mo.
                                </Label>
                                <Label
                                    size="subheading"
                                    className="sim-page__faq-title"
                                >
                                    How does the BYOP program work?
                                </Label>
                                <Label className="sim-page__faq-content">
                                    We’ll ship your SIM card to you with free
                                    shipping. Once your SIM arrives, put it in
                                    your device and follow our simple activation
                                    guide. You will be up and running in a
                                    matter of minutes.
                                </Label>
                                <Label
                                    size="subheading"
                                    className="sim-page__faq-title"
                                >
                                    Is my phone eligible?
                                </Label>
                                <Label>
                                    Must be a compatible device with either
                                    Android or iOS operating system. Check
                                    compatibility using our{' '}
                                    <Link
                                        href="/wireless/device-checker"
                                        label="device checker"
                                        font-size={16}
                                    />
                                    . Devices may need to be unlocked to
                                    function.
                                </Label>
                            </Flex>
                        </Column>
                    </Flex>
                    <Flex
                        xs
                        justify="center"
                        directionMd="row"
                        direction="column"
                        className="sim-page__faq sim-page__section-margin"
                    >
                        {/* DESKTOP - Left Column*/}
                        <Column
                            xs={12}
                            justify="start"
                            className="faq-heading__column-margin"
                        >
                            <Flex
                                xs
                                direction="column"
                                alignItems="start"
                                className="faq-content__flex-margin"
                            >
                                <Label
                                    size="micro"
                                    appearance="muted"
                                    className="sim-page__faq-title"
                                >
                                    TextNow's wireless network services are
                                    provided using the wireless network of a
                                    leading national mobile service provider*
                                </Label>
                            </Flex>
                        </Column>
                    </Flex>
                </Container>
            </Flex>
        );
    }
}

export default SimCardContent;
