import * as React from 'react';
import { Label, Link } from '@tn/retune';

/* eslint-disable max-len */

const FAQContent = [
    {
        title: 'How can your service start at 0$? What’s the catch?',
        body: (
            <Label>
                This service is paid for by unobtrusive ads, so there is no
                subscription and no billing information required! And you can
                connect to the TextNow's Nationwide Wireless Network* for phone
                service away from Wi-Fi. Customers with{' '}
                <Link
                    href="/wireless/device-checker"
                    label="eligible devices"
                    size="regular"
                />{' '}
                can get our Free Nationwide Talk & Text service, free of charge
                by just downloading the TextNow app and ordering a{' '}
                <Link
                    href="/wireless/sim-card"
                    label="TextNow SIM Card"
                    size="regular"
                />{' '}
                for their device.
            </Label>
        ),
    },
    {
        title: 'Where can I get coverage?',
        body: (
            <Label>
                With TextNow, you will have coverage through Wi-Fi and TextNow's
                Nationwide Wireless Network. To see if TextNow is a good fit,
                you can view our{' '}
                <Link
                    href="/wireless/coverage"
                    label="network coverage map"
                    size="regular"
                />
                .
            </Label>
        ),
    },
    {
        title: 'Can I use my existing phone with TextNow?',
        body: (
            <Label>
                Yes! You can bring any eligible device to TextNow, and activate
                it with a TextNow service plan. You can confirm your device's
                compatibility with our handy{' '}
                <Link
                    href="/wireless/device-checker"
                    label="device checker"
                    size="regular"
                />
                . If you have a compatible phone that's unlocked you'll just
                need a{' '}
                <Link
                    href="/wireless/sim-card"
                    label="TextNow SIM Card"
                    size="regular"
                />{' '}
                which we have available for purchase.
            </Label>
        ),
    },
];

/* eslint-enable max-len */

export default FAQContent;
