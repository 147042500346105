import { inferQueryString } from '@tn/modules/dist/product-set/helpers';

export default function(data: {
    purchaseType?: string;
    planId?: number;
    productId?: number;
    productSetId?: number;
    utmCampaign?: string;
    action?: string;
    ESN?: string;
}): string {
    const queryMap = inferQueryString();

    if (data.action) {
        queryMap.action = data.action;
    }

    if (data.purchaseType === 'purchaseForNewUser') {
        queryMap.purchaseType = data.purchaseType;
    }

    if (data.purchaseType === 'upgradeOrder') {
        queryMap.purchaseType = data.purchaseType;
    }

    if (
        typeof data.productId !== 'undefined' &&
        typeof data.productSetId !== 'undefined'
    ) {
        queryMap.product_id = data.productId;
        queryMap.product_set_id = data.productSetId;
    }

    if (typeof data.planId !== 'undefined') {
        queryMap.plan_id = data.planId;
    }

    if (data.ESN) {
        queryMap.ESN = data.ESN;
    }

    const queryList: string[] = [];
    for (const key in queryMap) {
        if (queryMap.hasOwnProperty(key)) {
            queryList.push(`${key}=${queryMap[key]}`);
        }
    }
    const queryString = queryList.join('&');

    return `/wireless/checkout?${queryString}`;
}
