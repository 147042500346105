// import { ApiReducerRecord } from '@tn/types/dist/generic';

import * as types from 'containers/App/modules/sim-assignment/constants';
import {
    SimAssignment,
    SimAssignmentStoreRecord,
    SimAssignmentRecord,
} from 'containers/App/modules/sim-assignment/types';
import { Action, ActionWithError, SimpleAction } from '@tn/types/dist/generic';

// REDUCER
export function reducer(
    state: SimAssignmentStoreRecord = new SimAssignmentStoreRecord(),
    action: Action<SimAssignment> | ActionWithError | SimpleAction
) {
    switch (action.type) {
        case types.LOAD_SIM_ASSIGN_REQUESTED:
            return state.set('loading', true);
        case types.LOAD_SIM_ASSIGN_SUCCEEDED:
            return state
                .set('loading', false)
                .set(
                    'result',
                    new SimAssignmentRecord(
                        (action as Action<SimAssignment>).payload.result
                    )
                )
                .set('error', undefined);
        case types.LOAD_SIM_ASSIGN_FAILED:
            return state
                .set('loading', false)
                .set('error', (action as ActionWithError).error)
                .set('result', new SimAssignmentRecord());
        case types.CLEAR_SIM_ASSIGN_STATE:
            return new SimAssignmentStoreRecord();
        default:
            return state;
    }
}
