import * as React from 'react';
import { Label } from '@tn/retune';

import './index.scss';

class TileImageOverlay extends React.PureComponent<Props> {
    getVisibility() {
        let className = '';

        if (this.props.sm === false) {
            className += ' tile-image-overlay-sm--none';
        } else if (this.props.sm === true) {
            className += ' tile-image-overlay-sm';
        }

        if (this.props.md === false) {
            className += ' tile-image-overlay-md--none';
        } else if (this.props.md === true) {
            className += ' tile-image-overlay-md';
        }

        return className;
    }

    render() {
        const className = `tile-image-overlay ${this.getVisibility()}`;

        return (
            <div className={className}>
                <div className="tile-image-overlay__container">
                    <Label size="regular">{this.props.label}</Label>
                </div>
            </div>
        );
    }
}

export interface Props {
    /**
     * text
     */
    label?: string;

    /**
     * visibility for mobile devices
     */
    sm?: boolean;

    /**
     * visibility for medium devices
     */
    md?: boolean;
}

export default TileImageOverlay;
