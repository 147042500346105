import * as React from 'react';
import { Column, Flex, Image } from '@tn/retune';

import './index.scss';

class TileImage extends React.PureComponent<Props> {
    render() {
        let className = `tile__image`;

        if (this.props.disabled) {
            className += ' tile__image--disabled';
        }

        const imageSrc = `${this.props.src}?size=product-list-d&background=ffffff`;
        const imagePlaceholderSrc = `${imageSrc}&quality=low`;

        const imageSrcMobile = `${this.props.src}?size=product-list-m&background=ffffff`;
        const imagePlaceholderSrcMobile = `${imageSrcMobile}&quality=low`;

        const body = (
            <div className={className}>
                <Image
                    src={imageSrc}
                    src2x
                    alt={this.props.alt}
                    placeholderSrc={imagePlaceholderSrc}
                    lazyLoad
                    xs={false}
                    sm={false}
                    md={false}
                    lg
                />

                <Image
                    src={imageSrcMobile}
                    src2x
                    alt={this.props.alt}
                    placeholderSrc={imagePlaceholderSrcMobile}
                    lazyLoad
                    xs
                    lg={false}
                />

                {this.props.overlay}
            </div>
        );

        return (
            <>
                <Column xs={false} sm={4} lg={6}>
                    <Flex direction="column">{body}</Flex>
                </Column>

                <Flex xs sm={false} className="flex-fill" />
                <Flex xs sm={false}>
                    {body}
                </Flex>
            </>
        );
    }
}

export interface Props {
    /**
     * image src
     */
    src: string;

    /**
     * progressive placeholder image src
     */
    placeholderSrc?: string;

    /**
     * image alt
     */
    alt: string;

    /**
     * disabled style
     */
    disabled?: boolean;

    /**
     * show an overlay component
     */
    overlay?: React.ReactNode;
}

export default TileImage;
