import * as React from 'react';
import { Responsive, Size } from '@tn/retune/build/lib/components/types';
import { Flex, Label } from '@tn/retune';
import PriceLabel from 'components/PriceLabel';

import '../index.scss';
import './index.scss';

class PlanModalTileLandscape extends React.PureComponent<Props> {
    renderPlanDescription() {
        return (
            this.props.category !== 'TALK_TEXT' && (
                <Flex xs marginTop={2} key={2} fluid>
                    <Label appearance="muted" size="micro">
                        Hot-spotting available for LTE devices on the CDMA
                        network
                    </Label>
                </Flex>
            )
        );
    }

    render() {
        let price = this.props.price;
        let disclaimer;
        let className;

        if (this.props.price !== this.props.promo_price) {
            price = this.props.promo_price;
            className = 'plans-modal--promo';
            disclaimer = (
                <Flex className="plans-modal--promo" marginTop={3}>
                    <Label
                        size="micro"
                        values={{ value: this.props.price / 100 }}
                    >{`*Promo price for the first month then \${value} a month.`}</Label>
                </Flex>
            );
        }

        return (
            <Flex
                className="plans-modal__tile plans-modal__tile--mobile"
                xs
                fluid
                justify="center"
                alignContent="start"
                direction="column"
                marginBottom={3}
            >
                <Flex xs direction="row">
                    <Flex fluid>
                        <Label size="subheading">{this.props.name}</Label>
                        {this.props.category === 'UNLIMITED' && (
                            <Label size="regular" noTranslate>
                                †
                            </Label>
                        )}
                    </Flex>
                    <Flex marginLeft={4}>
                        <PriceLabel
                            size="subheading"
                            className={className}
                            amount={price / 100}
                            subText={disclaimer ? '/mo*' : '/mo'}
                        />
                    </Flex>
                </Flex>

                <Flex xs fluid justify="center" direction="column">
                    {this.renderPlanDescription()}
                    {disclaimer}
                </Flex>
            </Flex>
        );
    }
}

export interface Props extends Size, Responsive<number | boolean> {
    category: string;
    name: string;
    price: number;
    promo_price: number;
}

export default PlanModalTileLandscape;
