import * as React from 'react';

import { Button, Label, Row, Column } from '@tn/retune';
import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';

import TileContent from 'components/TileContent';
import PriceLabel from 'components/PriceLabel';

export default class SimCard extends React.PureComponent<Props> {
    render() {
        return (
            <TileContent
                title={[
                    [
                        <Row key={1}>
                            <Column md={false} sm={false} xs={false} lg>
                                <Label size="heading2">SIM</Label>
                            </Column>
                        </Row>,
                        <Row key={2}>
                            <Column md={false} sm={false} xs={false} lg>
                                <Label size="heading2">Activation Kit</Label>
                            </Column>
                        </Row>,
                    ],
                    [
                        <Row key={3}>
                            <Column md lg={false}>
                                <Label size="regular">Bring your</Label>
                            </Column>
                        </Row>,
                        <Row key={4}>
                            <Column md lg={false}>
                                <Label size="regular">own phone</Label>
                            </Column>
                        </Row>,
                    ],
                ]}
                productSetId={this.props.productSet.id}
                subTitle={
                    <Label size="small">Free Nationwide Talk & Text</Label>
                }
                detail={
                    <PriceLabel
                        amount={this.props.productSet.min_sale_price}
                        size="big"
                    />
                }
                footer={<Button label="DETAILS" />}
            />
        );
    }
}

interface Props {
    productSet: ProductSetRecord;
}
