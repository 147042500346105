import * as React from 'react';
import { Responsive, Size } from '@tn/retune/build/lib/components/types';
import { Flex, Label } from '@tn/retune';
import PriceLabel from 'components/PriceLabel';

import '../index.scss';
import './index.scss';

class PlanModalTilePortrait extends React.PureComponent<Props> {
    renderPlanDescription() {
        return [
            <Flex
                xs
                marginTop={3}
                key={1}
                justify="center"
                alignContent="center"
            >
                <Label appearance="muted" size="micro" centered>
                    <Label size="micro" className="label--strong">
                        Unlimited
                    </Label>{' '}
                    Talk and Text to US & Canada
                </Label>
            </Flex>,
            this.props.category !== 'TALK_TEXT' && (
                <Flex
                    xs
                    marginTop={2}
                    key={2}
                    direction="column"
                    justify="center"
                    alignContent="center"
                >
                    <Label
                        appearance="muted"
                        size="micro"
                        className="label--strong"
                        centered
                    >
                        Data hot-spotting
                    </Label>
                    <Label appearance="muted" size="micro" centered>
                        Available for LTE devices on the CDMA network
                    </Label>
                </Flex>
            ),
        ];
    }

    render() {
        let price = this.props.price;
        let disclaimer;
        let className;

        if (this.props.price !== this.props.promo_price) {
            price = this.props.promo_price;
            className = 'plans-modal--promo';
            disclaimer = (
                <Label
                    size="micro"
                    centered
                    values={{ value: this.props.price / 100 }}
                >{`*Promo price for the first month then \${value} a month.`}</Label>
            );
        }

        return (
            <Flex
                className="plans-modal__tile plans-modal__tile--desktop"
                xs
                fluid
                direction="column"
            >
                <Flex xs className="plans-modal__tile__name" justify="center">
                    <Label size="subheading" centered>
                        {this.props.name}
                    </Label>
                    {this.props.category === 'UNLIMITED' && (
                        <Label size="regular" noTranslate>
                            †
                        </Label>
                    )}
                </Flex>

                <Flex
                    xs
                    direction="column"
                    className="plans-modal__tile__description"
                    justify="start"
                    alignContent="center"
                    marginBottom={3}
                >
                    {this.renderPlanDescription()}
                </Flex>

                <PriceLabel
                    justify="center"
                    className={className}
                    amount={price / 100}
                    size="big"
                    supText={disclaimer ? '*' : ''}
                    subText="/mo"
                    disclaimer={disclaimer}
                />
            </Flex>
        );
    }
}

export interface Props extends Size, Responsive<number | boolean> {
    category: string;
    name: string;
    price: number;
    promo_price: number;
}

export default PlanModalTilePortrait;
