import * as React from 'react';
import { connect } from 'react-redux';
import { Action, compose } from 'redux';
import { initialize } from 'redux-form';
import { getFormValues } from 'redux-form/immutable';

import * as productSetSelectors from '@tn/modules/dist/product-set/selectors';
import * as productsSelectors from '@tn/modules/dist/product-set-list/selectors';
import * as productSetsActions from '@tn/modules/dist/product-set-list/actions';
import ProductSetTile from 'containers/WirelessPage/components/ProductSetTile';

import { List, Map } from 'immutable';
import { Container, Row } from '@tn/retune';
import {
    isFiltered,
    convertUrlToFilter,
} from '@tn/modules/dist/product-set-list-filters/util';
import * as _window from '@tn/window';

export class ProductSetTileList extends React.PureComponent<Props> {
    componentDidMount() {
        this.props.loadProductSets();

        if (this.props.location) {
            // use filter like:
            // https://dev.textnow.com/wireless?filter=min_price__0-100;brand__lg;brand__apple
            const filterString = _window.getQueryVariable(
                'filter',
                this.props.location.search
            );

            if (filterString) {
                const filterValues = convertUrlToFilter(filterString);
                this.props.initialize(filterValues);
            }
        }
    }

    render() {
        return (
            this.props.productSets && (
                <div id="devices-store">
                    {' '}
                    {/** dont remove this id. Our ads are using this id */}
                    <Container key="products">
                        <Row marginBottom={5}>
                            {this.props.productSets.map(
                                (productSetId: number, key: number) => {
                                    return (
                                        <ProductSetTile
                                            key={`${productSetId}-${key}`}
                                            productSetId={productSetId.toString()}
                                        />
                                    );
                                }
                            )}
                        </Row>
                    </Container>
                </div>
            )
        );
    }
}

interface Props {
    location: {
        search?: string;
    };
    loadProductSets: () => void;
    initialize: (values: { [key: string]: {} }) => void;
    productSets: List<number>;
}

const makeMapStateToProps = () => {
    const getProductSet = productSetSelectors.productSet;
    const productSets = productsSelectors.productSets();

    const newMapStateToProps = (state) => {
        let filterValues: Map<string, string | {}> =
            (getFormValues('wirelessFilterForm')(state) as Map<
                string,
                string | {}
            >) || Map();

        // add filter to web_store_enabled by default
        filterValues = filterValues.merge(
            Map({
                web_store_enabled: Map({
                    web_store_enabled: true,
                }),
            })
        );

        return {
            productSets: productSets(state).filter((productSetId) => {
                // i need to get each product set to be able to filter
                const productSet = getProductSet(
                    state,
                    productSetId.toString()
                );

                if (!filterValues) {
                    return true;
                }

                return isFiltered(productSet, filterValues);
            }),
        };
    };
    return newMapStateToProps;
};

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        loadProductSets: () => dispatch(productSetsActions.loadProductSets()),
        initialize: (values: { [key: string]: {} }) =>
            dispatch(initialize('wirelessFilterForm', values)),
    };
}

const withConnect = connect(makeMapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProductSetTileList);
