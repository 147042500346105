import * as React from 'react';
import {
    SHIPPING,
    MONEY_BACK,
    WARRANTY,
    NATIONWIDE,
} from 'containers/App/modules/guaratee/constants';
import IconList from 'containers/App/components/IconList';
import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';
import { Column, Container, FAB, Flex, Label, Link, Row } from '@tn/retune';

import FAQ from 'containers/App/components/FAQ';

import './index.scss';
import TechReviewBanner from 'containers/DetailPage/components/TechReviewBanner';
import Specs from 'containers/DetailPage/components/DeviceContent/Specs';
import LifeStyle from 'containers/DetailPage/components/DeviceContent/LifeStyle';
import FeaturedImages from 'containers/DetailPage/components/DeviceContent/FeaturedImages';

export class DeviceContent extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            specsOpen: false,
        };
    }

    onToggleSpecs = () => {
        this.setState({
            specsOpen: !this.state.specsOpen,
        });
    };

    renderTechReview() {
        const currentProduct = this.props.productSet.products[0];
        const techReviewImage = currentProduct.product_images.find(
            (image) => image.description === 'tech_review_logo'
        );
        const techReviewText = currentProduct.product_specifications.find(
            (spec) => spec.title === 'tech_review_text'
        );

        // this product doesn't have tech reviews
        if (!techReviewText || !techReviewImage) {
            return null;
        }

        return (
            <TechReviewBanner
                logo={techReviewImage.img_url}
                message={techReviewText.description}
            />
        );
    }

    render() {
        const currentProduct = this.props.productSet.products[0];

        if (!currentProduct) {
            return null;
        }

        const techReview = this.renderTechReview();

        return (
            <>
                <Flex marginTop={2} marginBottom={techReview ? 0 : 2}>
                    <IconList
                        centered
                        size="big"
                        appearance="alternate"
                        key=""
                        iconItemList={[
                            SHIPPING,
                            MONEY_BACK,
                            WARRANTY,
                            NATIONWIDE,
                        ]}
                    />
                </Flex>

                {techReview}

                <FeaturedImages currentProduct={currentProduct} />

                <Flex
                    justify="center"
                    xs
                    className="device-content__specs"
                    marginTop={5}
                    automationId="specs"
                >
                    <Flex
                        className="device-content__specs__btn-container"
                        xs
                        direction="column"
                        alignItems="center"
                    >
                        <Flex marginBottom={3}>
                            <Link
                                onClick={this.onToggleSpecs}
                                appearance="primary"
                                size="small"
                                bold
                            >
                                {this.state.specsOpen
                                    ? 'HIDE PHONE SPECS'
                                    : 'VIEW PHONE SPECS'}
                            </Link>
                        </Flex>

                        <FAB
                            icon="chevron-up"
                            className={`device-content__specs__btn ${
                                this.state.specsOpen
                                    ? 'device-content__specs__btn--opened'
                                    : 'content__specs__btn--closed'
                            }`}
                            onClick={this.onToggleSpecs}
                        />
                    </Flex>

                    <Specs
                        currentProduct={currentProduct}
                        isOpen={this.state.specsOpen}
                    />
                </Flex>

                <LifeStyle
                    currentProduct={currentProduct}
                    isOpen={this.state.specsOpen}
                />

                <Flex fluid marginTop={5} marginBottom={5}>
                    <Container>
                        <Row>
                            <Column>
                                <FAQ
                                    branded
                                    type="device-detail"
                                    title="Frequently asked questions"
                                    limit={5}
                                />
                                <Label size="micro" appearance="muted">
                                    TextNow's wireless network services are
                                    provided using the wireless network of a
                                    leading national mobile service provider*
                                </Label>
                            </Column>
                        </Row>
                    </Container>
                </Flex>
            </>
        );
    }
}

export interface Props {
    productSet: ProductSetRecord;
}

export interface State {
    specsOpen: boolean;
}

export default DeviceContent;
