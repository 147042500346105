import * as React from 'react';
import { Button, Flex } from '@tn/retune';
import PaypalSnippet from '../Paypal/snippet.html';

import './index.scss';

export default class BuyNow extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isHidden: false,
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);
    }

    handleScroll = () => {
        const content = (window.document.getElementById(
            'device-content'
        ) as Element).getBoundingClientRect();

        if (content.top - 120 <= 0) {
            this.setState({
                isHidden: true,
            });
        } else {
            this.setState({
                isHidden: false,
            });
        }
    };

    render() {
        return (
            <>
                <Flex
                    direction="row"
                    xs={false}
                    md
                    alignItems="center"
                    padding={this.state.isHidden ? [2] : undefined}
                    className={`product-set__buy-now ${
                        this.state.isHidden ? 'product-set__buy-now--fixed' : ''
                    }`}
                >
                    <Flex xs={false} md>
                        <Button
                            automationId="buyNow"
                            onClick={this.props.onBuy}
                            wide
                            label="BUY NOW"
                            icon="chevron-right"
                        />
                    </Flex>

                    <Flex
                        xs={!this.state.isHidden}
                        alignItems="center"
                        marginLeft={4}
                        className="product-set__paypal-wrapper"
                    >
                        <div
                            dangerouslySetInnerHTML={{ __html: PaypalSnippet }}
                        />
                    </Flex>
                </Flex>
                <Flex
                    direction="column"
                    xs
                    md={false}
                    alignItems="center"
                    padding={this.state.isHidden ? [2] : undefined}
                    className={`product-set__buy-now ${
                        this.state.isHidden ? 'product-set__buy-now--fixed' : ''
                    }`}
                >
                    <Button
                        automationId="buyNow-mobile"
                        onClick={this.props.onBuy}
                        wide
                        label="BUY NOW"
                        icon="chevron-right"
                    />
                    <Flex
                        direction="column"
                        xs={!this.state.isHidden}
                        alignItems="center"
                        className="product-set__paypal-wrapper"
                    >
                        <div
                            dangerouslySetInnerHTML={{ __html: PaypalSnippet }}
                        />
                        <Flex sm md={false} marginBottom={4} />
                    </Flex>
                </Flex>
            </>
        );
    }
}

export interface Props {
    onBuy: () => void;
}

export interface State {
    isHidden: boolean;
}
