import * as React from 'react';
import { createStructuredSelector } from 'reselect';
import { Action, compose } from 'redux';
import { connect } from 'react-redux';

import { Modal, H2, Flex, Button, Label } from '@tn/retune';

import { isModalOpened } from '@tn/modules/dist/modals/selectors';
import { MODALS } from '@tn/modules/dist/modals/types';
import { toggleModal } from '@tn/modules/dist/modals/actions';

import './index.scss';

export class PhoneCompatibilityModalDeviceChecker extends React.PureComponent<
    Props
> {
    toggle = () => this.props.toggleModal();

    render() {
        return (
            <Modal
                className="phone-compatibility-modal-device-checker"
                isOpen={this.props.isModalOpened}
                toggle={this.toggle}
            >
                <Flex direction="column" justify="center">
                    <Flex md xs={false} justify="center">
                        <H2>Find your IMEI in your settings.</H2>
                    </Flex>
                    <Flex
                        xs
                        justify="center"
                        marginBottom={4}
                        className="instruction-container"
                    >
                        <Flex className="iphone-instructions">
                            <Flex className="phone-compatibility-modal_heading-os">
                                iPhone
                            </Flex>
                            Go to <Label bold>Settings</Label>, then{' '}
                            <Label bold>General</Label>, then{' '}
                            <Label bold>About</Label>.
                        </Flex>
                        <Flex className="android-instructions">
                            <Flex className="phone-compatibility-modal_heading-os">
                                Android
                            </Flex>
                            Go to <Label bold>Settings</Label>, then{' '}
                            <Label bold>About Phone</Label>, then{' '}
                            <Label bold>Status</Label>
                        </Flex>
                    </Flex>
                    <Button
                        className={'phone-compatibility-modal__button'}
                        onClick={this.toggle}
                        label={'Got it'}
                    />
                </Flex>
            </Modal>
        );
    }
}

export interface State {
    width: number;
}

interface Props {
    isModalOpened: boolean;
    toggleModal: () => void;
}

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        toggleModal: () => dispatch(toggleModal(MODALS.PHONE_COMPATIBILITY)),
    };
}

const mapStateToProps = createStructuredSelector({
    isModalOpened: isModalOpened(MODALS.PHONE_COMPATIBILITY),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PhoneCompatibilityModalDeviceChecker);
