import * as React from 'react';

import './index.scss';
import { Flex, Label } from '@tn/retune';

export default class OutOfStock extends React.PureComponent {
    render() {
        return (
            <Flex
                automationId="outOfStock"
                xs
                direction="column"
                className="product--out-of-stock"
                marginTop={3}
                padding={[3]}
            >
                <Label size="small" bold>
                    Out of Stock
                </Label>

                <Flex marginTop={2}>
                    <Label size="small" appearance="muted">
                        This phone is currently unavailable
                    </Label>
                </Flex>
            </Flex>
        );
    }
}
