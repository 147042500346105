import * as React from 'react';
import { Flex, Label } from '@tn/retune';
import { Product } from '@tn/types/dist/domain/Product';

import './index.scss';

export interface Props {
    isOpen: boolean;
    currentProduct: Product;
}

export default class Specs extends React.PureComponent<Props> {
    renderItems() {
        const specs = this.props.currentProduct.product_specifications.filter(
            (spec) => spec.title !== 'tech_review_text'
        );

        // add model
        specs.push({
            title: 'Model',
            description: this.props.currentProduct.model,
        });

        return specs.map((spec, idx) => (
            <Flex
                className="device-content-specs__item"
                justify="between"
                key={idx}
                wrap="wrap"
                paddingTop={3}
                paddingBottom={3}
                direction="row"
                marginRight={3}
            >
                <Flex
                    xs
                    marginRight={2}
                    className="device-content-specs__title"
                >
                    <Label size="small" bold>
                        {spec.title}
                    </Label>
                </Flex>
                <Flex
                    grow={1}
                    className="device-content-specs_description"
                    xs
                    justify="end"
                >
                    <Label size="micro" noTranslate>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: spec.description,
                            }}
                        />
                    </Label>
                </Flex>
            </Flex>
        ));
    }

    render() {
        return (
            <Flex
                className={`device-content-specs ${
                    this.props.isOpen ? 'device-content-specs--opened' : ''
                }`}
                xs
                marginLeft={4}
                justify="center"
                alignItems="center"
            >
                <Flex
                    paddingTop={5}
                    fluid
                    direction="row"
                    wrap="wrap"
                    alignSelf="center"
                    className="device-content-specs__container"
                >
                    {this.renderItems()}
                </Flex>
            </Flex>
        );
    }
}
