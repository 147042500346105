// CONSTANTS
export const LOAD_SIM_ASSIGN = 'app/LOAD_SIM_ASSIGN';
export const LOAD_SIM_ASSIGN_REQUESTED = 'app/LOAD_SIM_ASSIGN_REQUESTED';
export const LOAD_SIM_ASSIGN_SUCCEEDED = 'app/LOAD_SIM_ASSIGN_SUCCEEDED';
export const LOAD_SIM_ASSIGN_FAILED = 'app/LOAD_SIM_ASSIGN_FAILED';
export const CLEAR_SIM_ASSIGN_STATE = 'app/CLEAR_SIM_ASSIGN_STATE';

// Options for extra info
export const UNLOCKED_PARAM_REQUIRED = 'parameter_unlocked_required';

// Validation Results
export const VALIDATION_DEVICE_NOT_ELIGIBLE = 'VALIDATION_DEVICE_NOT_ELIGIBLE';
export const VALIDATION_DEVICE_NOT_YET_SUPPORTED =
    'VALIDATION_DEVICE_NOT_YET_SUPPORTED';
export const VALIDATION_SIM_REQUIRED = 'VALIDATION_SIM_REQUIRED';
export const VALIDATION_DEVICE_VALID_FOR_ACTIVATION =
    'VALIDATION_DEVICE_VALID_FOR_ACTIVATION';

// Errors
export const DEVICE_NOT_SUPPORTED = 'DEVICE_NOT_SUPPORTED';
export const DEVICE_ID_INVALID = 'DEVICE_ID_INVALID';
export const DEVICE_ID_REQUIRED = 'DEVICE_ID_REQUIRED';
export const ICCID_INVALID = 'ICCID_INVALID';
export const INTERNAL_FAILURE = 'INTERNAL_FAILURE';
