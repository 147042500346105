import { Action } from 'redux';
import { Record } from 'immutable';
import {
    ApiErrorRecord,
    ApiReducerRecord,
    ApiResponse,
} from '@tn/types/dist/generic';

export interface SimAssignment {
    validation_result?: string | null;
    carrier: string;
    sim_product_id: number;
    supports_voice: boolean;
    supported_plans: Array<number>;
}

export const SimAssignmentDefaults: SimAssignment = {
    validation_result: null,
    carrier: '',
    sim_product_id: -1,
    supports_voice: false,
    supported_plans: [],
};

export class SimAssignmentRecord extends Record(SimAssignmentDefaults) {
    constructor(params?: SimAssignment) {
        params ? super(params) : super();
    }

    isEmpty() {
        // If the record is equal to the defaults we know that the record is empty
        if (
            this.validation_result ===
                SimAssignmentDefaults.validation_result &&
            this.carrier === SimAssignmentDefaults.carrier &&
            this.sim_product_id === SimAssignmentDefaults.sim_product_id &&
            this.supports_voice === SimAssignmentDefaults.supports_voice &&
            this.supported_plans === SimAssignmentDefaults.supported_plans
        ) {
            return true;
        }

        return false;
    }
}

const SimAssignmentStoreRecordDefaults: ApiReducerRecord<SimAssignmentRecord> = {
    loading: false,
    result: new SimAssignmentRecord(),
    error: new ApiErrorRecord(),
};

export class SimAssignmentStoreRecord extends Record(
    SimAssignmentStoreRecordDefaults
) {
    constructor(params?: ApiReducerRecord<SimAssignmentRecord>) {
        params ? super(params) : super();
    }
}

export interface SimAssignmentActionWithResponse extends Action {
    esn?: string;
    locked?: string;
}

export type SimAssignmentApiResponse = ApiResponse<SimAssignment>;
