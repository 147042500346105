// SAGAS
import { call, put, takeLatest } from 'redux-saga/effects';

import Analytics from '@tn/analytics';
import { ApiError } from '@tn/types/dist/generic';

import * as api from 'containers/App/modules/sim-assignment/api';
import * as actions from 'containers/App/modules/sim-assignment/actions';
import { LOAD_SIM_ASSIGN } from 'containers/App/modules/sim-assignment/constants';
import {
    SimAssignmentActionWithResponse,
    SimAssignmentApiResponse,
} from 'containers/App/modules/sim-assignment/types';

export function* onLoadSimAssignment(action: SimAssignmentActionWithResponse) {
    yield put(actions.loadSimAssignmentRequested());

    const response: SimAssignmentApiResponse | ApiError = yield call(
        api.loadSimAssignment,
        action.esn,
        action.locked
    );

    if ((response as SimAssignmentApiResponse) && response.result) {
        const typedResponse = response as SimAssignmentApiResponse;
        if (typedResponse.result.sim_product_id) {
            // const label = `${typedResponse.sim_product_id}`;
            // Analytics.track('store', 'sim_compatibility_check', label);
        } else {
            // const label = `ineligible_${typedResponse.validation_result}`;
            // Analytics.track('store', 'sim_compatibility_check', label);
        }

        yield put(actions.loadSimAssignmentSucceeded(typedResponse));
    } else {
        const errorResponse = response as ApiError;
        Analytics.track('store', 'sim_compatibility_check', 'error');
        yield put(actions.loadSimAssignmentFailed(errorResponse));
    }
}

export function* saga() {
    yield takeLatest(LOAD_SIM_ASSIGN, onLoadSimAssignment);
}
