import * as React from 'react';
import { connect } from 'react-redux';
import { Action, compose } from 'redux';

import { Flex, Image, Label, Link } from '@tn/retune';

import StockIcon from './images/ico-stock-level.png';
import CloseIcon from './images/ico-small-close.png';

import './index.scss';
import { createStructuredSelector } from 'reselect';
import { productSetStock } from '@tn/modules/dist/product-stock/selectors';
import * as productSetStockActions from '@tn/modules/dist/product-stock/actions';

export class DevicePopOuts extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpened: false,
        };
    }

    onUpdateState = (e: React.MouseEvent<HTMLElement>) => {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            isOpened: !this.state.isOpened,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.productId !== prevProps.productId) {
            this.loadProductSetProductStock(this.props.productId);
        }
    }

    loadProductSetProductStock = (productId: string) => {
        this.props.loadProductSetProductStock(productId);
        setTimeout(this.onUpdateState, 5000);
    };

    componentDidMount() {
        if (this.props.productId) {
            this.loadProductSetProductStock(this.props.productId);
        }
    }

    render() {
        if (this.props.qnt === 0 || this.props.qnt > 25) {
            return null;
        }

        return (
            <Flex
                className={`pop-out ${this.state.isOpened ? 'opened' : ''}`}
                xs
                direction="row"
                padding={[3]}
                justify="center"
            >
                <Image src={StockIcon} alt="Stock" />
                <Flex xs fluid marginLeft={3} alignItems="center">
                    <Label
                        size="small"
                        values={{ qnt: this.props.qnt }}
                    >{`Only {qnt} left in stock.`}</Label>
                </Flex>
                <Flex xs alignItems="end">
                    <Link
                        className="pop-out__close"
                        onClick={this.onUpdateState}
                    >
                        <Image src={CloseIcon} alt="Stock" />
                    </Link>
                </Flex>
            </Flex>
        );
    }
}

export interface State {
    isOpened: boolean;
}

export interface Props {
    qnt: number;
    productId: string;
    loadProductSetProductStock: (productId: string) => void;
}

const mapStateToProps = createStructuredSelector({
    qnt: productSetStock,
});

export function mapDispatchToProps(dispatch: (action: Action) => void) {
    return {
        loadProductSetProductStock: (productId: string) =>
            dispatch(
                productSetStockActions.loadProductSetProductStock(productId)
            ),
    };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DevicePopOuts);
