import * as React from 'react';

import { ProductSetRecord } from '@tn/types/dist/domain/ProductSet/Record';
import { Link, Button, Label, Flex, ConfirmModal, ReadMore } from '@tn/retune';
import UsedModal from 'containers/DetailPage/components/ProductSetContent/UsedModal';
import * as Window from '@tn/window';

import DeliveryPromise from 'containers/DetailPage/components/DeliveryPromise';
import { List } from 'immutable';
import { PlanRecord } from '@tn/modules/dist/plans/types';
import ProductSetHeader from 'containers/DetailPage/components/ProductSetContent/ProductSetHeader';
import './index.scss';

import PlansSelector from 'containers/DetailPage/components/ProductSetContent/PlansSelector';
import ProductSelector from 'containers/DetailPage/components/ProductSetContent/ProductSelector';
import BuyNow from 'containers/DetailPage/components/ProductSetContent/BuyNow';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as sessionSelectors from '@tn/modules/dist/session/selectors';

export class PurchaseDetails extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isOpenConfirmUpgrade: false,
            planOption: 'upgrade',
            toggleUsedModal: false,
        };
    }

    getValidCurrentPlan() {
        const findPlan = this.props.plans.find(
            (plan) => plan.stripe_id === this.props.currentPlan
        );

        // checks if the plan i'm trying to set is valid, if not, selects the first on the list
        if (findPlan) {
            return findPlan.stripe_id;
        } else {
            return this.props.plans.getIn([0, 'stripe_id']);
        }
    }

    onToggleConfirmUpgrade = () => {
        this.setState({
            isOpenConfirmUpgrade: !this.state.isOpenConfirmUpgrade,
        });
    };

    onTogglePlanOption = (event) => {
        this.setState({
            planOption: event.target.value,
        });
    };

    /**
     * If the phone is used/gently, then it toggles the UsedModal, and if not, it proceeds with the checkout based on the purchaseType
     */
    middleChecker = () => {
        const currentProduct =
            this.props.productSet.products.find(
                (product) => product.id === this.getCurrentProduct().id
            ) || this.props.productSet.products[0];
        if (
            currentProduct.webstore_quality.indexOf('Used') >= 0 ||
            currentProduct.webstore_quality.indexOf('Gently') >= 0
        ) {
            this.onToggleUsedModal();
        } else {
            this.onClick();
        }
    };

    onClick = () => {
        if (
            this.props.hasSubscription &&
            Window.getQueryVariable('purchaseType') !== 'purchaseForNewUser' &&
            this.state.planOption === 'upgrade' &&
            this.props.isLoggedIn &&
            !this.state.isOpenConfirmUpgrade
        ) {
            this.onToggleConfirmUpgrade();
        } else if (this.props.onClick) {
            const planOptionString =
                this.state.planOption === 'upgrade'
                    ? 'upgradeOrder'
                    : 'purchaseForNewUser';

            this.props.onClick(
                this.isPhoneUpgrade() ? planOptionString : undefined
            );
        }
    };

    onToggleUsedModal = () => {
        this.setState({
            toggleUsedModal: !this.state.toggleUsedModal,
        });
    };

    onCancelConfirmUsed = () => {
        this.setState({
            toggleUsedModal: !this.state.toggleUsedModal,
        });
    };

    onContinueConfirmUsed = () => {
        this.setState({
            toggleUsedModal: !this.state.toggleUsedModal,
        });

        this.onClick();
    };

    getCurrentProduct = () => {
        return (
            this.props.productSet.products.find(
                (product) =>
                    product.id.toString() === this.props.currentProductId
            ) || this.props.productSet.products[0]
        );
    };

    isPhoneUpgrade = () => {
        const purchaseType = Window.getQueryVariable('purchaseType');
        return Boolean(
            this.props.hasSubscription && !this.props.isSim && !purchaseType
        );
    };

    render() {
        const currentProduct = this.getCurrentProduct();
        const isOnSale = currentProduct.price > currentProduct.sale_price;

        return (
            <Flex
                direction="column"
                className="product-set__select-plan"
                alignSelf="center"
                xs
                justify="center"
                fluid
            >
                <Flex xs={false} md direction="column">
                    <ProductSetHeader
                        isSIM={this.props.isSim}
                        isOnSale={isOnSale}
                        currentProduct={currentProduct}
                        hasSubscription={this.props.hasSubscription}
                        productSet={this.props.productSet}
                    />
                </Flex>

                {currentProduct.product_descriptions &&
                    currentProduct.product_descriptions.length > 0 && (
                        <div id="descriptions">
                            <Flex sm md={false} marginTop={4} />
                            <ReadMore maxLines={2} size="regular">
                                {
                                    currentProduct.product_descriptions[0]
                                        .description
                                }
                            </ReadMore>
                        </div>
                    )}

                {this.props.productSet.products.length > 1 && (
                    <ProductSelector
                        productSet={this.props.productSet}
                        currentProductId={this.props.currentProductId}
                        onUpdateCurrentProduct={
                            this.props.onUpdateCurrentProduct
                        }
                    />
                )}
                {!this.props.isSim && (
                    <PlansSelector
                        getValidCurrentPlan={this.getValidCurrentPlan()}
                        onUpdateCurrentPlan={this.props.onUpdateCurrentPlan}
                        plans={this.props.plans}
                        currentProduct={currentProduct}
                        isPhoneUpgrade={this.isPhoneUpgrade()}
                        onTogglePlanOption={this.onTogglePlanOption}
                        planOption={this.state.planOption}
                    />
                )}
                {!currentProduct.sold_out && (
                    <Flex marginTop={3}>
                        <DeliveryPromise
                            shipping_delay={currentProduct.shipping_delay}
                            label={`Want a ${
                                this.props.isSim ? 'SIM' : 'phone'
                            } by {shippingPromiseDay}?`}
                        />
                    </Flex>
                )}

                {!currentProduct.sold_out && !this.props.isSim && (
                    <Flex marginTop={3}>
                        <BuyNow onBuy={this.middleChecker} />
                    </Flex>
                )}

                {/* This is the button we see if the user is in the sim flow */}
                {this.props.isSim && (
                    <>
                        <Flex xs md={false} marginTop={3}>
                            <Button
                                onClick={this.props.onClick}
                                wide
                                label="Buy Now"
                                icon="chevron-right"
                                disabled={this.props.isChildUser}
                            />
                        </Flex>

                        <Flex xs={false} md marginTop={3}>
                            <Button
                                onClick={this.props.onClick}
                                label="Buy Now"
                                icon="chevron-right"
                                disabled={this.props.isChildUser}
                            />
                        </Flex>
                    </>
                )}

                {this.props.isSim && (
                    <>
                        <Flex marginTop={2} marginBottom={3} xs={false} md>
                            <Link
                                href="/wireless/device-checker"
                                appearance="primary"
                                size="regular"
                            >
                                Check if your phone is compatible
                            </Link>
                        </Flex>
                        <Flex
                            marginTop={2}
                            marginBottom={3}
                            justify="center"
                            xs
                            md={false}
                        >
                            <Link
                                href="/wireless/device-checker"
                                appearance="primary"
                                size="regular"
                            >
                                Check if your phone is compatible
                            </Link>
                        </Flex>
                    </>
                )}

                {/* We don't show the coverage stuff unless you are buying a sim */}
                {this.props.isSim && (
                    <>
                        <Flex marginTop={0} marginBottom={5} xs={false} md>
                            <Link
                                href="/wireless/coverage"
                                appearance="primary"
                                size="regular"
                            >
                                View coverage
                            </Link>
                        </Flex>
                        <Flex
                            marginTop={0}
                            marginBottom={5}
                            justify="center"
                            xs
                            md={false}
                        >
                            <Link
                                href="/wireless/coverage"
                                appearance="primary"
                                size="regular"
                            >
                                View coverage
                            </Link>
                        </Flex>
                    </>
                )}

                <UsedModal
                    currentProduct={currentProduct}
                    onCancelConfirmUsed={this.onCancelConfirmUsed}
                    onContinueConfirmUsed={this.onContinueConfirmUsed}
                    isOpen={this.state.toggleUsedModal}
                />

                <ConfirmModal
                    onCancel={this.onToggleConfirmUpgrade}
                    onContinue={this.onClick}
                    title="Upgrade/Replace Device"
                    isOpen={this.state.isOpenConfirmUpgrade}
                >
                    <Flex>
                        <Label size="small">
                            {' '}
                            Once you activate the newly shipped device, your
                            current device (the previous TextNow device) will be
                            automatically deactivated.{' '}
                        </Label>
                        <Flex marginTop={3}>
                            <Label size="small">
                                {' '}
                                Are you sure you wish to proceed with the
                                purchase?{' '}
                            </Label>
                        </Flex>
                    </Flex>
                </ConfirmModal>
            </Flex>
        );
    }
}
interface Props {
    hasSubscription?: boolean;
    productSet: ProductSetRecord;
    currentPlan?: string;
    plans: List<PlanRecord>;
    onUpdateCurrentPlan?: (stripeId: string) => void;
    onUpdateCurrentProduct?: (id: string) => void;
    isChildUser?: boolean;
    isSim?: boolean;
    currentProductId: string;
    onClick?: (temp?: string) => void;
    onBuy?: () => void;
    toggleModal?: () => void;
    isLoggedIn: boolean;
    currentProduct?: string;
}

const mapStateToProps = createStructuredSelector({
    isLoggedIn: sessionSelectors.isLoggedIn(),
});

export interface State {
    isOpenConfirmUpgrade: boolean;
    planOption: string;
    toggleUsedModal: boolean;
}

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PurchaseDetails);
