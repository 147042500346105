import * as React from 'react';

import { Label, H2, Flex, Button } from '@tn/retune';

import './index.scss';

export enum LockedStatus {
    Locked,
    Unlocked,
}

export class IsPhoneLocked extends React.PureComponent<Props> {
    onLocked = () => {
        this.props.onClick(LockedStatus.Locked);
    };

    onUnlocked = () => {
        this.props.onClick(LockedStatus.Unlocked);
    };

    render() {
        return (
            <Flex
                direction="column"
                className="phone-locked"
                alignSelf="center"
                fluid
                xs
            >
                <Flex>
                    <H2>Is your phone locked?</H2>
                </Flex>
                <Flex justify="center" marginBottom={3} xs>
                    <Label>
                        Carriers lock phones to prevent people from using it on
                        another carrier. If you didn't purchase your phone
                        through a carrier your phone is likely unlocked.
                    </Label>
                </Flex>
                <Flex
                    justify="start"
                    alignItems="center"
                    direction="row"
                    sm
                    xs={false}
                >
                    <Button
                        className="phone-locked__left-button"
                        onClick={this.onLocked}
                        loading={this.props.loading}
                        label="Yes"
                    />
                    <Button
                        onClick={this.onUnlocked}
                        loading={this.props.loading}
                        label="No"
                    />
                </Flex>
                <Flex
                    justify="start"
                    alignItems="center"
                    direction="column"
                    sm={false}
                    xs
                >
                    <Button
                        className="phone-locked__left-button"
                        onClick={this.onLocked}
                        loading={this.props.loading}
                        label="Yes"
                        wide
                    />
                    <Button
                        onClick={this.onUnlocked}
                        loading={this.props.loading}
                        label="No"
                        wide
                    />
                </Flex>
            </Flex>
        );
    }
}

interface Props {
    onClick: (status: LockedStatus) => void;
    loading?: boolean;
}

export default IsPhoneLocked;
